const API_BASE_URL = process.env.REACT_APP_API_URL;

export const uploadPublicIdentityKey = async (userId, workspaceId, publicKey) => {
  try {
    const response = await fetch(`${API_BASE_URL}keys/identity`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, workspaceId, publicKey }),
    });
    if (!response.ok) throw new Error('Failed to upload public identity key');
    return await response.json();
  } catch (error) {
    console.error('Error uploading public identity key:', error);
    throw error;
  }
};

export const uploadEncryptedSenderKey = async (userId, workspaceId, type, roomId, encryptedKey, createdAt) => {
  try {
    // Convert ArrayBuffer to base64 using same method as message encryption
    const base64Key = btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedKey)));
    
    const response = await fetch(`${API_BASE_URL}keys/sender`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        workspaceId,
        type,
        roomId,
        encryptedKey: base64Key, 
        createdAt: createdAt
      }),
    });
    
    if (!response.ok) throw new Error('Failed to upload encrypted sender key');
    return await response.json();
  } catch (error) {
    console.error('Error uploading encrypted sender key:', error);
    throw error;
  }
};

export async function fetchAllEncryptedSenderKeys(userId, workspaceId) {
  const response = await fetch(`${API_BASE_URL}keys/sender/all`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userId,
      workspaceId
    })
  });

  if (!response.ok) {
    throw new Error('Failed to fetch sender keys');
  }

  return await response.json();
} 


export async function fetchPublicChannelSenderKeys(userID, spaceID, channelIDs) {
  try {
    const response = await fetch(`${API_BASE_URL}keys/sender/public`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userID, spaceID, channelIDs })
  });

  if (!response.ok) {
    throw new Error('Failed to fetch public channel sender keys');
  }

    return await response.json();
  } catch (error) {
    console.error('Error fetching public channel sender keys:', error);
    throw error;
  }
}

export async function fetchMemberPublicKeys(workspaceId, memberIds) {
  try {
    const response = await fetch(`${API_BASE_URL}keys/public/members`, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ workspaceId, memberIds })
  });

  if (!response.ok) {
    throw new Error('Failed to fetch member public keys');
  }

    return await response.json();
  } catch (error) {
    console.error('Error fetching member public keys:', error);
    throw error;
  }
}

export async function fetchSpaceAdmins(workspaceId) {
  try {
    const response = await fetch(`${API_BASE_URL}space/admins`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ workspaceId })
  });

  if (!response.ok) {
    throw new Error('Failed to fetch space admins');
  }

    return await response.json();
  } catch (error) {
    console.error('Error fetching space admins:', error);
    throw error;
  }
}

export async function storeKeyRotationHistory(workspaceId, type, results) {
  try { 
    const response = await fetch(`${API_BASE_URL}keys/store-rotation`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ workspaceId, type, results })
    });

    if (!response.ok) {
      throw new Error('Failed to store key rotation history');
    }
  } catch (error) {
    console.error('Error storing key rotation history:', error);
    throw error;
  }
}

export async function getKeyRotationHistory(workspaceId) {
  try {
    const response = await fetch(`${API_BASE_URL}keys/rotation-history`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ workspaceId })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch rotation history');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching rotation history:', error);
    throw error;
  }
}

export const uploadMultipleEncryptedSenderKeys = async (encryptedKeys) => {
  try {
    // Convert each ArrayBuffer to base64
    const formattedKeys = encryptedKeys.map(key => ({
      ...key,
      encrypted_key: btoa(String.fromCharCode.apply(null, new Uint8Array(key.encrypted_key)))
    }));
    
    const response = await fetch(`${API_BASE_URL}keys/sender/batch`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        keys: formattedKeys
      }),
    });
    
    if (!response.ok) throw new Error('Failed to upload encrypted sender keys');
    return await response.json();
  } catch (error) {
    console.error('Error uploading encrypted sender keys:', error);
    throw error;
  }
};

export async function getUserKeySync(workspaceId, userId) {
  try {
    const response = await fetch(`${API_BASE_URL}keys/sync-status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ workspaceId, userId })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch key sync status');
    }

    return await response.json();
  } catch (error) {
    console.error('Error fetching key sync status:', error);
    throw error;
  }
}

export async function updateUserKeySync(workspaceId, userId, lastSync, needsSync) {
  try {
    const response = await fetch(`${API_BASE_URL}keys/update-sync`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        workspaceId,
        userId,
        lastSync,
        needsSync
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update key sync status');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating key sync status:', error);
    throw error;
  }
}

