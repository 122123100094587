import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './FolderMessagesView.module.css';
import useSavedStore from '../../store/savedStore';
import MessageView from '../Message/MessageView';

const FolderMessagesView = ({ folderId }) => {
  const navigate = useNavigate();
  const { savedMessages, savedFolders, fetchSavedMessages } = useSavedStore();

  useEffect(() => {
    if (!savedMessages[folderId] || savedMessages[folderId].length === 0) {
      fetchSavedMessages(folderId);
    }
  }, [folderId]);

  const currentFolder = savedFolders.find(f => f.folder_id === folderId);
  const messages = savedMessages[folderId] || [];

  return (
    <div className={styles.folderMessagesView}>
      <div className={styles.header}>
        <button 
          className={styles.backButton}
          onClick={() => navigate('/saved')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 11H7.83l5.59-5.59L12 4l-8 8l8 8l1.41-1.41L7.83 13H20z"/>
          </svg>
          Back
        </button>
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3"/>
          </svg>
          {currentFolder?.folder_name || 'Saved Messages'}
        </h2>
      </div>

      <div className={styles.messageList}>
        {messages.map(message => (
          <MessageView
            key={message.message_id}
            message={message}
            isInSavedMessages={true}
            showHeader={true}
          />
        ))}
        {messages.length === 0 && (
          <div className={styles.emptyState}>
            No saved messages in this folder
          </div>
        )}
      </div>
    </div>
  );
};

export default FolderMessagesView;