import { useCallback, useRef, useMemo } from 'react';
import useUserDetailsStore from '../../store/userDetailsStore';
import useUIStore from '../user/useUIStore';

const useMessageViewModel = (message) => {
  const userDetails = useUserDetailsStore(state => state.userDetails.get(message.sender_id));
  const getBasicUserDetails = useUserDetailsStore(state => state.getBasicUserDetails);
  const { activeQuickViewUserId, quickViewPosition, setActiveQuickView, setHoveringTrigger } = useUIStore();

  const avatarRef = useRef(null);
  const userInfoRef = useRef(null);
  
  const basicUserDetails = useMemo(() => {
    return {
      userID: userDetails?.userSpaceDetails?.user_id || message.sender_id,
      displayName: userDetails?.userSpaceDetails?.user_display_name || 'Loading...',
      imageUrl: userDetails?.userSpaceDetails?.user_image_url || 'default-avatar.png',
      bio: userDetails?.userSpaceDetails?.user_bio || '',
      role: userDetails?.userSpaceDetails?.user_role || 'user',
      email: userDetails?.userSpaceDetails?.user_email || '',
      joinDate: userDetails?.userSpaceDetails?.user_join_date || new Date().toISOString(),
      userSpaceDetails: userDetails?.userSpaceDetails || {}
    };
  }, [userDetails, message.sender_id]);

  const handleMouseEnter = useCallback((ref) => {
    if (basicUserDetails) {
      const rect = ref.current.getBoundingClientRect();
      setActiveQuickView(message.sender_id, {
        top: rect.top - 195,
        left: rect.left,
      });
      setHoveringTrigger(true);
    }
  }, [basicUserDetails, message.sender_id, setActiveQuickView, setHoveringTrigger]);

  const handleMouseLeave = useCallback(() => {
    setHoveringTrigger(false);
  }, [setHoveringTrigger]);

  const formatTimestamp = (timestamp) => {
    const date = timestamp ? new Date(timestamp) : new Date();

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  const timestamp = formatTimestamp(message.created_at);
  const displayName = basicUserDetails.displayName;
  const imageUrl = basicUserDetails.imageUrl;

  return {
    avatarRef,
    userInfoRef,
    userDetails: basicUserDetails,
    displayName,
    imageUrl,
    timestamp,
    activeQuickViewUserId,
    quickViewPosition,
    handleMouseEnter,
    handleMouseLeave,
  };
};

export default useMessageViewModel;
