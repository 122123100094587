import imageCompression from 'browser-image-compression';
import { initializeRoomKeys } from '../../../services/roomKeyInitialization';
import { generateBackupFileContent } from '../../../utils/backupKeyFile';

const createSpace = async (userID, spaceID, spaceName, spaceImage, spaceDescription, spacePrivacySetting) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);
    formData.append('spaceName', spaceName);
    formData.append('spaceDescription', spaceDescription);
    formData.append('spacePrivacySetting', spacePrivacySetting);

    if (spaceImage) {
      let imageBlob;
      if (spaceImage instanceof Blob || spaceImage instanceof File) {
        imageBlob = spaceImage;
      } else if (typeof spaceImage === 'string' && spaceImage.startsWith('data:')) {
        const response = await fetch(spaceImage);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      const fileName = `${spaceID}/space/profile/${Date.now()}-space-image.jpg`;
      formData.append('spaceImage', imageBlob, fileName);
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/create/space`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create space");
    }

    return true;
  } catch (error) {
    console.error("Error creating space:", error);
    return false;
  }
};

const updateUser = async (email, spaceID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/update/profile`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          spaceID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating new user:", error);
    return false;
  }
};

const updateLastActiveSpace = async (email, spaceID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/update/last-active-space`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          spaceID,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user");
    }

    return response.json();
  } catch (error) {
    console.error("Error creating new user:", error);
    return false;
  }
};

const getOnboardingStatus = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-onboarding-status?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch onboarding status");
    }

    return data.onboardingStatus;
  } catch (error) {
    console.error("Error fetching onboarding status:", error);
    throw error;
  }
};

const getLastActiveSpace = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-last-active-space?email=${encodeURIComponent(
        email
      )}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch onboarding status");
    }

    return data.last_active_space_id;
  } catch (error) {
    console.error("Error fetching onboarding status:", error);
    throw error;
  }
};

const updateOnboardingStatus = async (userID, spaceID, status) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-onboarding-status`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, spaceID, status }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update onboarding status");
    }
  } catch (error) {
    console.error("Error updating onboarding status:", error);
    throw error;
  }
};

const updateRole = async (userID, spaceID, userRole) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-user-role`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userID, spaceID, userRole }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update user role 1");
    }
  } catch (error) {
    console.error("Error updating user role ", error);
    throw error;
  }
};

const getUserByEmail = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-user-id-by-email?email=${encodeURIComponent(
        email
      )}`
    );

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch user data");
    }

    return data.userID;
  } catch (error) {
    console.error("Error fetching user data by email:", error);
    throw error;
  }
};

const saveJoinCode = async (spaceID, userID, uniqueCode) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}space/save-space-join-code/${spaceID}/${userID}/${uniqueCode}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data.success;
};

const sendInvites = async (email, inviteLink, identityKey, userID, spaceID, spaceName) => {
  try {
    const backupContent = await generateBackupFileContent(spaceID, identityKey);
    
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/send-invites`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ 
          email, 
          inviteLink, 
          backupContent,
          userID, 
          spaceID, 
          spaceName 
        }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      console.error("Server error:", errorData);
      throw new Error(errorData.error || "Failed to send invites");
    }

    return await response.json();
  } catch (error) {
    console.error("Error sending invites:", error);
    throw error;
  }
};

const processJoinCode = async (joinCode, email) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/validate-space-join-code?joinCode=${encodeURIComponent(joinCode)}&email=${encodeURIComponent(email)}`);

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || 'Failed to validate join code');
    }

    return data;
  } catch (error) {
    console.error('Error processing join code:', error);
    throw error;
  }
};

const fetchUserRole = async (userID, spaceID) => {
  try {  
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/get-user-role?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`
    );
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to fetch role");
    } 

    return data.userRole ? data.userRole : null;
  } catch (error) {
    console.error("Error fetching role :", error);
    throw error;
  }
}; 

const updateUserSpaceDetails = async (userID, spaceID, displayName, bio, image, email) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);
    formData.append('displayName', displayName);
    formData.append('bio', bio);
    formData.append('email', email);

    if (image) {
      let imageBlob;
      if (image instanceof Blob || image instanceof File) {
        imageBlob = image;
      } else if (typeof image === 'string' && image.startsWith('data:')) {
        const response = await fetch(image);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      if (imageBlob) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(imageBlob, options);
        
        const fileName = `${spaceID}/members/${userID}/profile/${Date.now()}-${
          imageBlob.type === 'image/svg+xml' ? 'default-avatar.svg' : 'user-image.jpg'
        }`;
        
        formData.append('userImage', compressedFile, fileName);
      }
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-user-details`,
      {
        method: "PUT",
        body: formData,
      }
    );

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Server response:", response.status, errorText);
      throw new Error(`Failed to update user space details: ${response.status} ${errorText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error updating user space details:", error);
    throw error;
  }
};

const getUserSpaceDetails = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-user-details?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user space details");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching user space details:", error);
    return false;
  }
};

const isUserinSpace = async (userID, spaceID) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/is-user-in-space?userID=${encodeURIComponent(
        userID
      )}&spaceID=${encodeURIComponent(spaceID)}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch user space details");
    }

    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error("Error fetching user space details:", error);
    return false;
  }
};

const removeSpaceInvite = async (joinCode, email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/remove-space-invite`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ joinCode, email }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to remove space invite');
    }

    return true;
  } catch (error) {
    console.error('Error removing space invite:', error);
    throw error;
  }
};

async function createInitialChannel(userId, spaceID, channelID) {
  try {
    // Create general channel first
    await fetch(`${process.env.REACT_APP_API_URL}onboarding/create-initial-channel`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        spaceID,
        channelID
      })
    });

    // Initialize encryption keys for the default channel
    await initializeRoomKeys(userId, spaceID, 'channel', channelID, [userId]);

    return channelID;
  } catch (error) {
    console.error('Failed to create initial channel:', error);
    throw error;
  }
}

const saveOnboardingAnalytics = async (analyticsData) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/save-onboarding-analytics`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(analyticsData),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to save analytics data');
    }

    return await response.json();
  } catch (error) {
    console.error('Error saving analytics:', error);
    throw error;
  }
};

const updateUserInfo = async (userID, spaceID, displayName, image) => {
  try {
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('spaceID', spaceID);
    formData.append('displayName', displayName);

    if (image) {
      let imageBlob;
      if (image instanceof Blob || image instanceof File) {
        imageBlob = image;
      } else if (typeof image === 'string' && image.startsWith('data:')) {
        const response = await fetch(image);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      if (imageBlob) {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(imageBlob, options);
        
        const fileName = `${spaceID}/members/${userID}/profile/${Date.now()}-${
          imageBlob.type === 'image/svg+xml' ? 'default-avatar.svg' : 'user-image.jpg'
        }`;
        
        formData.append('userImage', compressedFile, fileName);
      }
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}onboarding/update-user-info`,
      {
        method: 'PUT',
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update user info');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating user info:', error);
    throw error;
  }
};

const updateSpaceMember = async (userID, spaceID, displayName, bio, image, email, onboardingStatus, userRole) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}onboarding/update-space-member`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userID, spaceID, displayName, bio, image, email, onboardingStatus, userRole }),
    });

    if (!response.ok) {
      throw new Error('Failed to update space member');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating space member:', error);
    throw error;
  }
};

export {
  isUserinSpace,
  getUserSpaceDetails,
  updateUserSpaceDetails,
  updateRole,
  fetchUserRole,
  getOnboardingStatus,
  updateOnboardingStatus,
  getLastActiveSpace,
  updateUser,
  updateLastActiveSpace,
  createSpace,
  getUserByEmail,
  saveJoinCode,
  sendInvites,
  processJoinCode,
  removeSpaceInvite,
  createInitialChannel,
  saveOnboardingAnalytics,
  updateUserInfo,
  updateSpaceMember
};
