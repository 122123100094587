import React from 'react';
import useTypingIndicatorViewModel from './TypingIndicatorViewModel';
import useUserDetailsStore from '../../store/userDetailsStore';
import styles from './TypingIndicator.module.css';

const TypingIndicatorView = () => {
  const { typingIndicator } = useTypingIndicatorViewModel();
  const getBasicUserDetails = useUserDetailsStore(state => state.getBasicUserDetails);

  if (!typingIndicator.isTyping || !typingIndicator.user) {
    return (
      <div className={styles.emptyTypingIndicator}></div>
    );
  }

  const userDetails = getBasicUserDetails(typingIndicator.user);
  const displayName = userDetails?.displayName || 'Someone';

  return (
    <div className={styles.typingIndicator}>
      <span className={styles.displayName}>{displayName}</span> is typing
    </div>
  );
};

export default TypingIndicatorView;
