import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import styles from './CreateNewWorkspaceView.module.css';
import { createSpace, updateRole, updateOnboardingStatus, updateUserSpaceDetails, updateUser } from '../../../features/onboarding/services/onboardingService';
import { generateUniqueCode } from '../../../utils/uniqueIDGenerator';
import { createDefaultAvatar, getAvatarColor } from '../utils/imageUtils';
import { generateAndRegisterUserKeys } from '../../../services/identityKeyGeneration';
import { createInitialChannel, updateLastActiveSpace } from '../../../features/onboarding/services/onboardingService';
import { v4 as uuidv4 } from 'uuid';
import useUserDetailsStore from '../../../store/userDetailsStore';
import { getStoredAccounts, setStoredAccounts } from '../../../utils/accountStorage';
import { fetchUserWorkspaces } from '../../../features/onboarding/services/WorkspaceService';

const CreateNewWorkspaceView = () => {
  const [spaceName, setSpaceName] = useState('');
  const [spaceDescription, setSpaceDescription] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isGeneratingKeys, setIsGeneratingKeys] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);
  const [switchingAccount, setSwitchingAccount] = useState(false);
  
  const navigate = useNavigate();
  const { email, userID, setOnboardingStatus, establishUserSpace, activeAccounts, switchAccount } = useAuthStore();
  const { fetchUserDetails } = useUserDetailsStore();

  useEffect(() => {
    // Fetch user details when component mounts or when userID changes
    if (userID) {
      fetchUserDetails(userID);
    }
  }, [userID, fetchUserDetails]);

  useEffect(() => {
    setIsFormValid(spaceName.trim().length > 0);
  }, [spaceName]);

  const handleAccountSwitch = async (accountEmail) => {
    if (accountEmail === email) return;
    
    setSwitchingAccount(true);
    try {
      await switchAccount(accountEmail);
      setShowAccountDropdown(false);
      
      // Fetch user details for the new account
      const newUserID = useAuthStore.getState().userID;
      if (newUserID) {
        await fetchUserDetails(newUserID);
      }
    } catch (error) {
      console.error('Failed to switch account:', error);
    } finally {
      setSwitchingAccount(false);
    }
  };

  const handleSubmit = async () => {
    if (!isFormValid) return;
    setIsGeneratingKeys(true);

    try {
      const spacePreviewColor = getAvatarColor(spaceName);
      const spaceImageToSend = createDefaultAvatar(spaceName, spacePreviewColor);
      
      const spaceID = generateUniqueCode();
      const adminRole = 'admin';

      // Create default user details if none exist
      const defaultDisplayName = email.split('@')[0];
      const userImageToSend = createDefaultAvatar(defaultDisplayName, getAvatarColor(defaultDisplayName));

      await updateUser(email, spaceID);
      
      // Update stored accounts
      const storedAccounts = getStoredAccounts();
      const updatedAccounts = storedAccounts.map(account => {
        if (account.email === email) {
          return { ...account, lastActiveSpace: spaceID };
        }
        return account;
      });
      setStoredAccounts(updatedAccounts);

      await createSpace(userID, spaceID, spaceName, spaceImageToSend, spaceDescription, false);
      await updateRole(userID, spaceID, adminRole);
      
      await updateUserSpaceDetails(
        userID, 
        spaceID,
        defaultDisplayName,
        '', 
        userImageToSend,
        email
      );

      await updateOnboardingStatus(userID, spaceID, 'create/backup');

      // Fetch updated workspaces and update auth store
      const workspaces = await fetchUserWorkspaces(email);
      useAuthStore.getState().setWorkspaces(workspaces);

      establishUserSpace(spaceID, adminRole);
      setOnboardingStatus('create/backup');
      navigate('/create/backup', { 
        replace: true,
        state: { from: 'create/workspace' }
      });

    } catch (error) {
      console.error('Error creating space:', error);
      setIsGeneratingKeys(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <div className={styles.accountSelector}>
          <div onClick={() => !switchingAccount && setShowAccountDropdown(!showAccountDropdown)}>
            {email} {switchingAccount ? <i className="fa-solid fa-spinner fa-spin"></i> : <i className="fa-solid fa-chevron-down"></i>}
        </div>
        {showAccountDropdown && (
          <div className={styles.accountDropdown}>
            {activeAccounts.map(account => (
              <div 
                key={account.email} 
                className={`${styles.accountOption} ${account.email === email ? styles.active : ''}`}
                onClick={() => handleAccountSwitch(account.email)}
              >
                <span>{account.email}</span>
                {account.email === email && <div className={styles.activeIndicator} />}
              </div>
            ))}
          </div>
        )}
        </div>

        <h1 className={styles.title}>Create New Workspace</h1>
        <p className={styles.subtitle}>Workspaces are where communication happens. All your messages are end-to-end encrypted.</p>
        
        <div className={styles.formSection}>
          <div className={styles.inputGroup}>
            <label className={styles.label}>Workspace Name</label>
            <input
              required
              type="text"
              className={styles.input}
              value={spaceName}
              placeholder="Enter your workspace name"
              onChange={(e) => setSpaceName(e.target.value)}
              maxLength={25}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Workspace Description</label>
            <input
              type="text"
              className={styles.input}
              value={spaceDescription}
              placeholder="What does your workspace do?"
              onChange={(e) => setSpaceDescription(e.target.value)}
              maxLength={200}
            />
          </div>
        </div>
        
        <button
          className={styles.button}
          onClick={handleSubmit}
          disabled={!isFormValid || isGeneratingKeys}
        >
          Create Workspace
        </button>
      </div>
    </div>
  );
};

export default CreateNewWorkspaceView;