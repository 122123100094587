import React, { useEffect, useRef, useState } from 'react';
import styles from './MessageAttachments.module.css';

const PDFPreview = ({ url }) => {
  const containerRef = useRef(null);
  const pdfTaskRef = useRef(null);
  const renderTaskRef = useRef(null);
  const isMountedRef = useRef(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let retryTimeout;
    isMountedRef.current = true;

    const loadPDF = async (retryCount = 0) => {
      if (!url) return;
      
      try {
        // Cancel any existing render tasks
        if (renderTaskRef.current) {
          renderTaskRef.current.cancel();
          renderTaskRef.current = null;
        }
        
        // Cancel any existing PDF tasks
        if (pdfTaskRef.current) {
          pdfTaskRef.current.destroy();
          pdfTaskRef.current = null;
        }

        // Create a new canvas element for each render
        const container = containerRef.current;
        if (!container) return;

        // Remove any existing canvas
        while (container.firstChild) {
          container.removeChild(container.firstChild);
        }

        // Create fresh canvas
        const canvas = document.createElement('canvas');
        container.appendChild(canvas);

        // Wait a bit before loading to ensure cleanup is complete
        await new Promise(resolve => setTimeout(resolve, 100));
        
        if (!isMountedRef.current) return;

        const loadingTask = window.pdfjsLib.getDocument(url);
        pdfTaskRef.current = loadingTask;
        
        const pdf = await loadingTask.promise;
        if (!isMountedRef.current) return;
        
        const page = await pdf.getPage(1);
        if (!isMountedRef.current) return;

        const context = canvas.getContext('2d');
        const viewport = page.getViewport({ scale: 0.5 });
        
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Create new render task
        renderTaskRef.current = page.render({
          canvasContext: context,
          viewport: viewport,
          enableWebGL: false // Disable WebGL to prevent context issues
        });

        await renderTaskRef.current.promise;
        setError(null);
      } catch (error) {
        if (error.name === 'RenderingCancelled') return;
        
        if (error.message.includes('Worker was destroyed') && retryCount < 3) {
          // Retry after a delay if worker was destroyed
          retryTimeout = setTimeout(() => {
            if (isMountedRef.current) {
              loadPDF(retryCount + 1);
            }
          }, 500 * (retryCount + 1));
        } else if (isMountedRef.current) {
          console.error('Error loading PDF:', error);
          setError(error);
        }
      }
    };

    loadPDF();

    return () => {
      isMountedRef.current = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
      }
      if (pdfTaskRef.current) {
        pdfTaskRef.current.destroy();
      }
    };
  }, [url]);

  if (error) {
    return <div className={styles.pdfError}>Unable to load PDF preview</div>;
  }

  return (
    <div className={styles.pdfPreview}>
      <div ref={containerRef} />
    </div>
  );
};

export default PDFPreview; 