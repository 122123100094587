import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';

const Navbar = () => {
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate("/login");
  };

  return (
    <div className={styles.container}>
      <nav className={styles.navbar}>
        <div className={styles.logo} onClick={() => navigate('/')}>
          <img src="/logo.png" alt="Mention" />
        <h1>Mention</h1>
      </div>
      <div className={styles.navLinks}>
        <a href="/about">Whitepaper</a>
        <a href="/features">Features</a>
        <a href="/pricing">Pricing</a>
        <a href="/changelog">Changelog</a>
        <a href="/blog">About</a>
        <a href="/contact">Contact</a>
      </div>
      <button onClick={handleGetStarted} className={styles.getStartedButton}>
        Log in
          <i className="fa-solid fa-arrow-right"></i>
        </button>
      </nav>
    </div>
  );
};

export default Navbar; 