import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaDownload, FaLock } from 'react-icons/fa';
import useAuthStore from '../../../store/authStore';
import { updateOnboardingStatus } from '../services/onboardingService';
import { getSpaceDetails } from '../../sidebar/services/spaceService';
import { generateAndRegisterUserKeys } from '../../../services/identityKeyGeneration';
import { createInitialChannel } from '../services/onboardingService';
import { v4 as uuidv4 } from 'uuid';
import Spinner from '../../Spinner/Spinner';
import styles from '../css/KeyBackupInfoStep.module.css';

const KeyBackupInfoStep = () => {
  const [isGeneratingKeys, setIsGeneratingKeys] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { userID, spaceID, setOnboardingStatus } = useAuthStore();

  const handleContinue = async () => {
    if (isGeneratingKeys) return;
    setIsGeneratingKeys(true);

    try {
      const details = await getSpaceDetails(spaceID);
      if (!details) {
        throw new Error('Could not fetch space details');
      }

      await generateAndRegisterUserKeys(userID, spaceID, details.space_name);
      
      const channelID = uuidv4().toString();
      await createInitialChannel(userID, spaceID, channelID);

      const fromCreateWorkspace = location.state?.from === 'create/workspace';

      if (fromCreateWorkspace) {
        await updateOnboardingStatus(userID, spaceID, 'create/invites');
        setOnboardingStatus('create/invites');
        navigate('/create/invites', { replace: true });
      } else {
        await updateOnboardingStatus(userID, spaceID, 'create/user');
        setOnboardingStatus('create/user');
        navigate('/create/user', { replace: true });
      }
    } catch (error) {
      console.error('Error generating keys:', error);
      setIsGeneratingKeys(false);
    }
  };

  if (isGeneratingKeys) {
    return <Spinner />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>Master key backup file</h2>
    
        <div className={styles.infoSection}>         
          <p className={styles.infoText}>
            Continuing will download your encryption keys.
            You'll need this file for:
          </p>  
          
          <ol className={styles.infoList}>
            <li>Restoring access to your account</li>
            <li>Signing in from a new device or browser</li>
            <li>Recovering your encrypted messages and files</li>
          </ol>
        </div>

        <div className={styles.warningBox}>
          <div className={styles.iconWrapper}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="1.2em" 
              height="1.2em" 
              viewBox="0 0 24 24" 
              className={styles.warningIcon}
            >
              <path 
                fill="currentColor" 
                d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z"
              />
            </svg>
            <h3>Important:</h3>
          </div>
          <ul className={styles.warningList}>
            <li>Mention DOES NOT store this file to maintain end-to-end encryption</li>
            <li>Store this file in a secure location</li>
            <li>Never share this file with anyone</li>
            <li>Your workspace will not be recoverable without this file</li>
          </ul>
        </div>

        <label className={styles.checkbox}>
          <input
            type="checkbox"
            checked={hasConfirmed}
            onChange={(e) => setHasConfirmed(e.target.checked)}
          />
          <span>I understand that losing this file means losing access to my encrypted data</span>
        </label>

        <button 
          className={styles.continueButton}
          onClick={handleContinue}
          disabled={isGeneratingKeys || !hasConfirmed}
        >
          {isGeneratingKeys ? 'Generating Keys...' : 'Generate and Download Keys'}
        </button>
      </div>
    </div>
  );
};

export { KeyBackupInfoStep }; 