import React, { useState, useEffect } from 'react';
import styles from './SaveMessagePopup.module.css';

import useSavedStore from '../../../store/savedStore';
import { v4 as uuidv4 } from 'uuid';

const SaveMessagePopup = ({ message, onClose }) => {
  const { savedFolders, createFolder, saveMessageToFolder, fetchSavedFolders } = useSavedStore();

  const [newFolderName, setNewFolderName] = useState('');
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);

  useEffect(() => {
    if (savedFolders.length === 0) {
      fetchSavedFolders();
    }
  }, []);

  const handleSaveToFolder = async (folderID) => {
    try {
      const result = await saveMessageToFolder(folderID, message);
      if (result === null) {
        onClose();
        return;
      }
      onClose();
    } catch (error) {
      console.error('Error saving message:', error);
    }
  };

  const handleCreateFolder = async (e) => {
    e.preventDefault();
    if (!newFolderName.trim()) return;

    try {
      const folderID = uuidv4();

      await createFolder(newFolderName, folderID);
      await saveMessageToFolder(folderID, message);
      
      setIsCreatingFolder(false);
      onClose();
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  const handleClickOutside = () => {
    handleSaveToFolder('general');
    onClose();
  };

  /* 
   * 8. saved tab: shows all folders in grid view with all posts being the first folder  -> mention.so/client/spaceID/saved route**
   * 9. clicking on a folder: navigate to the folder ->  mention.so/client/spaceID/saved/folderID route
   * 10. opening a folder: shows all saved messages in that folder. everything should be organized by date saved. 
   * 11. clicking on a saved message: navigate to the message -> mention.so/client/spaceID/channelID
   * 
   * EDGE CASES: ENCRYPTED PREVIEW
   * EDGE CASES: ORIGINAL MESSAGE IS DELETED
   * EDGE CASES: Weird routing bug
   */

  return (
    <div className={styles.overlay} onClick={handleClickOutside}>
      <div className={styles.popup} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.savedIconContainer}>
            <div className={styles.savedIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3"/>
              </svg>
              <h3>Saved</h3>
            </div>
            <button className={styles.closeButton} onClick={handleClickOutside}>×</button>
          </div>
        </div>

        <div className={styles.folderList}>
          <h4 className={styles.saveToFolderHeader}>Save to a different folder</h4>
          {savedFolders.map(folder => (
            <button
              key={folder.folder_id}
              onClick={() => handleSaveToFolder(folder.folder_id)}
              className={styles.folderButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1.1em" height="1.1em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M3.5 6.25V8h4.629a.75.75 0 0 0 .53-.22l1.53-1.53l-1.53-1.53a.75.75 0 0 0-.53-.22H5.25A1.75 1.75 0 0 0 3.5 6.25m-1.5 0A3.25 3.25 0 0 1 5.25 3h2.879a2.25 2.25 0 0 1 1.59.659L11.562 5.5h7.189A3.25 3.25 0 0 1 22 8.75v9A3.25 3.25 0 0 1 18.75 21H5.25A3.25 3.25 0 0 1 2 17.75zM3.5 9.5v8.25c0 .966.784 1.75 1.75 1.75h13.5a1.75 1.75 0 0 0 1.75-1.75v-9A1.75 1.75 0 0 0 18.75 7h-7.19L9.72 8.841a2.25 2.25 0 0 1-1.591.659z" />
              </svg>
              {folder.folder_name}
            </button>
          ))}
        </div>

        {isCreatingFolder ? (
          <form onSubmit={handleCreateFolder} className={styles.createFolderForm}>
            <input
              type="text"
              value={newFolderName}
              onChange={e => setNewFolderName(e.target.value)}
              placeholder="New folder name"
              className={styles.input}
              autoFocus
            />
            <div className={styles.formActions}>
              <button 
                type="button" 
                onClick={() => setIsCreatingFolder(false)}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button type="submit" className={styles.createButton}>
                Create
              </button>
            </div>
          </form>
        ) : (
          <button
            onClick={() => setIsCreatingFolder(true)}
            className={styles.newFolderButton}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/></svg>
            Create new folder
          </button>
        )}
      </div>
    </div>
  );
};

export default SaveMessagePopup;
