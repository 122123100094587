const addMessageReaction = async (spaceId, roomId, messageId, reaction, userId, roomType) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${spaceId}/${roomId}/${messageId}/addReaction`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reaction, userId, roomType }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to add reaction');
    }

    return await response.json();
  } catch (error) {
    console.error('Error adding reaction:', error);
    throw error;
  }
};

const removeMessageReaction = async (spaceId, roomId, messageId, reaction, userId, roomType) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}${spaceId}/${roomId}/${messageId}/removeReaction`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reaction, userId, roomType }),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to remove reaction');
    }

    return await response.json();
  } catch (error) {
    console.error('Error removing reaction:', error);
    throw error;
  }
};

export {
  addMessageReaction,
  removeMessageReaction
};
