import React, { useEffect } from 'react';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import styles from './EmojiPicker.module.css';

const EmojiPicker = ({ onEmojiClick, onClose }) => {
  useEffect(() => {
    const handleClickoutside = (event) => {
      const emojiButton = document.querySelector('[data-emoji-button="true"]');
      if (emojiButton && emojiButton.contains(event.target)) return;

      if(!event.target.closest(`.${styles.emojiPickerContainer}`)) {
        onClose();
      }
    };

    setTimeout(() => {
      document.addEventListener('mousedown', handleClickoutside);
    }, 0);

    return () => document.removeEventListener('mousedown', handleClickoutside);
  }, [onClose]);

  return (
    <div className={styles.emojiPickerContainer}>
      <Picker 
        data={data}
        onEmojiSelect={(emoji) => {
          onEmojiClick(emoji.native);
          onClose();
        }}
        theme="dark"
        width={320}
        height={400}
        previewPosition="none"
        skinTonePosition="none"
        emojiSize={20}
        emojiButtonSize={30}
        maxFrequentRows={4}
        searchPosition="top"
      />
    </div>
  );
};

export default EmojiPicker;