import { getIdentityKey } from './indexedDBService';
import { storeDMKey, storeGroupKey, storeChannelKey } from './indexedDBService';
import { fetchAllEncryptedSenderKeys, fetchPublicChannelSenderKeys } from './keyBackendService';
import { Buffer } from 'buffer';
import { updateUserKeySync } from './keyBackendService';

export async function decryptAndStoreAllKeys(userId, workspaceId) {
  try {
    // Get identity key for decryption
    const identityKey = await getIdentityKey(userId, workspaceId);

    // TODO: If no identity key, rederict to restore account portal w/ upload field for backup identity key file
    if (!identityKey) throw new Error('Identity key not found');

    // Fetch all encrypted keys
    const encryptedKeys = await fetchAllEncryptedSenderKeys(userId, workspaceId);

    // Process each key
    for (const encryptedKey of encryptedKeys) {
      // Decrypt the key
      const decryptedKeyBuffer = await window.crypto.subtle.decrypt(
        { name: "RSA-OAEP" },
        identityKey.privateKey,
        Buffer.from(encryptedKey.encrypted_key, 'base64')
      );

      // Import as CryptoKey
      const key = await window.crypto.subtle.importKey(
        "raw",
        decryptedKeyBuffer,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
      );

      // Store using our existing services
      switch (encryptedKey.type) {
        case 'dm':
          await storeDMKey(userId, workspaceId, encryptedKey.room_id, key, encryptedKey.created_at);
          break;
        case 'group_chat':
          await storeGroupKey(userId, workspaceId, encryptedKey.room_id, key, encryptedKey.created_at);
          break;
        case 'channel':
          await storeChannelKey(userId, workspaceId, encryptedKey.room_id, key, encryptedKey.created_at);
          break;
        default:
          console.error('Unknown key type:', encryptedKey.type);
      }
    }

    // Record successful sync
    await updateUserKeySync(workspaceId, userId, new Date().toISOString(), false);
    
    return true;
  } catch (error) {
    console.error('Error syncing keys:', error);
    throw error;
  }
}

export async function fetchAndDecryptChannelKeys(userID, spaceID, channelIDs) {
  try {
    // Get admin's identity key
    const identityKey = await getIdentityKey(userID, spaceID);
    if (!identityKey) throw new Error('Admin identity key not found');

    // Fetch sender keys for all channels
    const { senderKeys } = await fetchPublicChannelSenderKeys(userID, spaceID, channelIDs);

    // Decrypt all sender keys with admin's private key
    const decryptedKeys = await Promise.all(senderKeys.map(async (senderKey) => {
      // Decrypt the key
      const decryptedKeyBuffer = await window.crypto.subtle.decrypt(
        { name: "RSA-OAEP" },
        identityKey.privateKey,
        Buffer.from(senderKey.encrypted_key, 'base64')
      );

      // Import as CryptoKey
      const key = await window.crypto.subtle.importKey(
        "raw",
        decryptedKeyBuffer,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
      );

      return { 
        room_id: senderKey.room_id, 
        key 
      };
    }));

    return decryptedKeys;
  } catch (error) {
    console.error('Error fetching and decrypting channel keys:', error);
    throw error;
  }
}