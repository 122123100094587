import { useState, useEffect } from 'react';
import useUserDetailsStore from '../../../store/userDetailsStore';
import useSidebarStore from '../../../store/sidebarStore';

const useFileDetails = (file) => {
  const [displayName, setDisplayName] = useState('');
  const [senderDetails, setSenderDetails] = useState(null);
  const { channelDetails, dmConversations, groupChats } = useSidebarStore();
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);

  useEffect(() => {
    const loadDetails = async () => {

      if (!file) return;

      try {
        // Load sender details
        const senderData = await fetchUserDetails(file.sender_id);
        setSenderDetails(senderData?.userSpaceDetails);

        // Load room details
        switch (file.room_type) {
          case 'channel': {
            const channel = channelDetails[file.room_id];
            setDisplayName(channel?.channel_name || 'Unknown Channel');
            break;
          }

          case 'dm': {
            const dm = dmConversations.find(d => d.dm_id === file.room_id);
            if (dm?.receiver_id) {
              const details = await fetchUserDetails(dm.receiver_id);
              if (details?.userSpaceDetails) {
                setDisplayName(details.userSpaceDetails.user_display_name || '');
              }
            }
            break;
          }

          case 'group_chat': {
            const group = groupChats.find(g => g.group_id === file.room_id);
            if (group?.member_ids?.length > 0) {
              const memberDetails = await Promise.all(
                group.member_ids.map(id => fetchUserDetails(id))
              );
              
              const names = memberDetails
                .filter(detail => detail?.userSpaceDetails)
                .map(detail => detail.userSpaceDetails.user_display_name)
                .filter(name => name);

              setDisplayName(names.join(', ') || 'Unknown Group');
            } else {
              setDisplayName(group?.group_name || 'Unknown Group');
            }
            break;
          }
        }
      } catch (error) {
        console.error('Error loading file details:', error);
        setDisplayName('Unknown Room');
      }
    };

    loadDetails();
  }, [file, channelDetails, dmConversations, groupChats, fetchUserDetails]);

  return {
    roomDisplayName: displayName,
    senderDetails,
    roomType: file?.room_type,
    roomId: file?.room_id,
    prefix: file?.room_type === 'channel' ? '#' : ''
  };
};

export default useFileDetails;