import React from 'react';
import useKeySyncStore from '../../store/keySyncStore';
import styles from './KeySyncModal.module.css';
import useAuthStore from '../../store/authStore';

const KeySyncModal = () => {
  const { showSyncModal, isSyncing, syncKeys } = useKeySyncStore();
  const { userID, spaceID } = useAuthStore();

  if (!showSyncModal) return null;

  const handleSync = () => {
    syncKeys(spaceID, userID);
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2 className={styles.title}>New Keys Available</h2>
        <p className={styles.description}>
          The workspace keys have been rotated. You must sync your keys to continue using the workspace.
        </p>
        <button 
          className={styles.syncButton}
          onClick={handleSync}
          disabled={isSyncing}
        >
          {isSyncing ? 'Syncing...' : 'Sync Keys Now'}
        </button>
        {isSyncing && <div className={styles.spinner} />}
      </div>
    </div>
  );
};

export default KeySyncModal;