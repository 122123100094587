import React, { useEffect, useState } from 'react';
import SidebarView from '../features/sidebar/components/structure/SidebarView';
import Spinner from '../features/Spinner/Spinner';
import useUserDetailsStore from '../store/userDetailsStore';
import useSidebarStore from '../store/sidebarStore';
import useAuthStore from '../store/authStore';
import DMCreationFlow from '../features/chat/components/DMCreationFlow/DMCreationFlow';
import MainPanelFeed from '../features/chat/components/MainPanel/MainPanelFeed';
import SavedMessagesView from '../features/saved/SavedMessagesView';
import KeySyncModal from '../components/modals/KeySyncModal';
import FilesTab from '../features/fileAttachments/FilesTab';
import { fetchSpaceMembers } from '../features/chat/services/DMCreationFlowService';

import { getFilesWithFilters } from '../features/fileAttachments/services/fileAttachmentService';
import { fetchUserWorkspaces } from '../features/onboarding/services/WorkspaceService';
import { getStoredAccounts, setStoredAccounts } from '../utils/accountStorage';
import { getLastActiveSpace } from '../features/onboarding/services/onboardingService';

import { fetchLatestRoomActivity, fetchLatestMessagesForSpace } from '../features/sidebar/services/roomActivityService';
import styles from './HomeView.module.css';
import useKeySyncStore from '../store/keySyncStore';
import useFileStore from '../store/fileStore';

const HomeView = ({ view = 'default' }) => {
  const initializeSocket = useSidebarStore(state => state.initializeSocket);
  const isDataLoaded = useSidebarStore(state => state.isDataLoaded);
  const fetchFoldersForSpace = useSidebarStore(state => state.fetchFoldersForSpace);
  const fetchDMConversations = useSidebarStore(state => state.fetchDMConversations);
  const fetchGroupChats = useSidebarStore(state => state.fetchGroupChats);
  const fetchSpaceDetails = useSidebarStore(state => state.fetchSpaceDetails);
  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);
  const fetchLastActiveRoom = useSidebarStore(state => state.fetchLastActiveRoom);
  const { isDMCreationFlowActive, isFilesViewActive } = useSidebarStore();

  const spaceId = useAuthStore(state => state.spaceID);
  const userID = useAuthStore(state => state.userID);

  const socket = useSidebarStore(state => state.socket);
  const [isSocketReady, setIsSocketReady] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  const email = useAuthStore(state => state.email);
  const { initializeFiles } = useFileStore();

  // Note, this only works for the active account. We might need to track these sessions in the future to restore them.
  useEffect(() => {
    const restoreAccountData = async () => {
      if (email && !getStoredAccounts().length) {
        try {
          // Fetch workspaces and last active space
          const workspaces = await fetchUserWorkspaces(email);
          const lastActiveSpace = await getLastActiveSpace(email);
          
          // Reconstruct account data
          const accountData = {
            email,
            workspaces,
            lastActiveSpace
          };
          
          // Update local storage and auth store
          setStoredAccounts([accountData]);
          useAuthStore.getState().setWorkspaces(workspaces);
        } catch (error) {
          console.error('Error restoring account data:', error);
        }
      }
    };

    restoreAccountData();
  }, [email]);

  useEffect(() => {
    initializeSocket();
  }, [initializeSocket]);

  useEffect(() => {
    if (socket) {
      setIsSocketReady(true);
    }
  }, [socket]);

  useEffect(() => {
    setDataFetched(false);
  }, [spaceId]);

  useEffect(() => {
    if (isSocketReady && !dataFetched) { 
      const fetchAllData = async () => {
        try {
          // Clear existing data before fetching new data
          useUserDetailsStore.getState().clearUserDetails();
          
          // Initialize key sync store first
          const { userID, spaceID } = useAuthStore.getState();
          useKeySyncStore.getState().initialize(spaceID, userID);

          // Fetch space members first
          const spaceMembers = await fetchSpaceMembers(spaceID);
          
          // Fetch all member details in parallel
          await Promise.all(
            spaceMembers.map(member => 
              useUserDetailsStore.getState().fetchUserDetails(member.user_id, spaceID)
            )
          );

          // Fetch basic data in parallel
          const [lastActiveRoom] = await Promise.all([
            fetchLastActiveRoom(userID, spaceId),
            fetchFoldersForSpace(spaceId),
            fetchSpaceDetails(spaceId),
            fetchDMConversations(spaceId, userID),
            fetchGroupChats(spaceId, userID)
          ]);

          // Get fresh state after fetching
          const sidebarState = useSidebarStore.getState();

          // Create accessible rooms object
          const accessibleRooms = [
            // Get channel IDs from folders
            ...Object.values(sidebarState.channelDetails || {}).map(channel => ({
              id: channel.channel_id,
              type: 'channel'
            })),
            // Get DM IDs
            ...sidebarState.dmConversations.map(dm => ({
              id: dm.dm_id,
              type: 'dm'
            })),
            // Get group chat IDs
            ...sidebarState.groupChats.map(group => ({
              id: group.group_id,
              type: 'group_chat'
            }))
          ];

          // Join all accessible rooms
          accessibleRooms.forEach(room => {
            socket.emit('join_room', room.id, room.type);
          });

          // Initialize files with the rooms we already have
          await initializeFiles(spaceId, { accessibleRooms });

          // Fetch messages for last active room if available
          if (lastActiveRoom?.room_id) {
            await useSidebarStore.getState().fetchMessages(lastActiveRoom.room_id, lastActiveRoom.room_type, new Date().toISOString(), 25);
          }

          // Get read status and latest messages in parallel
          const [lastOpenedRoomTimestamps, lastSentRoomMsgs] = await Promise.all([
            fetchLatestRoomActivity(spaceId, userID),
            fetchLatestMessagesForSpace(spaceId, userID)   
          ]);

          // Compare timestamps and set unread state 
          const unreadRooms = new Set(
            lastOpenedRoomTimestamps.filter(status => {
              const latestMessage = lastSentRoomMsgs[status.room_id];
              return latestMessage && new Date(latestMessage) > new Date(status.last_read_at);
            }).map(s => s.room_id)
          );

          useSidebarStore.setState({
            unreadRooms,
            lastReadTimestamps: lastOpenedRoomTimestamps.reduce((acc, s) => ({
              ...acc,
              [s.room_id]: s.last_read_at
            }), {})
          });

          setDataFetched(true);
        } catch (error) {
          console.error("Error fetching data:", error);
          throw error;
        }
      };
      fetchAllData();
    }
  }, [isSocketReady, dataFetched, spaceId, fetchFoldersForSpace, fetchSpaceDetails, fetchUserDetails, fetchDMConversations, fetchLastActiveRoom, fetchGroupChats, userID]);

  useEffect(() => {
    const checkConnection = () => {
      const socket = useSidebarStore.getState().socket;
      if (!socket?.connected) {
        initializeSocket();
      }
    };

    // Check connection status when visibility changes
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkConnection();
      }
    };

    // Check connection status when device wakes up
    const handleOnline = () => {
      checkConnection();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('online', handleOnline);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleOnline);
    };
  }, [initializeSocket]);

  useEffect(() => {
    return () => {
      useFileStore.getState().cleanup();
    };
  }, []); 
  
  const renderMainContent = () => {
    if (isDMCreationFlowActive) {
      return <DMCreationFlow />;
    }
    if (isFilesViewActive) {
      return <FilesTab />;
    }
    if (view === 'saved') {
      return <SavedMessagesView />;
    }
    return <MainPanelFeed />;
  };

  if (!isSocketReady || !isDataLoaded.folderContents || !dataFetched) {
    return <Spinner />;
  }
 
  return (
    <div className={styles.container}>
      <SidebarView />
      {renderMainContent()}
      <KeySyncModal />
    </div>
  );
};

export { HomeView };