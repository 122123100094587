import React, {useEffect} from 'react';
import styles from './UserQuickView.module.css';
import useUIStore from './useUIStore';
import useUserDetailsStore from '../../store/userDetailsStore';
import useAuthStore from '../../store/authStore';
import useSidebarStore from '../../store/sidebarStore';
import { fetchDMwithUser, startNewDM } from '../chat/services';
import { v4 as uuidv4 } from 'uuid';
import { initializeRoomKeys } from '../../services/roomKeyInitialization';

const UserQuickView = ({ userDetails, onEditProfileClick, position }) => {
  const currentUserID = useAuthStore(state => state.userID);
  const spaceID = useAuthStore(state => state.spaceID);
  const { setHoveringMenu } = useUIStore();
  const getUserStatus = useUserDetailsStore(state => state.getUserStatus);
  const { joinRoom, leaveRoom, fetchMessages } = useSidebarStore();
  
  const isCurrentUser = currentUserID === userDetails.userID;
  const isOnline = getUserStatus(userDetails.userID) === 'online';
  const isAdmin = userDetails.role === 'admin';

  const handleMessageClick = async () => {
    // if (isCurrentUser) return;

    // Clear current conversation
    // leaveRoom();
    useSidebarStore.getState().setMessages({});

    // Check for existing DM
    const dmConversations = await fetchDMwithUser(spaceID, currentUserID, userDetails.userID);
    let dmId;

    if (dmConversations.length > 0) {
      dmId = dmConversations[0].dm_id;
    } else {
      // Create new DM if none exists
      dmId = uuidv4();
      await startNewDM(spaceID, currentUserID, userDetails.userID, dmId);
      await initializeRoomKeys(currentUserID, spaceID, 'dm', dmId, [currentUserID, userDetails.userID]);
      
      // Update sidebar in real-time
      useSidebarStore.getState().fetchDMConversations(spaceID, currentUserID);
    }

    // Join the DM room and fetch messages
    joinRoom(dmId, 'dm', userDetails.userID, false);
    await fetchMessages(dmId, 'dm', new Date().toISOString(), 25);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      month: 'short',
      year: 'numeric'
    });
  };

  return (
    <div 
      className={`${styles.quickView} ${isCurrentUser && isAdmin ? styles.isCurrentUserAdmin : ''}`}
      style={
        isCurrentUser && isAdmin ? { top: position.top + 5, left: position.left } :
        isCurrentUser ? { top: position.top + 5, left: position.left } :
        isAdmin ? { top: position.top + 35, left: position.left } :
        { top: position.top + 40, left: position.left }
      }
      onMouseEnter={() => setHoveringMenu(true)}
      onMouseLeave={() => setHoveringMenu(false)}
    >
      {isAdmin && <span className={styles.adminBadge}>Workspace Owner</span>}

      <div className={styles.quickView_topBar}>
        <img src={userDetails.imageUrl} alt={`${userDetails.displayName}'s avatar`} className={styles.userImage} />
        
        <button 
          className={styles.messageButton} 
          onClick={handleMessageClick}
        >
          Message
        </button>
      </div>
        
      <div className={styles.quickView_userInfo}>
        <div className={styles.displayNameContainer}>
          <h3 className={styles.displayName}>{userDetails.displayName}</h3>
          <div className={`${styles.statusIndicator} ${isOnline ? styles.online : styles.away}`} />
        </div>
        
        {userDetails.bio && userDetails.bio.trim() && (
          <p className={styles.bio}>{userDetails.bio}</p>
        )}

        <div className={styles.metadataItem}>
          <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 16 16">
            <rect width="16" height="16" fill="none" />
            <path fill="currentColor" d="M8 15c-3.86 0-7-3.14-7-7s3.14-7 7-7s7 3.14 7 7s-3.14 7-7 7M8 2C4.69 2 2 4.69 2 8s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6" />
            <path fill="currentColor" d="M10 10.5c-.09 0-.18-.02-.26-.07l-2.5-1.5A.5.5 0 0 1 7 8.5v-4c0-.28.22-.5.5-.5s.5.22.5.5v3.72l2.26 1.35a.502.502 0 0 1-.26.93" />
          </svg>

          <span className={styles.metadataValue}>Joined on {formatDate(userDetails.joinDate)}</span>
        </div>
      </div>

      {isCurrentUser && (
        <button className={styles.editProfileButton} onClick={onEditProfileClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <rect width="24" height="24" fill="none" />
            <path fill="currentColor" d="M6.414 15.89L16.556 5.748l-1.414-1.414L5 14.476v1.414zm.829 2H3v-4.243L14.435 2.212a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414zM3 19.89h18v2H3z" />
          </svg>
          Edit Profile
        </button>
      )}
    </div>
  );
};

export default UserQuickView;