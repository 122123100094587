import { openDB } from 'idb';

async function initializeKeyStore() {
  const db = await openDB('E2EEKeyStore', 4, {
    upgrade(db, oldVersion) {
      if (oldVersion < 4) {
        const identityStore = db.createObjectStore('identity_keys', {
          keyPath: ['workspace_id', 'user_id']
        });
        identityStore.createIndex('by_workspace', 'workspace_id');
        identityStore.createIndex('by_user', 'user_id');

        const dmStore = db.createObjectStore('dm_keys', {
          keyPath: ['workspace_id', 'user_id', 'dm_id', 'created_at']
        });
        dmStore.createIndex('by_room', ['workspace_id', 'user_id', 'dm_id']);

        const groupStore = db.createObjectStore('group_keys', {
          keyPath: ['workspace_id', 'user_id', 'group_id', 'created_at']
        });
        groupStore.createIndex('by_room', ['workspace_id', 'user_id', 'group_id']);

        const channelStore = db.createObjectStore('channel_keys', {
          keyPath: ['workspace_id', 'user_id', 'channel_id', 'created_at']
        });
        channelStore.createIndex('by_room', ['workspace_id', 'user_id', 'channel_id']);
      }
    },
  });
  
  // Helper function to get the appropriate key version
  async function getKeyForTimestamp(storeName, workspace_id, user_id, room_id, timestamp) {
    const store = db.transaction(storeName).store;
    const keys = await store.index('by_room').getAll(IDBKeyRange.only([workspace_id, user_id, room_id]));
    
    if (!keys.length) return null;

    // Sort by creation time descending (newest first)
    keys.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    
    // If no timestamp provided, return latest key
    if (!timestamp) {
      return keys[0].value;
    }

    const messageTime = new Date(timestamp).getTime();
    
    // Find the key that was active when the message was created
    // We want the newest key that is older than or equal to the message timestamp
    let appropriateKey = null;
    
    for (const key of keys) {
      const keyTime = new Date(key.created_at).getTime();
      if (keyTime <= messageTime) {
        appropriateKey = key;
        break;
      }
    }

    if (!appropriateKey) {
      console.warn(
        `No key found for timestamp ${timestamp} in ${storeName}. `,
        `Available key timestamps: ${keys.map(k => k.created_at).join(', ')}`
      );
      return keys[0].value;
    }

    return appropriateKey.value;
  }
  
  return {
    // Identity Key operations
    async setIdentityKey(userId, workspaceId, value) {
      await db.put('identity_keys', {
        workspace_id: workspaceId,
        user_id: userId,
        value: value,
        created_at: new Date().toISOString()
      });
    },
    
    async getIdentityKey(userId, workspaceId) {
      return await db.get('identity_keys', [workspaceId, userId]);
    },

    // DM Key operations
    async setDMKey(userId, workspaceId, dmId, value, createdAt) {
      await db.put('dm_keys', {
        workspace_id: workspaceId,
        user_id: userId,
        dm_id: dmId,
        value: value,
        created_at: createdAt
      });
    },
    
    async getDMKey(userId, workspaceId, dmId, timestamp) {
      return await getKeyForTimestamp('dm_keys', workspaceId, userId, dmId, timestamp);
    },

    // Group Key operations
    async setGroupKey(userId, workspaceId, groupId, value, createdAt) {
      await db.put('group_keys', {
        workspace_id: workspaceId,
        user_id: userId,
        group_id: groupId,
        value: value,
        created_at: createdAt
      });
    },
    
    async getGroupKey(userId, workspaceId, groupId, timestamp) {
      return await getKeyForTimestamp('group_keys', workspaceId, userId, groupId, timestamp);
    },

    // Channel Key operations
    async setChannelKey(userId, workspaceId, channelId, value, createdAt) {
      await db.put('channel_keys', {
        workspace_id: workspaceId,
        user_id: userId,
        channel_id: channelId,
        value: value,
        created_at: createdAt
      });
    },
    
    async getChannelKey(userId, workspaceId, channelId, timestamp) {
      return await getKeyForTimestamp('channel_keys', workspaceId, userId, channelId, timestamp);
    },

    async getAllRoomKeys(userId, workspaceId) {
      const dmKeys = await db.getAll('dm_keys', [workspaceId, userId]);
      const groupKeys = await db.getAll('group_keys', [workspaceId, userId]);
      const channelKeys = await db.getAll('channel_keys', [workspaceId, userId]);
      
      return [
        ...dmKeys.map(k => ({ type: 'dm', id: k.dm_id, created_at: k.created_at })),
        ...groupKeys.map(k => ({ type: 'group_chat', id: k.group_id, created_at: k.created_at })),
        ...channelKeys.map(k => ({ type: 'channel', id: k.channel_id, created_at: k.created_at }))
      ];
    }
  };
}

async function getAllKeysForRoom(userId, workspaceId, roomType, roomId) {
  const db = await openDB('E2EEKeyStore', 4);
  let storeName;
  
  switch (roomType) {
    case 'dm':
      storeName = 'dm_keys';
      break;
    case 'group_chat':
      storeName = 'group_keys';
      break;
    case 'channel':
      storeName = 'channel_keys';
      break;
    default:
      throw new Error('Invalid room type');
  }
  
  const store = db.transaction(storeName).store;
  const keys = await store.index('by_room').getAll(IDBKeyRange.only([workspaceId, userId, roomId]));
  
  return keys.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
}

export { initializeKeyStore, getAllKeysForRoom };