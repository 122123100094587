import React, { useState, useRef } from 'react';
import styles from './AccountView.module.css';
import useUserDetailsStore from '../../../../store/userDetailsStore';
import useAuthStore from '../../../../store/authStore';
import { updateUserProfile } from '../../../user/services/userService';
import imageCompression from 'browser-image-compression';
import useThemeStore from '../../../../store/themeStore';
import useSidebarStore from '../../../../store/sidebarStore';

const AccountView = () => {
  const userDetails = useUserDetailsStore(state => state.userDetails);
  const { userID, spaceID } = useAuthStore();
  const currentUser = userDetails.get(userID);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [displayName, setDisplayName] = useState(currentUser?.userSpaceDetails?.user_display_name || '');
  const [bio, setBio] = useState(currentUser?.userSpaceDetails?.user_bio || '');
  const [userImage, setUserImage] = useState(currentUser?.userSpaceDetails?.user_image_url || '');
  const fileInputRef = useRef(null);
  const { theme, setTheme } = useThemeStore();

  if (!currentUser) return null;

  const { email } = currentUser;

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageLoading(true);
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageData = reader.result;
          setUserImage(imageData);
          
          try {
            const response = await updateUserProfile(userID, spaceID, { image: imageData });
            if (response.userDetails) {
              // Emit socket event for real-time updates
              const socket = useSidebarStore.getState().socket;
              socket.emit("update_user_details", {
                spaceId: spaceID,
                userId: userID,
                updates: {
                  user_display_name: displayName,
                  user_bio: bio,
                  user_image_url: imageData
                }
              });

              useUserDetailsStore.getState().updateUserDetails(userID, {
                user_image_url: imageData
              });
            }
          } catch (error) {
            console.error('Error updating profile image:', error);
          }
          
          setIsImageLoading(false);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error handling image:', error);
        setIsImageLoading(false);
      }
    }
  };

  const handleInputChange = async (field, value) => {
    const updates = {};
    if (field === 'displayName') {
      setDisplayName(value);
      updates.displayName = value;
    } else if (field === 'bio') {
      setBio(value);
      updates.bio = value;
    }

    try {
      const response = await updateUserProfile(userID, spaceID, updates);
      if (response.userDetails) {
        useUserDetailsStore.getState().updateUserDetails(userID, {
          user_display_name: response.userDetails.user_display_name,
          user_bio: response.userDetails.user_bio
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    document.documentElement.setAttribute('data-theme', newTheme);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Account Details</p>
      
      <div className={styles.section}>
        <div className={styles.formSection}>
          <div className={styles.inputGroup}>
            <label>Display Name</label>
            <input 
              type="text" 
              value={displayName} 
              className={styles.input} 
              maxLength={50}
              onChange={(e) => handleInputChange('displayName', e.target.value)}
            />
          </div>

          <div className={styles.inputGroup2}>
            <label>Bio Description</label>
            <textarea 
              className={styles.textarea}
              value={bio}
              maxLength={250}
              onChange={(e) => handleInputChange('bio', e.target.value)}
            />
          </div>
        </div>

        <div className={styles.imageSection}>
          <label>Profile Image</label>
          <div className={styles.addPhoto} onClick={handleImageClick}>
            <img 
              src={userImage || '/default-avatar.png'} 
              alt="Profile" 
              className={styles.profileImage}
            />
            <div className={styles.uploadOverlay}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24">
                <rect width="24" height="24" fill="none" />
                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                  <path d="M2 19V9a2 2 0 0 1 2-2h.5a2 2 0 0 0 1.6-.8l2.22-2.96A.6.6 0 0 1 8.8 3h6.4a.6.6 0 0 1 .48.24L17.9 6.2a2 2 0 0 0 1.6.8h.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2" />
                  <path d="M12 17a4 4 0 1 0 0-8a4 4 0 0 0 0 8" />
                </g>
              </svg>
              <span>Upload Photo</span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <div className={styles.section2}>
        <p className={styles.title}>Preferences</p>
        
        <div className={styles.settings}>
          <div>
            <h3>Appearance</h3>
            <p>Change the theme of your workspace.</p>
          </div>
          <select 
            className={styles.select}
            value={theme}
            onChange={(e) => handleThemeChange(e.target.value)}
          >
            <option value="dark">Dark</option>
            {/* <option value="light">Light</option> */}
            {/* <option value="blue">Ocean</option> */}
          </select>
        </div>

        <div className={styles.timezoneSettings}>
          <div>
            <h3>Default Timezone</h3>
            <p>Select the timezone of your HQ. Can be overridden by individuals.</p>
          </div>
          <select className={styles.select}>
            <option>GMT-6</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export {AccountView};