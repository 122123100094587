import React from 'react';
import { format } from 'date-fns';
import styles from './PDFPreviewModal.module.css';
import useFileDetails from '../../fileAttachments/hooks/useFileDetails';

const PDFPreviewModal = ({ file, onClose }) => {
  const fileDetails = useFileDetails(file);

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.fileInfo}>
            <div className={styles.senderInfo}>
              <img 
                src={fileDetails.senderDetails?.user_image_url || 'default-avatar.png'} 
                alt={`${fileDetails.senderDetails?.user_display_name}'s avatar`} 
                className={styles.senderAvatar} 
              />
              <div className={styles.senderDetails}>
                <span className={styles.senderName}>
                  {fileDetails.senderDetails?.user_display_name || 'Loading...'}
                </span>
                <span>Shared on {format(new Date(file.created_at), 'MMM dd, yyyy')}</span>
              </div>
            </div>
          </div>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.pdfContainer}>
          <div className={styles.pdfWrapper}>
            <iframe 
              src={`${file.url}#toolbar=1&zoom=scale&scale=1.0`} 
              className={styles.pdfFrame}
              title="PDF Preview"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFPreviewModal; 