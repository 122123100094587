import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SavedFoldersView.module.css';
import useSavedStore from '../../store/savedStore';
import { v4 as uuidv4 } from 'uuid';

const SavedFoldersView = () => {
  const navigate = useNavigate();
  const { savedFolders, fetchSavedFolders, createFolder } = useSavedStore();
  const [searchQuery, setSearchQuery] = useState('');
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  useEffect(() => {
    fetchSavedFolders();
  }, []);

  const filteredFolders = savedFolders.filter(folder => 
    folder.folder_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFolderClick = (folderId) => {
    navigate(`/saved/${folderId}`);
  };

  const handleCreateFolder = async (e) => {
    e.preventDefault();
    if (!newFolderName.trim()) return;

    try {
      const folderID = uuidv4();
      await createFolder(newFolderName, folderID);
      setNewFolderName('');
      setIsCreatingFolder(false);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  return (
    <div className={styles.savedFoldersView}>
      <div className={styles.header}>
        <h2>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3"/>
          </svg>
          Saved
        </h2>
        <button 
          className={styles.createFolderButton}
          onClick={() => setIsCreatingFolder(true)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19 12.998h-6v6h-2v-6H5v-2h6v-6h2v6h6z"/>
          </svg>
          Create new folder
        </button>
      </div>

      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search folders..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className={styles.searchInput}
        />
      </div>

      <div className={styles.folderGrid}>
        {filteredFolders.map(folder => (
          <div 
            key={folder.folder_id}
            className={styles.folderCard}
            onClick={() => handleFolderClick(folder.folder_id)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
              <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3"/>
            </svg>
            <span>{folder.folder_name}</span>
          </div>
        ))}
      </div>

      {isCreatingFolder && (
        <div className={styles.createFolderModal}>
          <form onSubmit={handleCreateFolder}>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              placeholder="New folder name"
              autoFocus
            />
            <div className={styles.modalActions}>
              <button type="button" onClick={() => setIsCreatingFolder(false)}>
                Cancel
              </button>
              <button type="submit">Create</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default SavedFoldersView; 