import React, { useState, useRef } from 'react';
import styles from './MessageActions.module.css';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import useSavedStore from '../../store/savedStore';
import SaveMessagePopup from './saved/SaveMessagePopup';
import MessageMenu from './MessageMenu';
import useAuthStore from '../../store/authStore';
import useUserDetailsStore from '../../store/userDetailsStore';
import { deleteMessage, editMessage } from './services/messageService';
import useSidebarStore from '../../store/sidebarStore';

const MessageActions = ({ onReplyClick, isInThreadSidebar, message, onReactionSelect }) => {
  const { isMessageSaved, unsaveMessage } = useSavedStore();
  const [showReactionPicker, setShowReactionPicker] = useState(false);
  const [showSavePopup, setShowSavePopup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(message.message);
  const editInputRef = useRef(null);
  
  const { userID, spaceID } = useAuthStore();
  const getBasicUserDetails = useUserDetailsStore(state => state.getBasicUserDetails);
  const userDetails = getBasicUserDetails(userID);
  
  const isAdmin = userDetails?.role === 'admin';
  const isMessageOwner = message.sender_id === userID;

  const handleReactionClick = () => {
    setShowReactionPicker(!showReactionPicker);
  };

  const handleSaveClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (isMessageSaved(message.message_id)) {
      unsaveMessage(message.message_id);
    } else {
      setShowSavePopup(true);
    }
  };

  const handleDelete = async () => {
    try {
      const { currentRoom } = useSidebarStore.getState();
      await deleteMessage(spaceID, currentRoom.room_id, message.message_id, currentRoom.room_type, message.created_at);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleEditStart = () => {
    setIsEditing(true);
    setEditedContent(message.message);
    // Focus the input after it renders
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.focus();
      }
    }, 0);
  };

  const handleEditSubmit = async () => {
    if (editedContent.trim() === message.message) {
      setIsEditing(false);
      return;
    }

    try {
      const { currentRoom } = useSidebarStore.getState();
      await editMessage(
        spaceID,
        currentRoom.room_id,
        message.message_id,
        currentRoom.room_type,
        message.created_at,
        editedContent.trim()
      );
      setIsEditing(false);
    } catch (error) {
      console.error('Error editing message:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleEditSubmit();
    } else if (e.key === 'Escape') {
      setIsEditing(false);
      setEditedContent(message.message);
    }
  };

  return (
    <div className={styles.messageActions}>
      {isEditing ? (
        <div className={styles.editContainer}>
          <textarea
            ref={editInputRef}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            onKeyDown={handleKeyDown}
            className={styles.editInput}
            rows={1}
          />
          <div className={styles.editActions}>
            <button onClick={() => setIsEditing(false)} className={styles.cancelButton}>
              Cancel
            </button>
            <button onClick={handleEditSubmit} className={styles.saveButton}>
              Save
            </button>
          </div>
        </div>
      ) : (
        <>
          {!isInThreadSidebar && (
            <button className={styles.actionButton} id="reply-button" onClick={onReplyClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M19 19v-4q0-1.25-.875-2.125T16 12H6.825l3.6 3.6L9 17l-6-6l6-6l1.425 1.4l-3.6 3.6H16q2.075 0 3.538 1.463T21 15v4z"/></svg>
            </button>
          )}
          
          <button className={styles.actionButton} onClick={handleReactionClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.15em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="M22 11v1a10 10 0 1 1-9-10"/><path d="M8 14s1.5 2 4 2s4-2 4-2M9 9h.01M15 9h.01M16 5h6m-3-3v6"/></g></svg>
          </button>

          {showReactionPicker && (
            <div className={styles.emojiPickerPopup}>
              <Picker 
                data={data} 
                onEmojiSelect={(emoji) => {
                  onReactionSelect(emoji.native);
                  setShowReactionPicker(false);
                }}
                theme="dark"
                previewPosition="none"
                skinTonePosition="none"
                searchPosition="top"
                maxFrequentRows={2}
                width={320}
                height={400}
              />
            </div>
          )}

          <button className={styles.actionButton} onClick={handleSaveClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.25em" viewBox="0 0 24 24">
              {isMessageSaved(message.message_id) ? (
                <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3"/>
              ) : (
                <path fill="currentColor" d="M16 2H8a3 3 0 0 0-3 3v16a1 1 0 0 0 .5.87a1 1 0 0 0 1 0l5.5-3.18l5.5 3.18a1 1 0 0 0 .5.13a1 1 0 0 0 .5-.13A1 1 0 0 0 19 21V5a3 3 0 0 0-3-3m1 17.27l-4.5-2.6a1 1 0 0 0-1 0L7 19.27V5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1Z"/>
              )}
            </svg>
          </button>

          <button 
            className={styles.actionButton} 
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2m0-6a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2"/>
            </svg>
          </button>

          {showMenu && (
            <MessageMenu 
              onDelete={handleDelete}
              onEdit={handleEditStart}
              onClose={() => setShowMenu(false)}
              message={message}
              isAdmin={isAdmin}
              isMessageOwner={isMessageOwner}
            />
          )}

          {showSavePopup && (
            <SaveMessagePopup message={message} onClose={()=>setShowSavePopup(false)}/>
          )}
        </>
      )}
    </div>
  );
};

export default MessageActions;