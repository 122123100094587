import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './SavedMessagesView.module.css';
import SavedFoldersView from './SavedFoldersView';
import FolderMessagesView from './FolderMessagesView';

const SavedMessagesView = () => {
  const { folderId } = useParams();

  return (
    <div className={styles.savedMessagesContainer}>
      {!folderId ? (
        <SavedFoldersView />
      ) : (
        <FolderMessagesView folderId={folderId} />
      )}
    </div>
  );
};

export default SavedMessagesView;