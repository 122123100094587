import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';  
import { LoginForm, VerifyForm, sendLoginLink, verifyToken } from '../features/authentication';
import useAuthStore from '../store/authStore';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginView = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [showVerifyForm, setShowVerifyForm] = useState(false);
  
  const { isAuthenticated, initializeAuth, addAccount } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = location.state?.from;

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectUrl || '/', { replace: true });
    }
  }, [isAuthenticated, navigate, redirectUrl]);

  const handleLogin = async (email) => {
    const success = await sendLoginLink(email);
    if (success) {
      localStorage.setItem('pendingLoginEmail', email);
      setShowVerifyForm(true);
      setError('');
    } else {
      setError('Failed to send login link. Please try again.');
    }
  };

  const handleVerify = async (code) => {
    try {
      const joinCode = redirectUrl?.split('/').pop();
      const success = await verifyToken(code, joinCode);

      if (success) {
        const sessionValid = await initializeAuth();
        
        if (!sessionValid) {
          setError('Session validation failed');
          return;
        }

        const currentAccounts = useAuthStore.getState().activeAccounts;
        if (currentAccounts.some(acc => acc.email === success.email)) {
          // Clean up ALL localStorage items
          localStorage.removeItem('pendingLoginEmail');
          localStorage.removeItem('returnPath');
          localStorage.removeItem('addingAccount');
          
          // only show this toast if they were not joining a space
          toast('This account has already been added');
          navigate(redirectUrl || '/', { 
            replace: true, 
            state: { inviteProcessed: !!joinCode } 
          });
          return;
        }

        // Always add the account to activeAccounts
        await addAccount(success.email);

        // Clean up stored email and flags
        localStorage.removeItem('pendingLoginEmail');
        localStorage.removeItem('addingAccount');
        localStorage.removeItem('returnPath');

        navigate(redirectUrl || '/', { 
          replace: true, 
          state: { inviteProcessed: !!joinCode } 
        });
      } else {
        setError('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('Login verification error:', error);
      setError('Verification failed. Please try again.');
    }
  };

  return !isAuthenticated ? (
    <div>
      {!showVerifyForm ? (
        <LoginForm 
          email={email} 
          setEmail={setEmail} 
          handleLogin={handleLogin} 
          error={error}
          redirectUrl={redirectUrl}
        />
      ) : (
        <VerifyForm 
          handleVerify={handleVerify} 
          handleResend={() => {
            setShowVerifyForm(false);
            setError('');
            navigate('/login', { replace: true });
          }} 
          error={error} 
          email={email}
        />
      )}
    </div>
  ) : null;
};

export { LoginView };