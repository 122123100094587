import React from 'react';
import styles from '../FilesTab.module.css';

const FileLoadingState = () => {
  return (
    <div className={styles.loadingContainer}>
      {[...Array(8)].map((_, i) => (
        <div key={i} className={styles.fileCardSkeleton}>
          <div className={styles.filePreviewSkeleton} />
          <div className={styles.fileInfoSkeleton}>
            <div className={styles.skeletonLine} />
            <div className={styles.skeletonLine} />
            <div className={styles.skeletonLine} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default FileLoadingState; 