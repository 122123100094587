import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useLogout from '../hooks/useLogout';

const LogoutView = () => {
  const handleLogout = useLogout();
  const hasLoggedOut = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasLoggedOut.current) {
      hasLoggedOut.current = true;
      handleLogout().then(() => {
        navigate('/', { replace: true });
      });
    }
  }, [handleLogout, navigate]);

  return null;
};

export { LogoutView };