import { storeIdentityKey } from "../../../services/indexedDBService";
import { stringToRsaKey } from "../../../utils/keyUtils";

const validateKeyFile = async (file) => {
  try {
    // Read file content
    const text = await file.text();
    
    // Extract sections from PEM format
    const sections = {};
    let currentSection = null;
    
    text.split('\n').forEach(line => {
      const beginMatch = line.match(/-----BEGIN (.+)-----/);
      const endMatch = line.match(/-----END (.+)-----/);
      
      if (beginMatch) {
        currentSection = beginMatch[1];
        sections[currentSection] = [];
      } else if (endMatch) {
        currentSection = null;
      } else if (currentSection && line.trim()) {
        sections[currentSection].push(line.trim());
      }
    });

    // Validate required sections exist
    if (!sections['WORKSPACE INFO'] || !sections['PRIVATE KEY'] || !sections['PUBLIC KEY']) {
      throw new Error('Invalid key file format: Missing required sections');
    }

    // Parse workspace info
    const workspaceInfo = sections['WORKSPACE INFO'].join('\n');
    const workspaceId = workspaceInfo.match(/Workspace:\s*([^\n]+)/)?.[1]?.trim();

    if (!workspaceId) {
      throw new Error('Invalid key file: Missing workspace ID');
    }

    // Convert key strings to CryptoKey objects
    const privateKeyString = sections['PRIVATE KEY'].join('');
    const publicKeyString = sections['PUBLIC KEY'].join('');

    const privateKey = await stringToRsaKey(privateKeyString, true);
    const publicKey = await stringToRsaKey(publicKeyString, false);

    return {
      workspaceId,
      identityKey: {
        privateKey,
        publicKey
      }
    };
  } catch (error) {
    console.error('Error validating key file:', error);
    throw new Error('Invalid key file format. Please ensure you uploaded the correct backup file.');
  }
};

const storeIdentityKeyFile = async (userId, workspaceId, keys) => {
  if (workspaceId !== keys.workspaceId) {
    throw new Error('Workspace ID mismatch');
  }
  await storeIdentityKey(userId, workspaceId, keys.identityKey);
};

export { validateKeyFile, storeIdentityKeyFile };