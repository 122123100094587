import React from 'react';
import styles from './MessageMenu.module.css';

const MessageMenu = ({ onDelete, onEdit, onClose, message, isAdmin, isMessageOwner }) => {
  return (
    <div className={styles.menuContainer}>
      <ul className={styles.menuList}>
        <li 
          className={styles.menuItem} 
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(message.message);
            onClose();
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
            <rect width="24" height="24" fill="none" />
            <g fill="none" stroke="currentColor" strokeWidth="1.5">
              <path d="M6 11c0-2.828 0-4.243.879-5.121C7.757 5 9.172 5 12 5h3c2.828 0 4.243 0 5.121.879C21 6.757 21 8.172 21 11v5c0 2.828 0 4.243-.879 5.121C19.243 22 17.828 22 15 22h-3c-2.828 0-4.243 0-5.121-.879C6 20.243 6 18.828 6 16z" />
              <path d="M6 19a3 3 0 0 1-3-3v-6c0-3.771 0-5.657 1.172-6.828S7.229 2 11 2h4a3 3 0 0 1 3 3" />
            </g>
          </svg>
          Copy Message
        </li>     
        
        {isMessageOwner && (
          <>
            <li 
              className={`${styles.menuItem} ${styles.disabled}`} 
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 32 32">
                <rect width="32" height="32" fill="none" />
                <path fill="currentColor" d="M2 26h28v2H2zM25.4 9c.8-.8.8-2 0-2.8l-3.6-3.6c-.8-.8-2-.8-2.8 0l-15 15V24h6.4zm-5-5L24 7.6l-3 3L17.4 7zM6 22v-3.6l10-10l3.6 3.6l-10 10z" />
              </svg>
              Edit Message
            </li>
            <div className={styles.divider} />
          </>
        )}

        {(isAdmin || isMessageOwner) && (
          <li 
            className={`${styles.menuItem} ${styles.deleteItem}`} 
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
              onClose();
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
              <rect width="24" height="24" fill="none" />
              <path fill="currentColor" d="M7 21q-.825 0-1.412-.587T5 19V6q-.425 0-.712-.288T4 5t.288-.712T5 4h4q0-.425.288-.712T10 3h4q.425 0 .713.288T15 4h4q.425 0 .713.288T20 5t-.288.713T19 6v13q0 .825-.587 1.413T17 21zM17 6H7v13h10zm-7 11q.425 0 .713-.288T11 16V9q0-.425-.288-.712T10 8t-.712.288T9 9v7q0 .425.288.713T10 17m4 0q.425 0 .713-.288T15 16V9q0-.425-.288-.712T14 8t-.712.288T13 9v7q0 .425.288.713T14 17M7 6v13z" />
            </svg>
            Delete Message
          </li>
        )}
      </ul>
    </div>
  );
};

export default MessageMenu; 