import { getDMKey, getGroupKey, getChannelKey } from './indexedDBService';
import { getAllKeysForRoom } from '../utils/indexedDB';
import { decryptAndStoreAllKeys } from './keyFetching';
import useSidebarStore from '../store/sidebarStore';

// Helper to get the appropriate key based on room type
async function getKeyForRoom(userId, workspaceId, roomType, roomId, timestamp) {
  switch (roomType) {
    case 'dm':
      return await getDMKey(userId, workspaceId, roomId, timestamp);
    case 'group_chat':
      return await getGroupKey(userId, workspaceId, roomId, timestamp);
    case 'channel':
      return await getChannelKey(userId, workspaceId, roomId, timestamp);
    case 'thread':
      const messages = useSidebarStore.getState().messages;
      const parentMessage = Object.values(messages).flat().find(msg => msg.message_id === roomId);

      return await getKeyForRoom(userId, workspaceId, parentMessage.type, parentMessage.room, timestamp);
    default:
      throw new Error("Invalid room type");
  }
}

async function performEncryption(message, key) {
  // Generate random IV
  const iv = window.crypto.getRandomValues(new Uint8Array(12));

  // Encrypt message with shared key
  const encoder = new TextEncoder();
  const messageData = encoder.encode(message);
  const encryptedMessage = await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv
    },
    key,
    messageData
  );

  // Combine IV and encrypted message
  const combined = new Uint8Array(iv.length + encryptedMessage.byteLength);
  combined.set(iv, 0);
  combined.set(new Uint8Array(encryptedMessage), iv.length);

  return btoa(String.fromCharCode.apply(null, combined));
}

async function performDecryption(encryptedData, key) {
  // Decode the base64 string
  const combined = new Uint8Array(atob(encryptedData).split('').map(char => char.charCodeAt(0)));
  
  // Extract IV (12 bytes)
  const iv = combined.slice(0, 12);
  const encryptedMessage = combined.slice(12);
  
  // Decrypt the message
  const decryptedData = await window.crypto.subtle.decrypt(
    { name: "AES-GCM", iv: iv },
    key,
    encryptedMessage
  );
  
  const decoder = new TextDecoder();
  return decoder.decode(decryptedData);
}

async function encryptMessage(userId, workspaceId, message, roomType, roomId, keyTimestamp) {
  try {
    const currentKey = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    if (currentKey) {
      return {
        encryptedData: await performEncryption(message, currentKey),
      };
    }
    
    await decryptAndStoreAllKeys(userId, workspaceId);
    const newKey = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    
    if (!newKey) {
      // restore account portal w/ upload field for backup identity key file
      throw new Error(`${roomType} key not found after refresh`);
    }
    
    return {
      encryptedData: await performEncryption(message, newKey),
    };
    
  } catch (error) {
    console.error("Encryption failed:", error);
    throw error;
  }
}

async function decryptMessage(userId, workspaceId, encryptedData, roomType, roomId, keyTimestamp) {
  if (!encryptedData) return '';
  
  try {
    const key = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    
    if (key) {
      try {
        return await performDecryption(encryptedData, key);
      } catch (error) {
        const allKeys = await getAllKeysForRoom(userId, workspaceId, roomType, roomId);
        
        for (const keyObj of allKeys) {
          try {
            const result = await performDecryption(encryptedData, keyObj.value);
            return result;
          } catch (e) {
            continue;
          }
        }
      }
    }

    try {
      await decryptAndStoreAllKeys(userId, workspaceId);
    } catch (error) {
      if (error.message === 'Identity key not found') {
        // Redirect to recovery portal
        window.location.href = '/recovery';
        throw new Error('IDENTITY_KEY_MISSING');
      }
      throw error;
    }

    const newKey = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    if (!newKey) {
      throw new Error('No key found after refresh');
    }
    
    return await performDecryption(encryptedData, newKey);
    
  } catch (error) {
    if (error.message === 'IDENTITY_KEY_MISSING') {
      throw error;
    }
    console.error(`Decryption failed for message in ${roomType} ${roomId}:`, error);
    throw error;
  }
}

export { encryptMessage, decryptMessage, getKeyForRoom };