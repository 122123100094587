import { storeDMKey, storeGroupKey, storeChannelKey } from './indexedDBService';

import { uploadEncryptedSenderKey } from './keyBackendService';
import { getIdentityKey } from './indexedDBService';

// Generate AES-GCM key for encryption/decryption
async function generateSharedKey() {
  return await window.crypto.subtle.generateKey(
    {
      name: "AES-GCM",
      length: 256
    },
    true,
    ["encrypt", "decrypt"]
  );
}

async function encryptKeyWithPublicKey(key, publicKey) {
  // Export the AES key to raw format
  const exportedKey = await window.crypto.subtle.exportKey("raw", key);
  
  // Encrypt with public key
  return await window.crypto.subtle.encrypt(
    { name: "RSA-OAEP" },
    publicKey,
    exportedKey
  );
}

// DM Key Generation and Registration
async function generateAndRegisterDMKey(userId, workspaceId, dmId, createdAt) {
  const sharedKey = await generateSharedKey();
  await storeDMKey(userId, workspaceId, dmId, sharedKey, createdAt);
  
  // Get user's public key and encrypt the shared key to store backup
  const identityKey = await getIdentityKey(userId, workspaceId);
  const encryptedKey = await encryptKeyWithPublicKey(sharedKey, identityKey.publicKey);
  
  // Upload encrypted key to server
  await uploadEncryptedSenderKey(userId, workspaceId, 'dm', dmId, encryptedKey, createdAt);
  
  return sharedKey;
}

// Group Key Generation and Registration
async function generateAndRegisterGroupKey(userId, workspaceId, groupId, createdAt) {
  const sharedKey = await generateSharedKey();
  await storeGroupKey(userId, workspaceId, groupId, sharedKey, createdAt);
  
  // Get user's public key and encrypt the shared key to store backup
  const identityKey = await getIdentityKey(userId, workspaceId);
  const encryptedKey = await encryptKeyWithPublicKey(sharedKey, identityKey.publicKey);
  
  // Upload encrypted key to server
  await uploadEncryptedSenderKey(userId, workspaceId, 'group', groupId, encryptedKey, createdAt);
  
  return sharedKey;
}

// Channel Key Generation and Registration
async function generateAndRegisterChannelKey(userId, workspaceId, channelId, createdAt) {
  const sharedKey = await generateSharedKey();
  await storeChannelKey(userId, workspaceId, channelId, sharedKey, createdAt);
  
  // Get user's public key and encrypt the shared key to store backup
  const identityKey = await getIdentityKey(userId, workspaceId);
  const encryptedKey = await encryptKeyWithPublicKey(sharedKey, identityKey.publicKey);

  // Upload encrypted key to server
  await uploadEncryptedSenderKey(userId, workspaceId, 'channel', channelId, encryptedKey, createdAt);

  return sharedKey;
}

export {
  generateAndRegisterDMKey,
  generateAndRegisterGroupKey,
  generateAndRegisterChannelKey,
  encryptKeyWithPublicKey,
  generateSharedKey
};
