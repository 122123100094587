const deleteMessage = async (spaceId, roomId, messageId, roomType, createdAt) => {
  let endpoint;
  switch (roomType) {
    case 'channel':
      endpoint = `channels/${spaceId}/${roomId}/messages/${messageId}`;
      break;
    case 'dm':
      endpoint = `dms/${roomId}/messages/${messageId}`;
      break;
    case 'group_chat':
      endpoint = `group-chats/${roomId}/messages/${messageId}`;
      break;
    default:
      throw new Error('Invalid room type');
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        created_at: createdAt,
        space_id: spaceId 
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to delete message');
    }

    return await response.json();
  } catch (error) {
    console.error('Error deleting message:', error);
    throw error;
  }
};

const editMessage = async (spaceId, roomId, messageId, roomType, createdAt, newContent) => {
  let endpoint;
  switch (roomType) {
    case 'channel':
      endpoint = `channels/${spaceId}/${roomId}/messages/${messageId}`;
      break;
    case 'dm':
      endpoint = `dms/${roomId}/messages/${messageId}`;
      break;
    case 'group_chat':
      endpoint = `group-chats/${roomId}/messages/${messageId}`;
      break;
    default:
      throw new Error('Invalid room type');
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        created_at: createdAt,
        space_id: spaceId,
        new_content: newContent
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to edit message');
    }

    return await response.json();
  } catch (error) {
    console.error('Error editing message:', error);
    throw error;
  }
};

export { deleteMessage, editMessage }; 