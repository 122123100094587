import { useState, useEffect } from 'react';
import { createChannel } from '../../../services/folderService';
import useAuthStore from '../../../../../store/authStore';
import useSidebarStore from '../../../../../store/sidebarStore';
import { initializeRoomKeys } from '../../../../../services/roomKeyInitialization';
import { v4 as uuidv4 } from 'uuid';
import { getSpaceMembersForKeyRotation } from '../../../services/spaceService';

const useCreateRoomModalViewModel = (initialFolderId) => {
  const [roomName, setRoomName] = useState('');
  const [description, setDescription] = useState('');
  const [folderId, setFolderId] = useState(initialFolderId);
  const [isPrivate, setIsPrivate] = useState(false);
  
  // Reset folderId when initialFolderId changes
  useEffect(() => {
    setFolderId(initialFolderId);
  }, [initialFolderId]);

  const spaceId = useAuthStore(state => state.spaceID);
  const userID = useAuthStore.getState().userID;

  const { socket, addChannelToFolder, joinRoom, fetchMessages } = useSidebarStore();

  const handleCreateRoom = async () => { 
    const channelId = uuidv4();
    const channelData = {
      channelId,
      channelName: roomName,
      channelDescription: description,
      spaceId: spaceId,
      folderId: folderId,
    };

    try {
      // Update local state immediately
      addChannelToFolder(folderId, channelData);
      
      // Join the new room immediately
      await joinRoom(channelId, 'channel', null);
      
      // Get all workspace members for key distribution
      const members = await getSpaceMembersForKeyRotation(spaceId);
      
      // Initialize encryption keys for all workspace members
      await initializeRoomKeys(userID, spaceId, 'channel', channelId, members);
      
      // Fire and forget these operations
      Promise.all([
        createChannel(folderId, channelData),
        fetchMessages(channelId, 'channel', new Date().toISOString(), 25),
        socket?.emit('room_created', {
          spaceId,
          folderId,
          channelData: {
            ...channelData,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString()
          }
        })
      ]).catch(error => {
        console.error("Background operations failed:", error);
      });

      setRoomName('');
      setDescription('');
      
      return channelId;
    } catch (error) {
      console.error("Failed to create room:", error);
      throw error;
    }
  };

  return {
    roomName,
    setRoomName,
    description,
    setDescription,
    handleCreateRoom,
    setFolderId,
    isPrivate,
    setIsPrivate
  };
};

export default useCreateRoomModalViewModel;