const fetchThreadMessages = async (spaceId, parentMessageId, before, limit) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}threads/${spaceId}/${parentMessageId}/replies?before=${before}&limit=${limit}`, {
      method: 'GET'
    });

    if (!response.ok) {
      throw new Error("Failed to fetch thread messages");
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching thread messages in service:", error);
    throw error;
  }
};

export {
  fetchThreadMessages
};