import imageCompression from 'browser-image-compression';

const fetchUserWorkspaces = async (email) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/get-user-workspaces?email=${encodeURIComponent(email)}`
    );

    if (!response.ok) {
      throw new Error("Failed to fetch workspaces");
    }

    const data = await response.json();
    
    return data.workspaces.map(workspace => ({
      ...workspace,
      imageUrl: workspace.image_url || '/default-avatar.png',
      description: workspace.description || 'No description available'
    })) || [];
  } catch (error) {
    console.error("Error fetching workspaces:", error);
    return [];
  }
}; 

const updateWorkspaceDetails = async (spaceID, updates) => {
  try {
    const formData = new FormData();
    formData.append('spaceID', spaceID);

    // Handle text updates
    if (updates.name) {
      formData.append('spaceName', updates.name);
    }
    if (updates.description) {
      formData.append('spaceDescription', updates.description);
    }

    // Handle image update
    if (updates.image) {
      let imageBlob;
      if (updates.image instanceof Blob || updates.image instanceof File) {
        imageBlob = updates.image;
      } else if (typeof updates.image === 'string' && updates.image.startsWith('data:')) {
        const response = await fetch(updates.image);
        imageBlob = await response.blob();
      } else {
        throw new Error('Invalid image format');
      }

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      };
      const compressedFile = await imageCompression(imageBlob, options);
      formData.append('spaceImage', compressedFile, 'workspace-image.jpg');
    }

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}space/update-workspace-details`,
      {
        method: 'PUT',
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error('Failed to update workspace details');
    }

    return await response.json();
  } catch (error) {
    console.error('Error updating workspace details:', error);
    throw error;
  }
};

export { fetchUserWorkspaces, updateWorkspaceDetails }; 