import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import styles from '../css/SpaceDetailsStep.module.css';
import { createSpace, updateRole, updateOnboardingStatus, updateUser } from '../services/onboardingService';
import { generateUniqueCode } from '../../../utils/uniqueIDGenerator';
import { createDefaultAvatar, getAvatarColor } from '../utils/imageUtils';
import { fetchUserWorkspaces } from '../services/WorkspaceService';
import { getStoredAccounts, setStoredAccounts } from '../../../utils/accountStorage';

const SpaceDetailsStep = () => {
  const [spaceName, setSpaceName] = useState('');
  const [spaceDescription, setSpaceDescription] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const { email, userID, setOnboardingStatus, establishUserSpace } = useAuthStore();

  useEffect(() => {
    setIsFormValid(spaceName.trim().length > 0);
  }, [spaceName]);

  const handleSubmit = async () => {
    if (!isFormValid) return;

    try {
      const spaceID = generateUniqueCode();
      const adminRole = 'admin';

      await updateUser(email, spaceID);

      const storedAccounts = getStoredAccounts();
      const updatedAccounts = storedAccounts.map(account => {
        if (account.email === email) {
          return { ...account, lastActiveSpace: spaceID };
        }
        return account;
      });
      setStoredAccounts(updatedAccounts);

      const spacePreviewColor = getAvatarColor(spaceName);
      const spaceImageToSend = createDefaultAvatar(spaceName, spacePreviewColor);
      await createSpace(userID, spaceID, spaceName, spaceImageToSend, spaceDescription, false);
      await updateRole(userID, spaceID, adminRole);
      await updateOnboardingStatus(userID, spaceID, 'create/backup');

      const workspaces = await fetchUserWorkspaces(email);
      useAuthStore.getState().setWorkspaces(workspaces);
      establishUserSpace(spaceID, adminRole);
      setOnboardingStatus('create/backup');

      navigate('/create/backup', { replace: true });
    } catch (error) {
      console.error('Error creating space:', error);
      navigate('/create/space', { replace: true });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <h2 className={styles.title}>Create a new workspace</h2>
        <p className={styles.subtitle}>Workspaces are where communication happens. All your messages are end-to-end encrypted.</p>
        
        <div className={`${styles.inputGroup} ${styles.firstInputGroup}`}>
          <label className={styles.label}>Workspace Name</label>
          <input
            type="text"
            className={styles.input}
            value={spaceName}
            placeholder="Enter your workspace name"
            onChange={(e) => setSpaceName(e.target.value)}
            maxLength={25}
          />
        </div>

        <div className={styles.inputGroup}>
          <label className={styles.label}>Workspace Description</label>
          <textarea
            className={styles.input}
            value={spaceDescription}
            placeholder="What does your workspace do?"
            onChange={(e) => setSpaceDescription(e.target.value)}
            maxLength={200}
          />
        </div>

        <button 
          onClick={handleSubmit} 
          className={styles.continueButton}
          disabled={!isFormValid}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export { SpaceDetailsStep };