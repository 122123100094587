import React, { useState, useEffect } from 'react';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import styles from './MessageReactions.module.css';
import useAuthStore from '../../store/authStore';
import useUserDetailsStore from '../../store/userDetailsStore';

const MessageReactions = ({ message, handleReactionClick }) => {
  const { userID } = useAuthStore();
  const [showReactionPicker, setShowReactionPicker] = useState(false);
  const [hoveredReaction, setHoveredReaction] = useState(null);
  const [userNames, setUserNames] = useState({});
  const getBasicUserDetails = useUserDetailsStore(state => state.getBasicUserDetails);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(`.${styles.emojiPickerPopup}`)) {
        setShowReactionPicker(false);
      }
    };

    if (showReactionPicker) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showReactionPicker]);

  useEffect(() => {
    const fetchUserNames = async () => {
      const names = {};
      for (const [reaction, users] of Object.entries(message.reactions || {})) {
        names[reaction] = await Promise.all(
          users.map(async (userId) => {
            const userDetails = getBasicUserDetails(userId);
            return userDetails?.displayName || 'Unknown User';
          })
        );
      }
      setUserNames(names);
    };

    fetchUserNames();
  }, [message.reactions, getBasicUserDetails]);

  const formatReactionText = (names, reaction) => {
    if (names.length === 0) return '';
    
    return (
      <span>
        {names.map((name, index) => (
          <>
            <span className={styles.userName}>{name},</span>
            {index < names.length - 1}
          </>
        ))}
        <span> reacted with {reaction}</span>
      </span>
    );
  };

  const onReactionClick = async (reaction) => {
    await handleReactionClick(reaction);
    setShowReactionPicker(false);
  };

  const hasReactions = message.reactions && Object.keys(message.reactions).length > 0;

  return (
    <div className={styles.reactionsContainer}>
      {Object.entries(message.reactions || {}).map(([reaction, users]) => (
        <button
          key={reaction}
          className={`${styles.reactionButton} ${users.includes(userID) ? styles.active : ''}`}
          onClick={() => onReactionClick(reaction)}
          onMouseEnter={() => setHoveredReaction(reaction)}
          onMouseLeave={() => setHoveredReaction(null)}
        >
          <>
            <span className={styles.reactionEmoji}>{reaction}</span>
            <span className={styles.reactionCount}>{users.length}</span>
            {hoveredReaction === reaction && userNames[reaction] && (
              <div className={styles.reactionUserPopup}>
                {formatReactionText(userNames[reaction], reaction)}
              </div>
            )}
          </>
        </button>
      ))}
      
      {hasReactions && (
        <button
          className={styles.addReactionButton}
          onClick={() => setShowReactionPicker(!showReactionPicker)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24">
            <rect width="24" height="24" fill="none" />
            <path fill="currentColor" d="M12 22q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2q.825 0 1.625.125t1.55.375q.575.2.725.588t.025.762t-.462.588t-.763.037q-.65-.225-1.312-.35T12 4Q8.675 4 6.337 6.338T4 12t2.338 5.663T12 20t5.663-2.337T20 12q0-.45-.05-.9t-.15-.875q-.125-.475.125-.775t.625-.375t.738.113t.487.712q.125.5.175 1.025T22 12q0 2.075-.787 3.9t-2.138 3.175t-3.175 2.138T12 22m8-17h-1q-.425 0-.712-.288T18 4t.288-.712T19 3h1V2q0-.425.288-.712T21 1t.713.288T22 2v1h1q.425 0 .713.288T24 4t-.288.713T23 5h-1v1q0 .425-.288.713T21 7t-.712-.288T20 6zm-4.5 6q.625 0 1.063-.437T17 9.5t-.437-1.062T15.5 8t-1.062.438T14 9.5t.438 1.063T15.5 11m-7 0q.625 0 1.063-.437T10 9.5t-.437-1.062T8.5 8t-1.062.438T7 9.5t.438 1.063T8.5 11m3.5 6.5q1.45 0 2.675-.7t1.975-1.9q.15-.3-.025-.6T16.1 14H7.9q-.35 0-.525.3t-.025.6q.75 1.2 1.988 1.9t2.662.7" />
          </svg>
        </button>
      )}

      {showReactionPicker && (
        <div className={styles.emojiPickerPopup}>
          <Picker 
            data={data} 
            onEmojiSelect={(emoji) => onReactionClick(emoji.native)}
            theme="dark"
            previewPosition="none"
            skinTonePosition="none"
            searchPosition="none"
            maxFrequentRows={2}
          />
        </div>
      )}
    </div>
  );
};

export default MessageReactions; 