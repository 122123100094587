import { create } from 'zustand';
import { saveMessage, unsaveMessage, getSavedFolders, createSavedFolder, getSavedMessages } from '../features/saved/services/savedService';
import useAuthStore from './authStore';

const useSavedStore = create((set, get) => ({
  savedFolders: [],
  savedMessages: {},
  currentSavedFolder: null,
  isSavedMessagesFetched: false,
  savedMessagesByMessageId: new Map(),

  fetchSavedFolders: async () => {
    try {
      const { spaceID, userID } = useAuthStore.getState();

      const folders = await getSavedFolders(spaceID, userID);
      set({ savedFolders: folders });
    } catch (error) {
      console.error("Error fetching saved folders:", error);
    }
  },

  createFolder: async (folderName, folderID) => {
    try {
      const { spaceID, userID } = useAuthStore.getState();

      const newFolder = await createSavedFolder(spaceID, userID, folderName, folderID);
      set(state => ({
        savedFolders: [...state.savedFolders, newFolder]
      }));
      return newFolder;
    } catch (error) {
      console.error("Error creating folder:", error);
      throw error;
    }
  },

  saveMessageToFolder: async (folderID, messageData) => {
    try {
      const state = get();
      // Check if message is already saved
      const existingFolderID = state.savedMessagesByMessageId.get(messageData.message_id);
          
      if (existingFolderID) {
        await get().unsaveMessage(messageData.message_id);
        return null;
      }

      const { spaceID, userID } = useAuthStore.getState();
      const result = await saveMessage(spaceID, userID, folderID, messageData);
      
      set(state => {
        const newSavedMessages = { ...state.savedMessages };
        const folderMessages = newSavedMessages[folderID] || [];
        
        newSavedMessages[folderID] = [...folderMessages, { 
          ...messageData, 
          saved_at: result.saved_at 
        }];

        const newMap = new Map(state.savedMessagesByMessageId);
        newMap.set(messageData.message_id, folderID);
        
        return { 
          savedMessages: newSavedMessages,
          savedMessagesByMessageId: newMap
        };
      });

      return result;
    } catch (error) {
      console.error("Error saving message:", error);
      throw error;
    }
  },

  unsaveMessage: async (messageID) => {
    try {
      const { spaceID, userID } = useAuthStore.getState();
      const state = get();
      const folderID = state.savedMessagesByMessageId.get(messageID);
      
      if (!folderID) return;

      await unsaveMessage(spaceID, userID, folderID, messageID);
      
      set(state => {
        const newSavedMessages = { ...state.savedMessages };
        const folderMessages = newSavedMessages[folderID] || [];
        
        newSavedMessages[folderID] = folderMessages.filter(
          msg => msg.message_id !== messageID
        );

        const newMap = new Map(state.savedMessagesByMessageId);
        newMap.delete(messageID);
        
        return { 
          savedMessages: newSavedMessages,
          savedMessagesByMessageId: newMap
        };
      });
    } catch (error) {
      console.error("Error unsaving message:", error);
      throw error;
    }
  },

  fetchSavedMessages: async (folderID) => {
    try {
      const { spaceID } = useAuthStore.getState();
      const messages = await getSavedMessages(spaceID, folderID);
      
      set(state => {
        const newMap = new Map(state.savedMessagesByMessageId);
        messages.forEach(msg => newMap.set(msg.original_message_id, folderID));
        
        return {
          savedMessages: { 
            ...state.savedMessages, 
            [folderID]: messages 
          },
          savedMessagesByMessageId: newMap,
          currentSavedFolder: folderID,
          isSavedMessagesFetched: true
        };
      });
    } catch (error) {
      console.error("Error fetching saved messages:", error);
    }
  },

  isMessageSaved: (messageID) => {
    return get().savedMessagesByMessageId.has(messageID);
  },

  initializeSavedMessages: async () => {
    try {
      const { spaceID, userID } = useAuthStore.getState();
      const folders = await getSavedFolders(spaceID, userID);
      
      set({ savedFolders: folders });

      // Fetch messages for each folder
      const messagesMap = {};
      const messageIdMap = new Map();

      await Promise.all(folders.map(async folder => {
        const messages = await getSavedMessages(spaceID, userID, folder.folder_id);
        messagesMap[folder.folder_id] = messages;
        
        // Update the messageIdMap
        messages.forEach(msg => {
          messageIdMap.set(msg.original_message_id, folder.folder_id);
        });
      }));

      set({
        savedMessages: messagesMap,
        savedMessagesByMessageId: messageIdMap,
        isSavedMessagesFetched: true
      });
    } catch (error) {
      console.error("Error initializing saved messages:", error);
    }
  }
}));

export default useSavedStore;
