async function cryptoKeyToString(key, format) {
  // Export the key to the specified format (pkcs8 for private, spki for public)
  const exportedKey = await window.crypto.subtle.exportKey(format, key);
  
  // Convert ArrayBuffer to base64 string
  return btoa(String.fromCharCode(...new Uint8Array(exportedKey)));
}

async function stringToCryptoKey(base64String, format, algorithm, usages) {
  // Convert base64 string to ArrayBuffer
  const binaryKey = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));
  
  // Import as CryptoKey
  return await window.crypto.subtle.importKey(
    format,
    binaryKey,
    algorithm,
    true,
    usages
  );
}

async function rsaKeyToString(key, isPrivate = false) {
  return cryptoKeyToString(key, isPrivate ? "pkcs8" : "spki");
}

async function stringToRsaKey(base64String, isPrivate = false) {
  return stringToCryptoKey(
    base64String,
    isPrivate ? "pkcs8" : "spki",
    {
      name: "RSA-OAEP",
      hash: "SHA-256"
    },
    isPrivate ? ["decrypt"] : ["encrypt"]
  );
}

async function aesKeyToString(key) {
  return cryptoKeyToString(key, "raw");
}

async function stringToAesKey(base64String) {
  return stringToCryptoKey(
    base64String,
    "raw", 
    {
      name: "AES-GCM",
      length: 256
    },
    ["encrypt", "decrypt"]
  );
}

export { 
  cryptoKeyToString, 
  stringToCryptoKey,
  rsaKeyToString,
  stringToRsaKey,
  aesKeyToString,
  stringToAesKey
};