import { create } from 'zustand';
import { getFilesWithFilters } from '../features/fileAttachments/services/fileAttachmentService';
import { decryptFile } from '../services/fileEncryption';
import useUserDetailsStore from '../store/userDetailsStore';
import useAuthStore from '../store/authStore';
import useSidebarStore from '../store/sidebarStore';

const useFileStore = create((set, get) => ({
  files: [],
  hasMore: true,
  page: 1,
  loading: false,
  decryptedUrls: {},
  userDetailsCache: {},
  isInitialized: false,
  filters: {
    dateRange: 'last30',
    startDate: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
    endDate: new Date().toISOString(),
    fileType: '',
    roomID: '',
    senderID: '',
    searchQuery: ''
  },

  setFiles: (files) => set({ files }),
  setHasMore: (hasMore) => set({ hasMore }),
  setPage: (page) => set({ page }),
  setLoading: (loading) => set({ loading }),
  setFilters: (filters) => set({ filters }),
  setDecryptedUrls: (urls) => set({ decryptedUrls: urls }),

  getUserDetails: async (userId) => {
    const { userDetailsCache } = get();
    if (userDetailsCache[userId]) {
      return userDetailsCache[userId];
    }

    try {
      const details = await useUserDetailsStore.getState().fetchUserDetails(userId);
      const userDetails = {
        displayName: details?.userSpaceDetails?.user_display_name,
        avatarUrl: details?.userSpaceDetails?.avatar_url
      };
      
      set(state => ({
        userDetailsCache: {
          ...state.userDetailsCache,
          [userId]: userDetails
        }
      }));
      
      return userDetails;
    } catch (error) {
      console.error('Error fetching user details:', error);
      return { displayName: '', avatarUrl: '' };
    }
  },

  initializeFiles: async (spaceId, { accessibleRooms }) => {
    if (get().isInitialized) return;

    try {
      const { userID } = useAuthStore.getState();
      
      // Fetch files with filters and accessible rooms
      const files = await getFilesWithFilters(spaceId, {
        ...get().filters,
        page: 1,
        limit: 15,
        accessibleRooms: accessibleRooms.map(room => ({
          id: room.id,
          type: room.type
        }))
      });

      // Get unique user IDs and fetch their details
      const uniqueUserIds = [...new Set(files.map(file => file.sender_id))];
      await Promise.all(uniqueUserIds.map(userId => get().getUserDetails(userId)));

      // Decrypt files in parallel
      const decryptedUrls = {};
      await Promise.all(files.map(async (file) => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}attachments/signed-url?key=${encodeURIComponent(file.key || file.file_url)}`
          );
          
          if (!response.ok) return;
          
          const { signedUrl } = await response.json();
          const encryptedBlob = await fetch(signedUrl).then(r => r.blob());

          const decryptedBlob = await decryptFile(
            encryptedBlob,
            userID,
            file.space_id,
            file.room_type,
            file.room_id,
            file.key_timestamp
          );
          
          decryptedUrls[file.key || file.file_url] = {
            url: URL.createObjectURL(decryptedBlob),
            fileType: decryptedBlob.type || file.file_type
          };
        } catch (error) {
          console.error('Error decrypting file:', error);
        }
      }));
      
      set({
        files,
        decryptedUrls,
        hasMore: files.length === 15,
        page: 1,
        isInitialized: true
      });

    } catch (error) {
      console.error('Error initializing files:', error);
    }
  },

  cleanup: () => {
    // Cleanup URLs and reset state when logging out or switching workspaces
    Object.values(get().decryptedUrls).forEach(({ url }) => URL.revokeObjectURL(url));
    set({
      files: [],
      decryptedUrls: {},
      hasMore: true,
      page: 1,
      isInitialized: false
    });
  },

  decryptAndAddFile: async (file) => {
    try {
      const { userID } = useAuthStore.getState();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}attachments/signed-url?key=${encodeURIComponent(file.key || file.file_url)}`
      );
      
      if (!response.ok) return;
      
      const { signedUrl } = await response.json();

      const encryptedBlob = await fetch(signedUrl).then(r => r.blob());

      const decryptedBlob = await decryptFile(
        encryptedBlob,
        userID,
        file.space_id,
        file.room_type,
        file.room_id,
        file.key_timestamp
      );

      set(state => ({
        decryptedUrls: {
          ...state.decryptedUrls,
          [file.key || file.file_url]: {
            url: URL.createObjectURL(decryptedBlob),
            fileType: decryptedBlob.type || file.file_type
          }
        },
        files: state.files.some(f => f.file_id === file.file_id) 
          ? state.files 
          : [file, ...state.files]
      }));

      await get().getUserDetails(file.sender_id);
      
    } catch (error) {
      console.error('Error decrypting new file:', error);
    }
  }
}));

export default useFileStore;