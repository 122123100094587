import React, { useState, useEffect } from 'react';
import styles from '../ChatSection.module.css';
import useSidebarStore from '../../../../store/sidebarStore';
import useAuthStore from '../../../../store/authStore';

import useUserDetailsStore from '../../../../store/userDetailsStore';
import { fetchSpaceMembers } from '../../services';

const TopBarView = () => {
  const { currentRoom, channelDetails, folderContents, folders } = useSidebarStore();
  const { spaceID } = useAuthStore();

  const fetchUserDetails = useUserDetailsStore(state => state.fetchUserDetails);
  const [displayName, setDisplayName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  // const [spaceMembers, setSpaceMembers] = useState([]);
  const [memberCount, setMemberCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        if (currentRoom.room_type === 'dm' && currentRoom.receiver_id) {
          // Fetch details for DM
          const details = await fetchUserDetails(currentRoom.receiver_id);
          if (details?.userSpaceDetails) {
            setDisplayName(details.userSpaceDetails.user_display_name || "Unknown User");
          } else {
            setDisplayName("Unknown User");
          }
          setMemberCount(2);
        } else if (currentRoom.room_type === 'group_chat') {
          // Fetch details for Group Chat
          const groupChat = useSidebarStore.getState().groupChats.find(
            chat => chat.group_id === currentRoom.room_id
          );
  
          if (groupChat?.member_ids) {
            const memberDetails = await Promise.all(
              groupChat.member_ids.map(id => fetchUserDetails(id))
            );
            const names = memberDetails
              .filter(detail => detail?.userSpaceDetails)
              .map(detail => detail.userSpaceDetails.user_display_name)
              .filter(name => name);
  
            setDisplayName(names.length > 0 ? names.join(', ') : 'Unknown Group');
            setMemberCount(groupChat.member_ids.length);
          } else {
            setDisplayName('Unknown Group');
            setMemberCount(0);
          }
        } else {
          // Fetch details for a space (workspace or channel)
          const members = await fetchSpaceMembers(spaceID);
          setMemberCount(members.length);
  
          const folderName = getFolderName();
          const channelName = channelDetails[currentRoom.room_id]?.channel_name || currentRoom.room_id;
  
          setDisplayName(`${folderName} | ${channelName}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setDisplayName("Error Loading Details");
        setMemberCount(0);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [spaceID, currentRoom]);
  

  const getFolderName = () => {
    for (const [folderId, channels] of Object.entries(folderContents)) {
      if (channels.some(channel => channel.channel_id === currentRoom.room_id)) {
        const folder = folders.find(f => f.folder_id === folderId);
        return folderId === 'SIDEBAR' ? 'Workspace' : folder?.folder_name || 'Unknown';
      }
    }
    return 'Unknown';
  };

  return (
    <div className={styles.topBar}>
      <div className={styles.channelInfo}>
        {currentRoom.room_type === 'channel' ? (
          <>
            <span className={styles.folderName}>{getFolderName()}</span>
            <span className={styles.separator}>|</span>
            <h2 className={styles.channelName}>
              {channelDetails[currentRoom.room_id]?.channel_name || currentRoom.room_id}
            </h2>
          </>
        ) : (
          <h2 className={styles.channelName}>{displayName}</h2>
        )}
      </div>
      <div className={styles.memberInfo}> 
        <p className={styles.memberCount}>{memberCount}</p>
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
          <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35" />
        </svg>
      </div>
    </div>
  );
};

export default TopBarView;