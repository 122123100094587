import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../../store/authStore';
import styles from '../css/UserDetailsStep.module.css';
import { updateOnboardingStatus, updateUserSpaceDetails, updateUser, updateLastActiveSpace } from '../services/onboardingService';
import { generateUniqueCode } from '../../../utils/uniqueIDGenerator';
import { createDefaultAvatar, getAvatarColor } from '../utils/imageUtils';
import { getStoredAccounts, setStoredAccounts } from '../../../utils/accountStorage';
import { saveOnboardingAnalytics } from '../services/onboardingService';

const UserDetailsStep = () => {
  const [displayName, setDisplayName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [orgSize, setOrgSize] = useState('');
  const [industry, setIndustry] = useState('');
  const [referralSource, setReferralSource] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  
  const navigate = useNavigate();
  const { email, userID, spaceID, setOnboardingStatus } = useAuthStore();

  useEffect(() => {
    setIsFormValid(
      displayName.trim().length > 0 &&
      userRole &&
      orgSize &&
      industry &&
      referralSource
    );
  }, [displayName, userRole, orgSize, industry, referralSource]);

  // Dropdown options
  const roleOptions = [
    'Select your role',
    'Founder/CEO',
    'CTO/Tech Lead',
    'Product Manager',
    'Software Engineer',
    'Designer',
    'Marketing',
    'Sales',
    'Other'
  ];

  const industryOptions = [
    'Select your industry',
    'Technology',
    'Crypto',
    'Finance',
    'Healthcare',
    'Education',
    'E-commerce',
    'Manufacturing',
    'Media & Entertainment',
    'Other'
  ];

  const orgSizeOptions = [
    'Select organization size',
    '1-10 employees',
    '11-50 employees',
    '51-200 employees',
    '201-500 employees',
    '501-1000 employees',
    '1000+ employees'
  ];

  const referralOptions = [
    'Select how you heard about us',
    'Search Engine',
    'Social Media',
    'Friend/Colleague',
    'Blog/Article',
    'Conference/Event',
    'Advertisement',
    'Other'
  ];

  const handleSubmit = async () => {
    if (!isFormValid) return;

    try {
      const userPreviewColor = getAvatarColor(displayName);
      const userImageToSend = createDefaultAvatar(displayName, userPreviewColor);

      await updateUserSpaceDetails(userID, spaceID, displayName, '', userImageToSend, email);

      // Save analytics data
      await saveOnboardingAnalytics({email, spaceID, userRole, industry, orgSize, referralSource, joinDate: new Date().toISOString()});

      await updateOnboardingStatus(userID, spaceID, 'create/invites');
      setOnboardingStatus('create/invites');
      navigate('/create/invites', { replace: true });

    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Tell us about yourself</h1>
      
      <div className={styles.formWrapper}>
        <div className={styles.formSection}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Full Name</label>
            <input
              required
              type="text"
              className={`${styles.input} ${styles.fullNameInput}`}
              value={displayName}
              placeholder="Enter your full name"
              onChange={(e) => setDisplayName(e.target.value)}
              maxLength={50}
            />
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Role</label>
            <select
              required
              className={styles.input}
              value={userRole}
              onChange={(e) => setUserRole(e.target.value)}
            >
              {roleOptions.map(option => (
                <option key={option} value={option === 'Select your role' ? '' : option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.divider}></div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Industry</label>
            <select
              required
              className={styles.input}
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            >
              {industryOptions.map(option => (
                <option key={option} value={option === 'Select your industry' ? '' : option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>Organization Size</label>
            <select
              required
              className={styles.input}
              value={orgSize}
              onChange={(e) => setOrgSize(e.target.value)}
            >
              {orgSizeOptions.map(option => (
                <option key={option} value={option === 'Select organization size' ? '' : option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.inputGroup}>
            <label className={styles.label}>How did you hear about us?</label>
            <select
              required
              className={styles.input}
              value={referralSource}
              onChange={(e) => setReferralSource(e.target.value)}
            >
              {referralOptions.map(option => (
                <option key={option} value={option === 'Select how you heard about us' ? '' : option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        
        <button
          className={styles.button}
          onClick={handleSubmit}
          disabled={!isFormValid}>
          Continue
        </button>
      </div>
    </div>
  );
};

export { UserDetailsStep };