import React from 'react';
import { format } from 'date-fns';
import styles from './ImagePreviewModal.module.css';
import useFileDetails from '../hooks/useFileDetails';

const ImagePreviewModal = ({ image, onClose }) => {
  const fileDetails = useFileDetails(image);

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <div className={styles.header}>
          <div className={styles.fileInfo}>
            <div className={styles.senderInfo}>
              <img 
                src={fileDetails.senderDetails?.user_image_url || 'default-avatar.png'} 
                alt={`${fileDetails.senderDetails?.user_display_name}'s avatar`} 
                className={styles.senderAvatar} 
              />
              <div className={styles.senderDetails}>
                <span className={styles.senderName}>
                  {fileDetails.senderDetails?.user_display_name || 'Loading...'}
                </span>
                <span>Shared on {format(new Date(image.created_at), 'MMM dd, yyyy')}</span>
              </div>
            </div>
          </div>
          <button className={styles.closeButton} onClick={onClose}>×</button>
        </div>
        <div className={styles.imageContainer}>
          <img src={image.url} alt={image.file_name} />
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;