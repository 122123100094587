import { generateAndRegisterDMKey, generateAndRegisterGroupKey, generateAndRegisterChannelKey } from './senderKeyGeneration';
import { fetchMemberPublicKeys, fetchSpaceAdmins } from './keyBackendService';
import { encryptKeyWithPublicKey } from './senderKeyGeneration';
import { uploadEncryptedSenderKey } from './keyBackendService';
import { stringToRsaKey } from '../utils/keyUtils';

export async function initializeRoomKeys(userId, workspaceId, roomType, roomId, memberIds) {
  // Get all admins and combine with member IDs into a single Set
  const spaceAdmins = await fetchSpaceAdmins(workspaceId);
  const allRecipients = new Set([...memberIds, ...spaceAdmins]);
  
  // Get public keys for all recipients at once
  const recipientPublicKeys = await fetchMemberPublicKeys(workspaceId, Array.from(allRecipients));

  // Generate and distribute keys based on room type
  try {
    let sharedKey;
    const createdAt = new Date().toISOString();
    
    switch (roomType) {
      case 'dm':
        sharedKey = await generateAndRegisterDMKey(userId, workspaceId, roomId, createdAt);
        break;
      case 'group_chat':
        sharedKey = await generateAndRegisterGroupKey(userId, workspaceId, roomId, createdAt);
        break;
      case 'channel':
        sharedKey = await generateAndRegisterChannelKey(userId, workspaceId, roomId, createdAt);
        break;
      default:
        throw new Error('Invalid room type');
    }

    // Encrypt and distribute key to all recipients
    for (const recipient of recipientPublicKeys) {
      if (recipient.user_id !== userId) { // Skip creator as they already have the key
        const publicKey = await stringToRsaKey(recipient.public_key, false);
        const encryptedKey = await encryptKeyWithPublicKey(sharedKey, publicKey);
        await uploadEncryptedSenderKey(
          recipient.user_id, 
          workspaceId, 
          roomType, 
          roomId, 
          encryptedKey,
          createdAt
        );
      }
    }

    return sharedKey;
  } catch (error) {
    console.error(`Failed to initialize keys for ${roomType} ${roomId}:`, error);
    throw error;
  }
} 