import styles from './LoadingAttachment.module.css';

const LoadingAttachment = ({ count }) => {
  return (
    <div className={styles.loadingAttachment}>
      <div className={styles.spinner} />
      <div className={styles.loadingContainer}>
        <div className={styles.loadingBar}>
          <div className={styles.loadingProgress} />
        </div>
        <div className={styles.loadingText}>
          Encrypting {count} {count === 1 ? 'file' : 'files'}...
        </div>
      </div>
    </div>
  );
};

export default LoadingAttachment; 