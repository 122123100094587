import React, { useState, useRef, useEffect } from 'react';
import styles from './WorkspaceSettingsView.module.css';
import useAuthStore from '../../../../store/authStore';
import { updateWorkspaceDetails } from '../../../onboarding/services/WorkspaceService';
import imageCompression from 'browser-image-compression';

const WorkspaceSettingsView = () => {
  const { spaceID } = useAuthStore();
  const [displayName, setDisplayName] = useState('');
  const [bio, setBio] = useState('');
  const [workspaceImage, setWorkspaceImage] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchWorkspaceDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}space/${spaceID}/get-space-details`);
        const data = await response.json();
        
        if (data) {
          setDisplayName(data.space_name || '');
          setBio(data.space_description || '');
          setWorkspaceImage(data.space_image_url || '');
        }
      } catch (error) {
        console.error('Error fetching workspace details:', error);
      }
    };

    fetchWorkspaceDetails();
  }, [spaceID]);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsImageLoading(true);
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        };
        const compressedFile = await imageCompression(file, options);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const imageData = reader.result;
          setWorkspaceImage(imageData);
          
          // Update workspace with new image
          try {
            await updateWorkspaceDetails(spaceID, { image: imageData });
          } catch (error) {
            console.error('Error updating workspace image:', error);
          }
          
          setIsImageLoading(false);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error('Error handling image:', error);
        setIsImageLoading(false);
      }
    }
  };

  const handleInputChange = async (field, value) => {
    const updates = {};
    if (field === 'displayName') {
      setDisplayName(value);
      updates.name = value;
    } else if (field === 'bio') {
      setBio(value);
      updates.description = value;
    }

    try {
      await updateWorkspaceDetails(spaceID, updates);
    } catch (error) {
      console.error('Error updating workspace:', error);
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>Workspace Details</p>

      <div className={styles.section}>
        <div className={styles.formSection}>
          <div className={styles.inputGroup}>
            <label>Workspace Name</label>
            <input 
              type="text" 
              value={displayName}
              className={styles.input}
              onChange={(e) => handleInputChange('displayName', e.target.value)}
              maxLength={50}
            />
          </div>

          <div className={styles.inputGroup}>
            <label>Workspace Bio</label>
            <textarea
              className={styles.textarea}
              value={bio}
              onChange={(e) => handleInputChange('bio', e.target.value)}
              maxLength={250}
            />
          </div>
        </div>

        <div className={styles.imageSection}>
          <label>Profile Image</label>
          <div className={styles.addPhoto} onClick={handleImageClick}>
            <img 
              src={workspaceImage || '/default-avatar.png'} 
              alt="Profile" 
              className={styles.profileImage}
            />
            <div className={styles.uploadOverlay}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8rem" height="1.8rem" viewBox="0 0 24 24">
                <rect width="24" height="24" fill="none" />
                <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
                  <path d="M2 19V9a2 2 0 0 1 2-2h.5a2 2 0 0 0 1.6-.8l2.22-2.96A.6.6 0 0 1 8.8 3h6.4a.6.6 0 0 1 .48.24L17.9 6.2a2 2 0 0 0 1.6.8h.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2" />
                  <path d="M12 17a4 4 0 1 0 0-8a4 4 0 0 0 0 8" />
                </g>
              </svg>
              <span>Upload Photo</span>
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            onChange={handleImageUpload}
            style={{ display: 'none' }}
          />
        </div>
      </div>

      <div className={styles.section2}>
        <p className={styles.title}>Preferences</p>
         

        <div className={styles.settings}>
          <div>
            <h3>Default Timezone</h3>
            <p>Select the timezone of your workspace HQ.</p>
          </div>
          <select className={styles.select}>
            <option>GMT-6</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceSettingsView;