import React, { useState } from 'react';
import useTextBoxViewModel from './TextBoxViewModel';
import useTypingIndicatorViewModel from '../TypingIndicator/TypingIndicatorViewModel';
import styles from './TextBox.module.css';
import useSidebarStore from '../../store/sidebarStore';
import EmojiPicker from '../emoji/EmojiPicker';
import HyperlinkView from './hyperlinks/HyperlinkView';
import TypingIndicatorView from '../TypingIndicator/TypingIndicatorView';
import PDFPreview from '../Message/messageAttachments/PDFPreview';

const TextBoxView = ({ roomId, roomType, parentRoomType, parentRoomId, parentCreatedAt }) => {
  const {
    message,
    setMessage,
    sendMessage,
    fileInputRef,
    handleFileSelect,
    removeAttachment,
    attachments,
    handleKeyPress,
    resetTextareaHeight,
    showEmojiPicker,
    setShowEmojiPicker,
    handleEmojiSelect,
  } = useTextBoxViewModel(
    roomId,
    roomType,
    parentRoomType,
    parentRoomId,
    parentCreatedAt
  );

  const { handleTypingChange } = useTypingIndicatorViewModel();
  const { channelDetails } = useSidebarStore();

  const [showLinkModal, setShowLinkModal] = useState(false);

  const getPlaceholder = () => {
    if (roomType === 'channel') {
      return `Message #${channelDetails[roomId]?.channel_name || 'channel'}`;
    } else if (roomType === 'thread') {
      return 'Reply in thread';
    } else if (roomType === 'dm') {
      return 'Send a message';
    }
    return 'Send a message';
  };

  const handleInput = (e) => {
    const textarea = e.target;
    if (e.nativeEvent.inputType === "insertLineBreak") {
      textarea.style.height = 'auto';
      textarea.style.height = Math.min(textarea.scrollHeight, 150) + 'px';
    }
    setMessage(e.target.value);
    handleTypingChange(e);
  };

  const handleHyperlink = (linkMarkdown) => {
    setMessage(prevMessage => prevMessage + linkMarkdown);
  };

  return (
    <div className={styles.textbox_container}>
      <div className={styles.messageInputContainer}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          multiple
          accept="image/*,video/*,application/pdf,.doc,.docx,.xls,.xlsx,.txt,.csv,.zip,.rar"
        />
        {attachments.length > 0 && (
          <div className={styles.attachmentPreview}>
            {attachments.map((att, index) => (
              <div key={index} className={styles.previewItem}>
                {att.preview ? (
                  // Image preview
                  <img src={att.preview} alt="attachment" />
                ) : att.type === 'application/pdf' ? (
                  // PDF preview
                  <div className={styles.filePreview}>
                    <PDFPreview url={URL.createObjectURL(att.file)} />
                    <span className={styles.fileName}>{att.name}</span>
                  </div>
                ) : (
                  // Other file types
                  <div className={styles.filePreview}>
                    <div className={styles.fileIcon}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6zm4 18H6V4h7v5h5v11z"/>
                      </svg>
                    </div>
                    <span className={styles.fileName}>{att.name}</span>
                  </div>
                )}
                <button 
                  className={styles.removeButton}
                  onClick={() => removeAttachment(index)}
                >×</button>
              </div>
            ))}
          </div>
        )}
        <textarea 
          id="messageInput"
          placeholder={getPlaceholder()}
          value={message}
          onChange={handleInput}
          onInput={handleInput}
          onKeyDown={handleKeyPress}
          className={styles.messageInputTextInput}
          rows={1}
        />
        
        <div className={styles.messageInputToolbar}>
          <div className={styles.toolbarLeft}>

            {/* Mentions button */}
            <button className={styles.messageInputToolbarButton}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path fill="currentColor" d="M4 12a8 8 0 0 1 8-8c5.367 0 8.445 4.445 8.006 8.39c-.12 1.086-.438 1.723-.72 2.095s-.571.54-.733.62c-.753.377-1.133.212-1.283.093c-.19-.15-.372-.503-.284-1.034l.006-.032l.503-5.032a1 1 0 0 0-1.867-.59A5.03 5.03 0 0 0 12.03 7C9.279 7 7 9.229 7 12c0 2.774 2.288 5 5.038 5c1.212 0 2.35-.436 3.237-1.176c.175.36.425.682.753.942c.917.726 2.172.752 3.42.128c.337-.168.91-.51 1.434-1.203s.956-1.682 1.112-3.08C22.556 7.554 18.633 2 12 2C6.477 2 2 6.477 2 12s4.477 10 10 10a9.96 9.96 0 0 0 4.445-1.04a1 1 0 0 0-.89-1.791A8 8 0 0 1 4 12m5 0c0-1.647 1.364-3 3.03-3c1.92 0 3.364 1.767 2.974 3.62c-.291 1.378-1.539 2.38-2.966 2.38C10.368 15 9 13.645 9 12" />
                </g>
              </svg>
            </button>

            {/* Attachments button */}
            <button 
              className={`${styles.messageInputToolbarButton} ${styles.attachmentsButton}`} 
              onClick={() => fileInputRef.current?.click()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48" />
              </svg>
            </button>

            {/* Emoji button */}
            <button 
              className={styles.messageInputToolbarButton}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
              data-emoji-button="true"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4-8c.55 0 1-.45 1-1s-.45-1-1-1s-1 .45-1 1s.45 1 1 1m-8 0c.55 0 1-.45 1-1s-.45-1-1-1s-1 .45-1 1s.45 1 1 1m4 5.5c2.07 0 3.75-1.45 4.2-3.5H7.8c.45 2.05 2.13 3.5 4.2 3.5" />
              </svg>
            </button>

            <div className={styles.verticalDivider}></div>

            {/* Links button */}
            <button 
              className={styles.messageInputToolbarButton}
              onClick={() => setShowLinkModal(true)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 20 16">
                <rect width="20" height="16" fill="none" />
                <path fill="currentColor" d="M12.736.064c.52.2.787.805.598 1.353L8.546 15.305c-.19.548-.763.83-1.282.631c-.52-.2-.787-.805-.598-1.353L11.454.695c.19-.548.763-.83 1.282-.631M2.414 8.256L5.95 11.99c.39.412.39 1.08 0 1.492a.963.963 0 0 1-1.414 0L.293 9.003a1.1 1.1 0 0 1 0-1.493l4.243-4.48a.963.963 0 0 1 1.414 0a1.1 1.1 0 0 1 0 1.494zm15.172 0L14.05 4.524a1.1 1.1 0 0 1 0-1.493a.963.963 0 0 1 1.414 0l4.243 4.479c.39.412.39 1.08 0 1.493l-4.243 4.478a.963.963 0 0 1-1.414 0a1.1 1.1 0 0 1 0-1.492z" />
              </svg>
            </button>

            {/* Add the emoji picker component */}
            {showEmojiPicker && (
              <EmojiPicker
                onEmojiClick={handleEmojiSelect}
                onClose={() => setShowEmojiPicker(false)}
              />
            )}

            {/* Code button */}
            <button className={styles.messageInputToolbarButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 32 32">
	<rect width="32" height="32" fill="none" />
	<path fill="currentColor" d="M16 22h14v2H16zm0-14h14v2H16zm-8 4V4H6v1H4v2h2v5H4v2h6v-2zm2 16H4v-4a2 2 0 0 1 2-2h2v-2H4v-2h4a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H6v2h4z" />
</svg>
            </button>

            {/* Code button */}
            <button className={styles.messageInputToolbarButton}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.2rem" height="1.2rem" viewBox="0 0 20 20">
                <rect width="20" height="20" fill="none" />
                <path fill="currentColor" fillRule="evenodd" d="M6 4.75A.75.75 0 0 0 6.75 4h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 4.75M6 10a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75A.75.75 0 0 1 6 10m0 5.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H6.75a.75.75 0 0 1-.75-.75M1.99 4.75a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1zm0 10.5a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1zm0-5.25a1 1 0 0 1 1-1H3a1 1 0 0 1 1 1v.01a1 1 0 0 1-1 1h-.01a1 1 0 0 1-1-1z" clipRule="evenodd" />
              </svg>
            </button>

            <div className={styles.verticalDivider}></div>

            {/* Format button */}
            <button className={`${styles.messageInputToolbarButton} ${styles.formatButton}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16">
                <path fill="currentColor" fillRule="evenodd" d="M4.25 2.25A.75.75 0 0 0 3.5 3v10c0 .414.336.75.75.75H9.5a3.25 3.25 0 0 0 1.477-6.146A3.25 3.25 0 0 0 8.5 2.25zm3.5 5a1.75 1.75 0 1 0 0-3.5h-2v3.5zm-2 1.5v3.5h3a1.75 1.75 0 1 0 0-3.5z" clipRule="evenodd" />
              </svg>
            </button> 

            <button className={`${styles.messageInputToolbarButton} ${styles.formatButton}`}>
              <i className="fa-solid fa-italic"></i>
            </button> 

            <button className={`${styles.messageInputToolbarButton} ${styles.formatButton}`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" viewBox="0 0 56 56">
	<rect width="56" height="56" fill="none" />
	<path fill="currentColor" d="M9.613 28.645h13.594c.61.187 1.219.375 1.898.515l4.336 1.031c4.852 1.172 6.891 2.766 6.891 5.579c0 3.374-3.445 5.789-8.344 5.789c-4.336 0-7.57-1.946-8.789-5.274c-.422-1.008-.937-1.453-1.922-1.453c-1.242 0-2.015.773-2.015 1.992c0 .422.047.867.164 1.313c1.242 4.43 6.14 7.195 12.586 7.195c7.758 0 12.75-3.96 12.75-10.078c0-2.93-1.078-5.04-3.446-6.61h9.07a.95.95 0 0 0 .962-.96a.95.95 0 0 0-.961-.961H32.934a22 22 0 0 0-2.086-.563l-3.82-.914c-4.758-1.148-6.774-2.601-6.774-5.203c0-3.281 3.234-5.602 7.71-5.602c4.032 0 6.915 1.829 8.063 5.063c.399.89.938 1.312 1.875 1.312c1.22 0 1.97-.703 1.97-1.875c0-.351-.048-.726-.142-1.078c-1.054-4.265-5.742-7.195-11.765-7.195c-7.055 0-12.14 4.008-12.14 9.68c0 2.765 1.03 4.828 3.234 6.375H9.613a.95.95 0 0 0-.96.96c0 .54.421.962.96.962" />
</svg>
            </button> 



          </div>
          
          <div className={styles.toolbarRight}>
            {/* Send button */}
            <button 
              className={styles.messageInputToolbarButton}
              onClick={() => {
                sendMessage();
                resetTextareaHeight();
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                  <path fill="currentColor" d="M17.991 6.01L5.399 10.563l4.195 2.428l3.699-3.7a1 1 0 0 1 1.414 1.415l-3.7 3.7l2.43 4.194L17.99 6.01Zm.323-2.244c1.195-.433 2.353.725 1.92 1.92l-5.282 14.605c-.434 1.198-2.07 1.344-2.709.241l-3.217-5.558l-5.558-3.217c-1.103-.639-.957-2.275.241-2.709z" />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <TypingIndicatorView />

      {showLinkModal && (
        <HyperlinkView
          onClose={() => setShowLinkModal(false)}
          onInsert={handleHyperlink}
        />
      )}
    </div>
  );
};

export default TextBoxView;