import io from 'socket.io-client';

let socket;

export const getSocket = () => {
  if (!socket) {
    let baseURL;
    if (process.env.NODE_ENV === 'production') {
      try {
        baseURL = window.location.origin;
      } catch (error) {
        console.warn('Failed to get window.location.origin, using fallback URL');
        baseURL = 'https://mention.so';
      }
    } else {
      baseURL = 'http://localhost:8080';
    }

    socket = io(baseURL, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 10000,
      randomizationFactor: 0.5,
      timeout: 20000,
      autoConnect: true,
      reconnectionAttempts: Infinity,
      pingTimeout: 60000,
      pingInterval: 25000,
      withCredentials: true,
      forceNew: true,
      secure: process.env.NODE_ENV === 'production',
      rejectUnauthorized: false,
      transportOptions: {
        polling: {
          extraHeaders: {
            'X-Forwarded-Proto': 'https'
          }
        }
      }
    });

    // Add connection state logging
    socket.on('connect', () => {
      console.log('Socket connected successfully');
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      if (socket.io.opts.transports[0] === 'websocket') {
        socket.io.opts.transports = ['polling', 'websocket'];
      }
    });

    socket.on('reconnect_attempt', (attemptNumber) => {
      console.log(`Reconnection attempt #${attemptNumber}`);
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log(`Successfully reconnected after ${attemptNumber} attempts`);
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect') {
        // Server initiated disconnect, attempt reconnection
        socket.connect();
      }
    });
  }

  return socket;
};