import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { sendInvites, saveJoinCode, updateOnboardingStatus, getUserByEmail } from '../services/onboardingService';
import { getSpaceChannels } from '../../sidebar/services/channelService';
import { fetchAndDecryptChannelKeys } from '../../../services/keyFetching';
import { generateIdentityKey } from '../../../services/identityKeyGeneration';
import { rsaKeyToString } from '../../../utils/keyUtils';
import { encryptKeyWithPublicKey } from '../../../services/senderKeyGeneration';
import { uploadPublicIdentityKey, uploadMultipleEncryptedSenderKeys } from '../../../services/keyBackendService';
import { getSpaceDetails } from '../../sidebar/services/spaceService';

import { v4 as uuidv4 } from 'uuid';

import styles from '../css/InviteUsersStep.module.css';
import useAuthStore from '../../../store/authStore';
import InviteEmailBox from '../../../components/inviteBox/InviteEmailBox';

const { nanoid } = require('nanoid');

const InviteUsersStep = () => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { userID, spaceID, setOnboardingStatus } = useAuthStore();
  const navigate = useNavigate();

  const generateUniqueInviteLink = async () => {
    const baseDomain = process.env.NODE_ENV === 'production' ? 'https://mention.so' : 'http://localhost:3000';
    const uniqueCode = nanoid(15); 
    await saveJoinCode(spaceID, userID, uniqueCode);
    return `${baseDomain}/invite/${uniqueCode}`;
  };

  const handleSubmit = async (emailsToSend = emails) => {
    setIsLoading(true);

    try {
      const link = await generateUniqueInviteLink();
      const channels = await getSpaceChannels(spaceID);
      const channelIDs = channels.map(channel => channel.channel_id);
      const decryptedChannelKeys = await fetchAndDecryptChannelKeys(userID, spaceID, channelIDs);

      // Process each email
      const inviteResults = await Promise.all(emailsToSend.map(async (email) => {
        try {
          // First check if user already exists
          let newUserID = await getUserByEmail(email);

          // If user doesn't exist, generate new ID
          if (!newUserID) {
            newUserID = uuidv4().toString();
          }

          // Generate new identity key for the user
          const identityKey = await generateIdentityKey();
          const publicKeyString = await rsaKeyToString(identityKey.publicKey, false);

          // Encrypt all channel sender keys with the new user's public key
          const encryptedKeys = await Promise.all(
            decryptedChannelKeys.map(async ({ room_id, key }) => {
              const encryptedKey = await encryptKeyWithPublicKey(key, identityKey.publicKey);
              return {
                user_id: newUserID,
                workspace_id: spaceID,
                room_id,
                encrypted_key: encryptedKey,
                type: 'channel',
                created_at: new Date().toISOString()
              };
            })
          );

          await uploadMultipleEncryptedSenderKeys(encryptedKeys);
          await uploadPublicIdentityKey(newUserID, spaceID, publicKeyString);
          
          // Get space details
          const spaceDetails = await getSpaceDetails(spaceID);
          if (!spaceDetails) {
            throw new Error('Could not fetch space details');
          }

          // Send invite 
          await sendInvites(email, link, identityKey, newUserID, spaceID, spaceDetails.space_name);

          return { email, success: true };
        } catch (error) {
          console.error(`Failed to process invite for ${email}:`, error);
          return { email, success: false, error: error.message };
        }
      }));

      const failures = inviteResults.filter(result => !result.success);
      if (failures.length > 0) {
        console.error('Some invites failed:', failures);
      }

      setOnboardingStatus('completed');
      await updateOnboardingStatus(userID, spaceID, 'completed');
      navigate(`/client/${spaceID}`, { replace: true });
    } catch (error) {
      console.error('Error sending invites:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Send Invites</h2>
      <p className={styles.label}>Enter emails to invite people to your workspace</p>
      
      <div className={styles.inviteEmailBoxWrapper}>
        <InviteEmailBox
          emails={emails}
          setEmails={setEmails}
          onSendInvites={handleSubmit}
          isLoading={isLoading}
        />
      </div>
      
      <button onClick={() => handleSubmit([])} className={styles.skipButton}>Skip</button>
    </div>
  );
};

export { InviteUsersStep };