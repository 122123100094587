import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import styles from './KeyManagementView.module.css';
import useAuthStore from '../../../../store/authStore';
import { checkKeyRotationNeeded, manualKeyRotation } from '../../../../services/keyManagement';
import { getKeyRotationHistory } from '../../../../services/keyBackendService';
import { toast } from 'react-toastify';

const KeyManagementView = ({ workspaceId }) => {
  const [isRotating, setIsRotating] = useState(false);
  const [needsRotation, setNeedsRotation] = useState(false);
  const [rotationHistory, setRotationHistory] = useState([]);
  const { userID } = useAuthStore();

  useEffect(() => {
    async function loadData() {
      if (userID && workspaceId) {
        // This wont affect anything because keys will be rotated automatically. Manual trigger will have nothing to do with this.
        const needs = await checkKeyRotationNeeded(userID, workspaceId);
        setNeedsRotation(needs);

        // TODO: SET THE INTIAL ONE ON SPACE CREATION PLS
        const history = await getKeyRotationHistory(workspaceId);
        setRotationHistory(history);
      }
    }
    loadData();
  }, [userID, workspaceId]);

  const handleRotateKeys = async () => {
    setIsRotating(true);
    try {
      await manualKeyRotation(userID, workspaceId);
      toast.success('Key rotation initiated');
    } catch (error) {
      console.error('Failed to rotate keys:', error);
      toast.error('Failed to rotate keys');
    } finally {
      setIsRotating(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>Key Management</h2>
        <p className={styles.subtitle}>
          {/* THey will likely never see this because keys will be rotated automatically */}
          {needsRotation 
            ? 'Some keys are older than 30 days and should be rotated'
            : 'All keys are up to date'}
        </p>
      </div>

      <div className={styles.content}>
        <button
          onClick={handleRotateKeys}
          disabled={isRotating}
          className={`${styles.rotateButton} ${needsRotation ? styles.warning : ''}`}
        >
          {isRotating ? (
            <>
              <FaSpinner className={styles.spinner} />
              Rotating Keys...
            </>
          ) : (
            'Rotate All Keys'
          )}
        </button>

        <div className={styles.historySection}>
          <h3 className={styles.title}>Rotation History</h3>
          <div className={styles.historyList}>
            {rotationHistory.length > 0 ? (
              rotationHistory.map((entry, index) => (
                <div key={index} className={styles.historyItem}>
                  <span className={styles.timestamp}>
                    {new Date(entry.timestamp).toLocaleString()}
                  </span>
                  <span className={styles.type}>
                    {entry.type === 'auto' ? 'Automatic' : 'Manual'}
                  </span>
                  <span className={styles.status}>
                    {entry.results.filter(r => r.success).length} successful
                  </span>
                </div>
              ))
              
            ) : (
              <div className={styles.noHistory}>
                No rotation history available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyManagementView;