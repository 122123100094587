import useAuthStore from '../store/authStore';
import { logout } from '../features/authentication/services/AuthService';

/**
 * Custom hook to handle the logout process.
 *
 * - Calls the server-side logout endpoint to clear the user's session.
 * - Updates the client-side authentication state via authStore.
 * - Navigates the user to the login page upon successful logout.
 *
 * @returns {Function} The function to trigger the logout process.
 */
const useLogout = () => {
  const terminateUserSession = useAuthStore((state) => state.terminateUserSession);
  const currentAccount = useAuthStore((state) => state.currentAccount);
  const activeAccounts = useAuthStore((state) => state.activeAccounts);
 
  const handleLogout = async () => {
    try {
      // If there's only one account, perform simple logout
      if (activeAccounts.length <= 1) {
        const success = await logout(currentAccount.email);
        
        if (success) {
          await terminateUserSession();
        } else {
          console.error('Logout API call failed');
        }
        return;
      }

      // Get next account to switch to
      const nextAccount = activeAccounts.find(acc => acc.email !== currentAccount?.email);

      if (!nextAccount) {
        console.error('No valid account found to switch to');
        throw new Error('No valid account to switch to');
      }

      // Store the account we're logging out before switching
      const accountToLogout = currentAccount;

      // First switch to next account
      await useAuthStore.getState().switchAccount(nextAccount.email);
      
      // Then logout the previous account
      const success = await logout(accountToLogout.email);

      if (!success) {
        console.error('Failed to logout current account');
        throw new Error('Failed to logout current account');
      }

      // Update store state with the account we want to remove
      await terminateUserSession(accountToLogout);

    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return handleLogout;
};

export default useLogout;
