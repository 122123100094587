import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa';
import { validateKeyFile, storeIdentityKeyFile } from '../../onboarding/utils/keyFileHandler';
import { decryptAndStoreAllKeys } from '../../../services/keyFetching';
import useAuthStore from '../../../store/authStore';
import styles from './KeyRecoveryPortal.module.css';

const KeyRecoveryPortal = () => {
  const [keyFile, setKeyFile] = useState(null);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();
  const { userID, spaceID } = useAuthStore();

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const validatedKeys = await validateKeyFile(file);
      setKeyFile(validatedKeys);
      setError(null);
    } catch (error) {
      setError('Invalid key file. Please ensure you uploaded the correct backup file.');
      setKeyFile(null);
    }
  };

  const handleRestore = async () => {
    if (!keyFile) return;
    setIsProcessing(true);

    try {
      await storeIdentityKeyFile(userID, spaceID, keyFile);
      await decryptAndStoreAllKeys(userID, spaceID);
      navigate('/', { replace: true });
    } catch (error) {
      setError('Failed to restore access. Please try again or contact support.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h2 className={styles.title}>Lost Keys or New Device?</h2>
        
        <p className={styles.description}>
          Upload your identity key backup file to restore access to your messages.
          This file was automatically downloaded when you first joined the space.
        </p>

        <div className={styles.uploadSection}>
          <input
            type="file"
            onChange={handleFileUpload}
            accept=".pem"
            className={styles.fileInput}
          />
        </div>

        {error && (
          <div className={styles.error}>
            {error}
          </div>
        )}

        <button
          onClick={handleRestore}
          disabled={!keyFile || isProcessing}
          className={styles.restoreButton}
        >
          {isProcessing ? (
            <>
              <FaSpinner className={styles.spinner} />
              Restoring Access...
            </>
          ) : (
            'Restore Access'
          )}
        </button>

        <div className={styles.supportSection}>
          <p>Can't find your backup file?</p>
          <a 
            href="mailto:support@mention.com" 
            className={styles.supportLink}
          >
            Contact Support
          </a>
        </div>
      </div>
    </div>
  );
};

export default KeyRecoveryPortal; 