import { rsaKeyToString } from './keyUtils';

async function generateBackupFileContent(workspaceId, identityKey) {
  const publicKeyString = await rsaKeyToString(identityKey.publicKey, false);
  const privateKeyString = await rsaKeyToString(identityKey.privateKey, true);
  
  // Format as PEM with multiple sections
  const content = `-----BEGIN WORKSPACE INFO-----
  Workspace: ${workspaceId}
  Created: ${new Date().toISOString()}
  -----END WORKSPACE INFO-----
  -----BEGIN PRIVATE KEY-----
  ${privateKeyString}
  -----END PRIVATE KEY-----
  -----BEGIN PUBLIC KEY-----
  ${publicKeyString}
  -----END PUBLIC KEY-----`;

  return content;
}

function autoDownloadBackupFile(filename, content) {
  const blob = new Blob([content], { type: 'application/octet-stream', endings: 'native' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

export { generateBackupFileContent, autoDownloadBackupFile };