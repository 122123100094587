import { create } from 'zustand';
import { getSocket } from '../utils/socket';

import { getSpaceDetails, getFolders, getFolderContents, getChannelDetails, getChannelMessages, getLastActiveRoom, updateLastActiveRoom, updateLatestRoomActivity } from '../features/sidebar/services';
import { fetchDMList, getDMs, fetchUserGroupChats, fetchGroupChatMessages, fetchThreadMessages } from '../features/chat/services'
import useAuthStore from './authStore';
import useUserDetailsStore from './userDetailsStore';
import { decryptMessage } from '../services/messageEncryption';
import useFileStore from './fileStore';

const useSidebarStore = create((set, get) => ({
  socket: null, 
  folders: [],
  collapsedFolders: new Set(),
  folderContents: {},
  channelDetails: {},
  dmConversations: [],
  groupChats: [],
  messages: {},
  completedRooms: new Set(),
  currentRoom: { room_id: '', room_type: '', receiver_id: '' },
  isDataLoaded: { folderContents: false }, 
  spaceDetails: {},
  userDetails: {},
  isDMCreationFlowActive: false,
  isFilesViewActive: false,
  unreadRooms: new Set(),
  lastReadTimestamps: {},

  toggleDMCreationFlow: () => set(state => ({ isDMCreationFlowActive: !state.isDMCreationFlowActive })),
  toggleFilesView: () => set(state => ({ isFilesViewActive: !state.isFilesViewActive })),
    
  initializeSocket: () => {
    if (get().socket) return;

    const { userID, spaceID } = useAuthStore.getState();
    const socket = getSocket();
    
    const setupSocketListeners = (socket) => {
      socket.on('connect', () => {
        console.log('Socket connected');
        
        // Rejoin all rooms after reconnection
        const accessibleRooms = [
          ...Object.values(get().channelDetails || {}).map(channel => ({
            id: channel.channel_id,
            type: 'channel'
          })),
          ...get().dmConversations.map(dm => ({
            id: dm.dm_id,
            type: 'dm'
          })),
          ...get().groupChats.map(group => ({
            id: group.group_id,
            type: 'group_chat'
          }))
        ];

        // Join user's personal channel and space
        socket.emit('join_user_channel', userID);
        socket.emit('join_space', spaceID);

        // Rejoin all active rooms
        accessibleRooms.forEach(room => {
          socket.emit('join_room', room.id);
        });

        // Rejoin current room if any
        const currentRoom = get().currentRoom;
        if (currentRoom.room_id) {
          socket.emit('join_room', currentRoom.room_id);
        }
      });

      socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
      });

      socket.on('disconnect', () => {
        console.log('Socket disconnected');
        socket.emit('user_status', { userID, status: 'away' });
      });

      socket.on('user_status_change', (data) => {
        useUserDetailsStore.getState().updateUserStatus(data.userID, data.status);
      });
    
      socket.on('receive_message', async (data) => {
        const { userID, spaceID } = useAuthStore.getState();
        const currentRoom = get().currentRoom;

        // Decrypt the received message
        const decryptedMessage = {
          ...data,
          message: await decryptMessage(userID, spaceID, data.message, data.type, data.room, data.key_timestamp)
        };

        
        // Process any attachments in the message
        if (data.attachments?.length) {
          const fileStore = useFileStore.getState();
          for (const attachment of data.attachments) {
            const fileData = {
              ...attachment,
              sender_id: data.sender_id,
              space_id: spaceID,
              room_id: data.room,
              room_type: data.type,
              key_timestamp: data.key_timestamp,
              created_at: data.created_at,
              file_id: attachment.key, // Using the key as file_id
              file_url: attachment.key,
              file_name: attachment.name,
              file_type: attachment.type
            };
            await fileStore.decryptAndAddFile(fileData);
          }
        }
        
        get().addMessage(data.room, decryptedMessage);

        if (currentRoom.room_id !== data.room) {
          get().checkForUnread(data.room, data.created_at);
        }
      });
      
      socket.on('user_notification', (data) => {
        const currentRoom = get().currentRoom; 
        
        // Only process notification if we're not in this room
        if (currentRoom.room_id !== data.room) {
          get().checkForUnread(data.room, data.timestamp);
        }
      });

      socket.on('parent_thread_updated', (data) => {
        const { parent_room_id, parent_message_id, reply_count, last_reply_at } = data;
        const parentMessage = get().messages[parent_room_id]?.find(
          msg => msg.message_id === parent_message_id
        );
        
        if (parentMessage) {
          const updatedMessage = {
            ...parentMessage,
            reply_count,
            last_reply_at
          };
          get().updateMessage(parent_room_id, updatedMessage);
        }
      });

      socket.on('room_created', ({ spaceId, folderId, channelData }) => {
        // Only update if it's for the current space
        if (spaceId === spaceID) {
          get().addChannelToFolder(folderId, channelData);
        }
      });

      socket.on('room_deleted', (data) => {
        const store = get();
        
        // Remove the channel locally
        store.removeChannelFromFolder(data.folderId, data.channelId);

        // If user is in the deleted channel, switch to the random channel
        if (store.currentRoom.room_id === data.channelId) {
          store.joinRoom(data.randomChannelId, 'channel', null, false);
        }
      });

      socket.on('dm_created', async ({ spaceId, dmId, userId, receiverId }) => {
        const { userID, spaceID } = useAuthStore.getState();
        
        // Only update if it's for the current space and the current user is the receiver
        if (spaceId === spaceID && (userID === userId || userID === receiverId)) {
          // Fetch updated DM conversations
          await get().fetchDMConversations(spaceID, userID);
        }
      });

      socket.on('folder_created', ({ spaceId, folderData }) => {
        // Only update if it's for the current space
        if (spaceId === spaceID) {
          get().addFolder({
            folder_id: folderData.folderId,
            folder_name: folderData.folderName
          });
        }
      });

      socket.on('folder_deleted', ({ spaceId, folderId, channelsToMove }) => {
        if (spaceId === spaceID) {
          // Remove folder
          get().removeFolder(folderId);
          
          // Add channels to SIDEBAR using the provided channel data
          channelsToMove.forEach(channel => {
            get().addChannelToFolder('SIDEBAR', channel);
          });
        }
      });

      socket.on('reaction_added', (data) => {
        const { messageId, roomId, reaction, userId } = data;
        const message = get().messages[roomId]?.find(msg => msg.message_id === messageId);
        
        if (message) {
          if (!message.reactions) message.reactions = {};
          if (!message.reactions[reaction]) message.reactions[reaction] = [];
          if (!message.reactions[reaction].includes(userId)) {
            message.reactions[reaction].push(userId);
            get().updateMessage(roomId, message);
          }
        }
      });

      socket.on('reaction_removed', (data) => {
        const { messageId, roomId, reaction, userId } = data;
        const message = get().messages[roomId]?.find(msg => msg.message_id === messageId);
        
        if (message && message.reactions?.[reaction]) {
          message.reactions[reaction] = message.reactions[reaction].filter(id => id !== userId);
          if (message.reactions[reaction].length === 0) {
            delete message.reactions[reaction];
          }
          get().updateMessage(roomId, message);
        }
      });

      socket.on('message_deleted', ({ roomId, messageId }) => {
        set(state => {
          const roomMessages = state.messages[roomId] || [];
          return {
            messages: {
              ...state.messages,
              [roomId]: roomMessages.filter(msg => msg.message_id !== messageId)
            }
          };
        });
      });

      socket.on('update_user_details', (data) => {
        console.log('Received user details update:', data);
        const { userId, updates } = data;
        
        // Update user details in the store
        useUserDetailsStore.getState().updateUserDetails(userId, {
          user_display_name: updates.user_display_name,
          user_bio: updates.user_bio,
          user_image_url: updates.user_image_url
        });

        // If this update affects any DM conversations or group chats, refresh them
        const state = get();
        const dmConversations = state.dmConversations.some(dm => 
          dm.receiver_id === userId || dm.sender_id === userId
        );
        const groupChats = state.groupChats.some(group => 
          group.member_ids?.includes(userId)
        );

        if (dmConversations || groupChats) {
          const { spaceID, userID } = useAuthStore.getState();
          get().fetchDMConversations(spaceID, userID);
          get().fetchGroupChats(spaceID, userID);
        }
      });
    };

    set({ socket });
    setupSocketListeners(socket);
  },

  joinRoom: (roomId, roomType, receiverId = null, isFromSidebar = true) => {
    const socket = get().socket;
    const currentRoom = get().currentRoom;

    if (socket && currentRoom.room_id) {  
      get().markRoomAsRead(currentRoom.room_id);
    }

    if (isFromSidebar) {
      if (get().isDMCreationFlowActive) {
        get().toggleDMCreationFlow();
      }
      if (get().isFilesViewActive) {
        get().toggleFilesView();
      }
    }

    set({ 
      currentRoom: { 
        room_id: roomId, 
        room_type: roomType, 
        receiver_id: receiverId 
      }
    });

    const { userID, spaceID } = useAuthStore.getState();
    get().updateLastActiveRoom(userID, spaceID, roomId, roomType);
  },

  leaveRoom: () => {
    const socket = get().socket;
    const currentRoom = get().currentRoom;
  
    if (socket && currentRoom.room_id) {  
      get().markRoomAsRead(currentRoom.room_id);
      
      set(state => ({
        currentRoom: { room_id: '', room_type: '', receiver_id: '' },
        messages: { ...state.messages, [currentRoom.room_id]: [] }
      }));
    }
  },

  fetchLastActiveRoom: async (userID, spaceID) => {
    try {
      const { roomId, roomType, receiverId } = await getLastActiveRoom(userID, spaceID);
      set({ currentRoom: { room_id: roomId, room_type: roomType, receiver_id: receiverId } });
    } catch (error) {
      console.error("Error fetching last active room:", error);
    }
  },
  
  updateLastActiveRoom: async (userID, spaceID, roomID, roomType) => {
    try {
      await updateLastActiveRoom(userID, spaceID, roomID, roomType);
    } catch (error) {
      console.error("Error updating last active room:", error);
    }
  },

  fetchSpaceDetails: async (spaceId) => {
    try {
      const spaceDetails = await getSpaceDetails(spaceId);
      set({ spaceDetails: spaceDetails });
    } catch (error) {
      console.error("Error fetching space details:", error);
    }
  },

  fetchDMConversations: async (spaceId, userID) => {
    try {
      const dms = await fetchDMList(spaceId, userID);
      set({ dmConversations: dms });
    } catch (error) {
      console.error("Error fetching personal DMs:", error);
    }
  },

  fetchGroupChats: async (spaceId, userID) => {
    try {
      const groupChats = await fetchUserGroupChats(spaceId, userID);
      set({ groupChats: groupChats });
    } catch (error) {
      console.error("Error fetching personal group chats:", error);
    }
  },

  fetchMessages: async (roomId, roomType, before, limit) => {
    try {
      const { userID, spaceID } = useAuthStore.getState();
      const existingMessages = get().messages[roomId] || [];
      
      // If we already have messages and this is an initial load (not scrolling up)
      if (existingMessages.length > 0 && before === new Date().toISOString()) {
        return existingMessages;
      }

      let fetchedMessages;

      switch (roomType) {
        case 'channel':
          fetchedMessages = await getChannelMessages(spaceID, roomId, before, limit);
          break;
        case 'group_chat':
          fetchedMessages = await fetchGroupChatMessages(spaceID, roomId, before, limit);
          break;
        case 'dm':
          fetchedMessages = await getDMs(spaceID, roomId, before, limit);
          break;
        case 'thread':
          fetchedMessages = await fetchThreadMessages(spaceID, roomId, before, limit);
          break;
        default:
          throw new Error('Invalid room type');
      }
      
      // Decrypt messages and attachments
      const decryptedMessages = await Promise.all(
        fetchedMessages.map(async (msg) => {
          // First decrypt the message content
          const decryptedMessage = await decryptMessage(
            userID, 
            spaceID, 
            msg.reply_message || msg.message, 
            roomType, 
            roomId, 
            msg.key_timestamp
          );

          // Process and decrypt attachments if they exist
          let processedAttachments = [];
          if (Array.isArray(msg.attachments) && msg.attachments.length > 0) {
            const fileStore = useFileStore.getState();
            processedAttachments = await Promise.all(
              msg.attachments.map(async (attachment) => {
                const fileData = {
                  ...attachment,
                  sender_id: msg.sender_id,
                  space_id: spaceID,
                  room_id: roomId,
                  room_type: roomType,
                  key_timestamp: msg.key_timestamp,
                  message_id: msg.message_id,
                  created_at: msg.created_at,
                  file_id: attachment.key,
                  file_url: attachment.key,
                  file_name: attachment.name,
                  file_type: attachment.type
                };
                
                // Decrypt and add to fileStore
                await fileStore.decryptAndAddFile(fileData);
                return fileData;
              })
            );
          }

          // Determine room-specific IDs
          const roomSpecificId = {
            channel_id: roomType === 'channel' ? roomId : null,
            group_id: roomType === 'group_chat' ? roomId : null,
            dm_id: roomType === 'dm' ? roomId : null,
          };

          return {
            ...msg,
            message: decryptedMessage,
            space: spaceID,
            type: roomType,
            room: roomId,
            ...roomSpecificId,
            sender_id: msg.reply_sender_id || msg.sender_id,
            attachments: processedAttachments
          };
        })
      );

      // Fetch user details for all unique sender_ids
      const uniqueSenderIds = [...new Set(decryptedMessages.map(msg => msg.sender_id))];
      
      await Promise.all(uniqueSenderIds.map(senderId => 
        useUserDetailsStore.getState().fetchUserDetails(senderId, spaceID)
      ));

      set(state => {
        const newMessages = { ...state.messages };
        const existingMessages = newMessages[roomId] || [];
        
        const existingMessageIds = new Set(existingMessages.map(msg => msg.message_id));
        
        const uniqueNewMessages = decryptedMessages
          .filter(msg => !existingMessageIds.has(msg.message_id))
          .map(msg => ({
            ...msg,
            attachments: Array.isArray(msg.attachments) ? msg.attachments : [],
            reply_count: msg.reply_count || 0,
            last_reply_at: msg.last_reply_at || null,
            has_replies: msg.reply_count > 0
          }));

        // If we received fewer messages than requested, mark this room as completed
        if (decryptedMessages.length < limit) {
          return {
            messages: {
              ...newMessages,
              [roomId]: [...uniqueNewMessages, ...existingMessages].sort((a, b) => 
                new Date(a.created_at) - new Date(b.created_at)
              )
            },
            completedRooms: new Set([...state.completedRooms, roomId])
          };
        }

        return {
          messages: {
            ...newMessages,
            [roomId]: [...uniqueNewMessages, ...existingMessages].sort((a, b) => 
              new Date(a.created_at) - new Date(b.created_at)
            )
          }
        };
      });

    } catch (error) {
      console.error('Error fetching messages:', error);
      throw error;
    }
  },

  setMessages: (messages) => set({ messages }),

  addMessage: (roomId, message) => { 
    set(state => {
      const newMessages = { ...state.messages };
      const roomMessages = newMessages[roomId] || [];
      
      // Check if the message already exists by message_id
      const messageExists = roomMessages.find(msg => msg.message_id === message.message_id);
      
      if (!messageExists) {
        newMessages[roomId] = [...roomMessages, message];
      }
  
      return { messages: newMessages };
    });
  },

  updateMessage: (roomId, updatedMessage) => {
    set(state => {
      const newMessages = { ...state.messages };
      const roomMessages = newMessages[roomId] || [];
      const messageIndex = roomMessages.findIndex(msg => msg.message_id === updatedMessage.message_id);
      
      if (messageIndex !== -1) {
        roomMessages[messageIndex] = updatedMessage;
        newMessages[roomId] = roomMessages;
      }
  
      return { messages: newMessages };
    });
  },

  fetchFoldersForSpace: async (spaceId) => {
    try {
      // Fetch folders for the given space
      const folders = await getFolders(spaceId);

      // Initialize objects to store folder contents and channel details
      const folderContents = {};
      const channelDetails = {};
  
      // Fetch contents for each folder and channel details concurrently
      await Promise.all(folders.map(async ({ folder_id }) => {
        // Get contents for the current folder
        folderContents[folder_id] = await getFolderContents(spaceId, folder_id);
        
        // Fetch channel details for items in the folder that are channels
        await Promise.all(folderContents[folder_id].map(async ({ channel_id }) => {
          if (!channelDetails[channel_id]) {
            channelDetails[channel_id] = await getChannelDetails(spaceId, channel_id);
          }
        }));
      }));
  
      // Update the state with all fetched data
      set(state => ({
        folders,
        folderContents,
        channelDetails: { ...state.channelDetails, ...channelDetails },
        isDataLoaded: { ...state.isDataLoaded, folderContents: true },
      }));
    } catch (error) {
      console.error("Error fetching folders and contents:", error);
    }
  },

  toggleFolder: (folderId) => {
    set(state => {
      const newCollapsedFolders = new Set(state.collapsedFolders);
      if (newCollapsedFolders.has(folderId)) {
        newCollapsedFolders.delete(folderId);
      } else {
        newCollapsedFolders.add(folderId);
      }
      return { collapsedFolders: newCollapsedFolders };
    });
  },

  addFolder: (folder) => {
    set(state => ({
      folders: [...state.folders, folder],
      collapsedFolders: new Set(state.collapsedFolders)  
    }));
  },

  removeFolder: (folderId) => {
    set(state => {
      const newCollapsedFolders = new Set(state.collapsedFolders);
      newCollapsedFolders.delete(folderId);  
  
      return {
        folders: state.folders.filter(folder => folder.folder_id !== folderId),
        collapsedFolders: newCollapsedFolders
      };
    });
  },

  addChannelToFolder: (folderId, channelData) => {
    set(state => {
      const updatedFolderContents = {
        ...state.folderContents,
        [folderId]: [
          ...(state.folderContents[folderId] || []),
          { 
            space_id: channelData.spaceId, 
            folder_id: folderId, 
            channel_id: channelData.channelId, 
            order: state.folderContents[folderId]?.length || 0  
          }
        ]
      };

      const updatedChannelDetails = {
        ...state.channelDetails,
        [channelData.channelId]: {
          channel_name: channelData.channelName,
          channel_description: channelData.channelDescription,
          channel_id: channelData.channelId,
          space_id: channelData.spaceId,
          folder_id: folderId,
          created_at: new Date().toISOString(),
        }
      };

      return {
        folderContents: updatedFolderContents,
        channelDetails: updatedChannelDetails,
      };
    });
  },

  removeChannelFromFolder: (folderId, channelId) => {
    set(state => {
      const updatedFolderContents = {
        ...state.folderContents,
        [folderId]: state.folderContents[folderId].filter(item => item.channel_id !== channelId),
      };

      const updatedChannelDetails = { ...state.channelDetails };
      delete updatedChannelDetails[channelId];

      return {
        folderContents: updatedFolderContents,
        channelDetails: updatedChannelDetails,
      };
    });
  },

  markRoomAsRead: async (roomId) => {
    const timestamp = new Date().toISOString();
    const currentRoom = get().currentRoom;
    
    if (get().unreadRooms.has(roomId)) {
      set(state => ({
        unreadRooms: new Set([...state.unreadRooms].filter(id => id !== roomId)),
        lastReadTimestamps: { ...state.lastReadTimestamps, [roomId]: timestamp }
      }));

      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, timestamp);
    }
  },

  checkForUnread: async (roomId, messageTimestamp) => {
    const lastRead = get().lastReadTimestamps[roomId];
    const currentRoom = get().currentRoom;
    
    if (currentRoom.room_id === roomId) {
      set(state => ({
        lastReadTimestamps: { ...state.lastReadTimestamps, [roomId]: messageTimestamp },
        unreadRooms: new Set([...state.unreadRooms].filter(id => id !== roomId))
      }));
      
      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, messageTimestamp);
      return;
    }
    
    if (!lastRead || new Date(messageTimestamp) > new Date(lastRead)) {
      set(state => ({
        unreadRooms: new Set([...state.unreadRooms, roomId])
      }));

      const { userID, spaceID } = useAuthStore.getState();
      await updateLatestRoomActivity(spaceID, userID, roomId, currentRoom.room_type, lastRead || new Date(0).toISOString());
    }
  },

  clearRoomCompletion: (roomId) => {
    set(state => ({
      completedRooms: new Set([...state.completedRooms].filter(id => id !== roomId))
    }));
  },

  deleteMessage: (roomId, messageId) => {
    set(state => {
      const roomMessages = state.messages[roomId] || [];
      return {
        messages: {
          ...state.messages,
          [roomId]: roomMessages.filter(msg => msg.message_id !== messageId)
        }
      };
    });
  },

  handleMessageEdit: (roomId, messageId, newContent) => {
    set(state => {
      const newMessages = { ...state.messages };
      const roomMessages = newMessages[roomId] || [];
      const messageIndex = roomMessages.findIndex(msg => msg.message_id === messageId);
      
      if (messageIndex !== -1) {
        roomMessages[messageIndex] = {
          ...roomMessages[messageIndex],
          message: newContent
        };
        newMessages[roomId] = roomMessages;
      }

      return { messages: newMessages };
    });
  }

}));

export default useSidebarStore;