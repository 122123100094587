import { storeIdentityKey } from './indexedDBService';
import { uploadPublicIdentityKey } from './keyBackendService';

import { generateBackupFileContent, autoDownloadBackupFile } from '../utils/backupKeyFile';
import { rsaKeyToString } from '../utils/keyUtils';

async function generateIdentityKey() {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    },
    true,
    ["encrypt", "decrypt"]
  );
  
  return keyPair;
}

async function registerWorkspaceIdentityKey(userId, workspaceId, identityKey) {
  await storeIdentityKey(userId, workspaceId, identityKey);

  const publicKeyString = await rsaKeyToString(identityKey.publicKey, false);
  await uploadPublicIdentityKey(userId, workspaceId, publicKeyString);
}

async function generateAndRegisterUserKeys(userId, workspaceId, spaceName) {
  const identityKey = await generateIdentityKey();
  await registerWorkspaceIdentityKey(userId, workspaceId, identityKey);
  
  const backupContent = await generateBackupFileContent(workspaceId, identityKey);
  const safeSpaceName = spaceName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
  const fileName = `${safeSpaceName}_mention_keys.pem`;
  await autoDownloadBackupFile(fileName, backupContent);
}

export { generateIdentityKey, generateAndRegisterUserKeys };