import React, { useState, useEffect, useRef } from 'react';
import styles from './DMCreationFlow.module.css';
import useAuthStore from '../../../../store/authStore';
import useSidebarStore from '../../../../store/sidebarStore';
import { getSpaceChannels } from '../../../../features/sidebar/services/channelService';
import { fetchDMwithUser, fetchSpaceMembers, startNewDM, fetchGroupChatsWithUsers, startNewGroupChat } from '../../services';
import ChatSectionView from '../ChatSectionView';

import Avatar from 'react-avatar';
import { v4 as uuidv4 } from 'uuid';
import { initializeRoomKeys } from '../../../../services/roomKeyInitialization';


const DMCreationFlow = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [spaceMembers, setSpaceMembers] = useState([]);
  const [spaceChannels, setSpaceChannels] = useState([]);
  const [isChannelSelected, setIsChannelSelected] = useState(false);
  const [showDropdown, setShowDropdown] = useState(true);

  const dropdownRef = useRef(null);

  const { toggleDMCreationFlow, joinRoom, leaveRoom, fetchMessages } = useSidebarStore();

  const userID = useAuthStore((state) => state.userID);
  const spaceId = useAuthStore((state) => state.spaceID);

  useEffect(() => {
    const fetchData = async () => {
      const members = await fetchSpaceMembers(spaceId);
      const channels = await getSpaceChannels(spaceId);
      setSpaceMembers(members);
      setSpaceChannels(channels);
    };
    fetchData();
  }, [spaceId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const clearCurrentConversation = () => {
    leaveRoom();
    useSidebarStore.getState().setMessages({});
  };

  const findAndJoinExistingRoom = async (users) => {
    clearCurrentConversation();

    if (users.length === 1) {
      const targetUserId = users[0].user_id;
      
      const dmConversations = await fetchDMwithUser(spaceId, userID, targetUserId);
      let dmId;

      if (dmConversations.length > 0) {
        dmId = dmConversations[0].dm_id;
      } else {
        dmId = uuidv4();
        await startNewDM(spaceId, userID, targetUserId, dmId);
        await initializeRoomKeys(userID, spaceId, 'dm', dmId, [userID, targetUserId]);
        
        // Update sidebar in real-time for DMs
        useSidebarStore.getState().fetchDMConversations(spaceId, userID);
      }

      joinRoom(dmId, 'dm', targetUserId, false);
      await fetchMessages(dmId, 'dm', new Date().toISOString(), 25);

      return true;
    } else if (users.length > 1) {
      const memberIds = [userID, ...users.map(user => user.user_id)];
      const groupChatConversations = await fetchGroupChatsWithUsers(spaceId, userID, memberIds);
      let groupId;

      if (groupChatConversations.length > 0) {
        groupId = groupChatConversations[0].group_id;
      } else {
        groupId = uuidv4();
        await startNewGroupChat(spaceId, memberIds, groupId);
        await initializeRoomKeys(userID, spaceId, 'group_chat', groupId, memberIds);
      }

      joinRoom(groupId, 'group_chat', null, false);
      await fetchMessages(groupId, 'group_chat', new Date().toISOString(), 25);

      // Update sidebar in real-time for group chats
      useSidebarStore.getState().fetchGroupChats(spaceId, userID);

      return true;
    }

    return false;
  };

  const handleUserSelect = async (item) => {
    if (selectedUsers.length > 0 && item.type === 'channel') {
      return;
    }
    
    setShowDropdown(false);
    setSearchTerm('');

    if (item.type === 'channel') {
      setSelectedUsers([item]);
      setIsChannelSelected(true);
      await joinRoom(item.channel_id, 'channel', null, false);
      await fetchMessages(item.channel_id, 'channel', new Date().toISOString(), 25);
    } else if (!selectedUsers.find(u => u.user_id === item.user_id)) {
      const newSelectedUsers = [...selectedUsers, item];
      setSelectedUsers(newSelectedUsers);
      await findAndJoinExistingRoom(newSelectedUsers);
    }
  };

  const handleUserRemove = async (id) => {
    const newSelectedUsers = selectedUsers.filter(u => (u.type === 'channel' ? u.channel_id !== id : u.user_id !== id));
    setSelectedUsers(newSelectedUsers);
    setIsChannelSelected(false);

    if (newSelectedUsers.length === 0) {
      clearCurrentConversation();
    } else if (newSelectedUsers[0].type !== 'channel') {
      await findAndJoinExistingRoom(newSelectedUsers);
    }
  };

  const filteredItems = (selectedUsers.length === 0 ? [...spaceMembers, ...spaceChannels] : spaceMembers).filter(item => {
    const name = item.type === 'channel' ? item.channel_name : item.user_display_name;
    const id = item.type === 'channel' ? item.channel_id : item.user_id;
    return name && typeof name === 'string' && 
      name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !selectedUsers.find(u => (u.type === 'channel' ? u.channel_id === id : u.user_id === id));
  });
  return (
    <div className={styles.dmCreationFlow}>
      <div className={styles.topBar}>
        <p className={styles.title}>New Message</p>
        <button onClick={toggleDMCreationFlow} className={styles.closeButton}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20px" viewBox="0 0 24 24">
            <path fill="currentColor" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z" />
          </svg>
        </button>
      </div>
      <div className={styles.searchSection}>
        <div className={styles.toField}>
          <span className={styles.toLabel}>Send to:</span>
          <div className={styles.selectedUsers}>
            {selectedUsers.map(user => (
              <span key={user.type === 'channel' ? user.channel_id : user.user_id} className={styles.userTag}>
                {user.type === 'channel' ? user.channel_name : user.user_display_name}
                <button onClick={() => handleUserRemove(user.type === 'channel' ? user.channel_id : user.user_id)}>×</button>
              </span>
            ))}
          </div>
          <input
            type="text"
            placeholder="@somebody, or a group of people"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            className={styles.searchInput}
          />
        </div>
      </div>
      {showDropdown && !isChannelSelected && (
        <div ref={dropdownRef} className={styles.userList}>
          {filteredItems.map(item => (
            <div key={item.type === 'channel' ? item.channel_id : item.user_id} className={styles.userItem} onClick={() => handleUserSelect(item)}>
              {item.type === 'channel' ? (
                <span className={styles.channelIcon}>#</span>
              ) : (
                <Avatar name={item.user_display_name} src={item.user_image_url} size="24" className={styles.userAvatar}/>
              )}
              <span className={styles.userName}>{item.type === 'channel' ? item.channel_name : item.user_display_name}</span>
            </div>
          ))}
        </div>
      )}
      <div className={styles.previewSection}>
        {selectedUsers.length > 0 ? (
          <ChatSectionView />
        ) : (
          <div className={styles.blankScreen}>
            <div className={styles.blankScreenIcon}>
              <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 48 48">
                <rect width="48" height="48" fill="none" />
                <path 
                  fill="none" 
                  stroke="currentColor" 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth="3.5"
                  d="M5.5 5.5h15.857v15.857H5.5zm19.07 7.928l10-10l10 10l-10 10zM5.5 26.643h15.857V42.5H5.5zm21.143 0H42.5V42.5H26.643z" 
                />
              </svg>
            </div>
            <p className={styles.blankScreenText}>Select users to start a conversation</p>
            <p className={styles.blankScreenSubtext}>
              DM your workspace members, create group chats, or engage in channel discussions.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default DMCreationFlow;
