import { initializeKeyStore } from '../utils/indexedDB';

// Identity Key Management
async function storeIdentityKey(userId, workspaceId, identityKey) {
  const keyStore = await initializeKeyStore();
  await keyStore.setIdentityKey(userId, workspaceId, identityKey);
}

async function getIdentityKey(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const result = await keyStore.getIdentityKey(userId, workspaceId);
  return result ? result.value : null;
}

// DM Key Management
async function storeDMKey(userId, workspaceId, dmId, key, createdAt) {
  const keyStore = await initializeKeyStore();
  await keyStore.setDMKey(userId, workspaceId, dmId, key, createdAt);
}

async function getDMKey(userId, workspaceId, dmId, timestamp) {
  const keyStore = await initializeKeyStore();
  return await keyStore.getDMKey(userId, workspaceId, dmId, timestamp);
}

// Group Chat Key Management
async function storeGroupKey(userId, workspaceId, groupId, key, createdAt) {
  const keyStore = await initializeKeyStore();
  await keyStore.setGroupKey(userId, workspaceId, groupId, key, createdAt);
}

async function getGroupKey(userId, workspaceId, groupId, timestamp) {
  const keyStore = await initializeKeyStore();
  return await keyStore.getGroupKey(userId, workspaceId, groupId, timestamp);
}

// Channel Key Management
async function storeChannelKey(userId, workspaceId, channelId, key, createdAt) {
  const keyStore = await initializeKeyStore();
  await keyStore.setChannelKey(userId, workspaceId, channelId, key, createdAt);
}

async function getChannelKey(userId, workspaceId, channelId, timestamp) {
  const keyStore = await initializeKeyStore();
  return await keyStore.getChannelKey(userId, workspaceId, channelId, timestamp);
}

export {
  storeIdentityKey,
  getIdentityKey,
  storeDMKey,
  getDMKey,
  storeGroupKey,
  getGroupKey,
  storeChannelKey,
  getChannelKey
};