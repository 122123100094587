import React, { useState } from 'react';
import styles from './MemberPortalView.module.css';
import useUserDetailsStore from '../../../../store/userDetailsStore';
import useAuthStore from '../../../../store/authStore';
import InviteEmailBox from '../../../../components/inviteBox/InviteEmailBox';

import { sendInvites, removeSpaceInvite, getUserByEmail } from '../../../onboarding/services/onboardingService';
import { getSpaceChannels } from '../../../sidebar/services/channelService';
import { fetchAndDecryptChannelKeys } from '../../../../services/keyFetching';
import { generateIdentityKey } from '../../../../services/identityKeyGeneration';
import { rsaKeyToString } from '../../../../utils/keyUtils';
import { encryptKeyWithPublicKey } from '../../../../services/senderKeyGeneration';
import { uploadPublicIdentityKey, uploadMultipleEncryptedSenderKeys } from '../../../../services/keyBackendService';
import { v4 as uuidv4 } from 'uuid';

const MemberPortalView = ({ spaceDetails }) => {
  const [emails, setEmails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pendingInvites, setPendingInvites] = useState([]);
  const userDetails = useUserDetailsStore(state => state.userDetails);
  const { userID, spaceID } = useAuthStore();
  
  const inviteCode = spaceDetails?.space_join_code;

  const members = Array.from(userDetails.values());
  const memberCount = members.length + pendingInvites.length;

  const getInviteLink = () => {
    const baseDomain = process.env.NODE_ENV === 'production' ? 'https://mention.so' : 'http://localhost:3000';
    return `${baseDomain}/invite/${inviteCode}`;
  };

  const handleSendInvites = async (emailsToSend = emails) => {
    if (!emailsToSend || emailsToSend.length === 0) return;
    setIsLoading(true);

    try {
      const inviteLink = getInviteLink();
      
      const channels = await getSpaceChannels(spaceID);
      const channelIDs = channels.map(channel => channel.channel_id);

      const decryptedChannelKeys = await fetchAndDecryptChannelKeys(userID, spaceID, channelIDs);

      // Process each email
      const inviteResults = await Promise.all(emailsToSend.map(async (email) => {
        try {
          // First check if user already exists
          let newUserID = await getUserByEmail(email);

          // If user doesn't exist, generate new ID
          if (!newUserID) {
            newUserID = uuidv4().toString();
          }

          // Generate new identity key for the user
          const identityKey = await generateIdentityKey();
          const publicKeyString = await rsaKeyToString(identityKey.publicKey, false);

          // Encrypt channel keys with new user's public key
          const encryptedKeys = await Promise.all(
            decryptedChannelKeys.map(async ({ room_id, key }) => {
              const encryptedKey = await encryptKeyWithPublicKey(key, identityKey.publicKey);
              return {
                user_id: newUserID,
                workspace_id: spaceID,
                room_id,
                encrypted_key: encryptedKey,
                type: 'channel',
                created_at: new Date().toISOString()
              };
            })
          );

          // Upload keys
          await uploadMultipleEncryptedSenderKeys(encryptedKeys);
          await uploadPublicIdentityKey(newUserID, spaceID, publicKeyString);
          
          // Send invite email with keys
          await sendInvites(email, inviteLink, identityKey, newUserID, spaceID, spaceDetails.space_name);

          return { email, success: true };
        } catch (error) {
          console.error(`Failed to process invite for ${email}:`, error);
          return { email, success: false, error: error.message };
        }
      }));

      // Update UI with results
      const successfulInvites = inviteResults.filter(result => result.success);
      const newPendingInvites = successfulInvites.map(({ email }) => ({
        email,
        status: 'Pending',
        dateInvited: new Date().toISOString(),
        id: Math.random().toString(36).substr(2, 9)
      }));
      
      setPendingInvites([...pendingInvites, ...newPendingInvites]);
      setEmails([]);

    } catch (error) {
      console.error('Error sending invites:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelInvite = async (inviteId) => {
    try {
      const invite = pendingInvites.find(inv => inv.id === inviteId);
      if (!invite) return;

      await removeSpaceInvite(inviteCode, invite.email);
      setPendingInvites(pendingInvites.filter(inv => inv.id !== inviteId));
    } catch (error) {
      console.error('Error canceling invite:', error);
    }
  };

  const allMembers = [
    ...members.map(member => ({
      ...member,
      email: member.userSpaceDetails?.user_email,
      dateJoined: member.userSpaceDetails?.user_join_date || 'Unknown',
      type: 'member'
    })),
    ...pendingInvites.map(invite => ({
      id: invite.id,
      email: invite.email,
      status: invite.status,
      dateJoined: invite.dateInvited,
      type: 'pending'
    }))
  ];

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Member Portal</h1>

      <section className={styles.inviteSection}>
        <h2 className={styles.header}>Invite Members</h2>
        <p className={styles.subheader}>
          Enter email addresses to invite new members to your workspace
        </p>       
        
        <InviteEmailBox
          emails={emails}
          setEmails={setEmails}
          onSendInvites={handleSendInvites}
          isLoading={isLoading}
        />
      </section>

      <section className={styles.memberSection}>
        <h2 className={styles.header}>Member Management</h2>
        <p className={styles.subheader}>
          Invite and Manage Workspace Members
        </p>
        
        <div className={styles.memberTable}>
          <div className={styles.tableHeader}>
            <div className={styles.memberColumn}>Members ({memberCount})</div>
            <div className={styles.emailColumn}>Email</div>
            <div className={styles.statusColumn}>Status</div>
            <div className={styles.roleColumn}>Role</div>
            <div className={styles.dateColumn}>Date Joined</div>
            <div className={styles.controlsColumn}>Admin Controls</div>
          </div>

          <div className={styles.tableBody}>
            {allMembers.map(member => (
              <div key={member.id} className={styles.tableRow}>
                <div className={styles.memberInfo}>
                  {member.type === 'member' ? (
                    <>
                      <img 
                        src={member.userSpaceDetails?.user_image_url || '/default-avatar.png'} 
                        alt="" 
                        className={styles.memberAvatar} 
                      />
                      <div className={styles.memberDetails}>
                        <div className={styles.memberName}>
                          {member.userSpaceDetails?.user_display_name}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className={styles.memberDetails}>
                      <div className={styles.memberName}>Pending Member</div>
                    </div>
                  )}
                </div>

                <div className={styles.emailCell}>
                  {member.email}
                </div>

                <div className={styles.statusCell}>
                  <div className={`${styles.statusIndicator} ${styles[member.status?.toLowerCase() || 'active']}`}>
                    <span className={styles.statusDot} />
                    {member.status || 'Active'}
                  </div>
                </div>

                <div className={styles.roleCell}>
                  {member.type === 'member' ? (
                    <select 
                      className={styles.roleSelect}
                      defaultValue={member.userSpaceDetails?.role || 'Workspace Member'}
                    >
                      <option>Admin</option>
                      <option>Member</option>
                    </select>
                  ) : (
                    <span className={styles.pendingRole}>Pending</span>
                  )}
                </div>

                <div className={styles.dateCell}>
                  {new Date(member.dateJoined).toLocaleDateString()}
                </div>

                <div className={styles.controlsCell}>
                  {member.type === 'member' ? (
                    <button className={styles.removeButton}>Remove User</button>
                  ) : (
                    <button 
                      className={styles.cancelButton}
                      onClick={() => handleCancelInvite(member.id)}
                    >
                      Cancel Invite
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MemberPortalView;