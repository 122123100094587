import React, { useState, useEffect } from 'react';
import styles from './SettingsModal.module.css';
import SettingsContent from './SettingsContent';
import useUserDetailsStore from '../../../store/userDetailsStore';
import useAuthStore from '../../../store/authStore';
import useLogout from '../../../hooks/useLogout'; 
import useSettingsStore from '../../../store/settingsStore';

const SettingsModal = ({ onClose }) => {
  const selectedSection = useSettingsStore(state => state.selectedSection);
  const setSelectedSection = useSettingsStore(state => state.setSelectedSection);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const { spaceID, userID } = useAuthStore();
  const userDetails = useUserDetailsStore(state => state.userDetails);
  const currentUser = userDetails.get(userID);
  const isAdmin = currentUser?.userSpaceDetails?.user_role === 'admin';
  
  const handleLogout = useLogout();

  useEffect(() => {
    const fetchSpaceDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}space/${spaceID}/get-space-details`
        );
        const details = await response.json();
        setSpaceDetails(details);
      } catch (error) {
        console.error('Error fetching space details:', error);
      }
    };

    if (spaceID) {
      fetchSpaceDetails();
    }
  }, [spaceID]);

  const handleClose = () => {
    setSelectedSection('account');
    onClose();
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  };

  const menuItems = {
    workspace: [
      { 
        id: 'key-management', 
        label: 'Key management',
        disabled: !isAdmin,
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M11.9 11H22v4.004c0 .546-.45.996-1 .996s-1-.45-1-.996V13h-2v2c0 .55-.45 1-1 1s-1-.45-1-1v-2h-4.1A5.002 5.002 0 0 1 2 12a5 5 0 0 1 9.9-1M7 15a3 3 0 1 0 0-6a3 3 0 0 0 0 6" />
          </svg>
        )
      },
      { 
        id: 'member-portal', 
        label: 'Member portal',
        disabled: !isAdmin,
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5M4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5S5.5 6.57 5.5 8.5S7.07 12 9 12m0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7m7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44M15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35c.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35" />
          </svg>
        )
      },
      { 
        id: 'workspace-settings', 
        label: 'Workspace settings',
        disabled: !isAdmin,
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 25 24">
            <path fill="currentColor" d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29a.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19a1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22a.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5a1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29a.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19a1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22a.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5a1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22m-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14a2.36 2.36 0 0 1 2.59.47l.54.54l.88-.88l-.54-.55a2.34 2.34 0 0 1-.48-2.56a2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46a2.36 2.36 0 0 1 .47-2.59l.54-.54l-.88-.88l-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14a2.36 2.36 0 0 1-2.59-.47l-.54-.54l-.88.88l.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46a2.36 2.36 0 0 1-.47 2.59l-.54.54l.88.88l.55-.54a2.39 2.39 0 0 1 4 1.67Z" />
            <path fill="currentColor" d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5a3.5 3.5 0 0 1-3.5 3.5m0-5a1.5 1.5 0 1 0 1.5 1.5a1.5 1.5 0 0 0-1.5-1.5" />
          </svg>
        )
      },
      { 
        id: 'billing', 
        label: 'Billing & support',
        disabled: !isAdmin,
        icon: <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 25"><path fill="currentColor" fillRule="evenodd" d="M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v1H2zm0 3v7a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-7zm5 2a1 1 0 1 0 0 2h5a1 1 0 1 0 0-2z" clipRule="evenodd"/></svg>
      },
    ],
    account: [
      { 
        id: 'account', 
        label: 'My account', 
        icon: 'fa-solid fa-address-card' ,
      },
      // { 
      //   id: 'notifications', 
      //   label: 'Notifications', 
      //   icon: 'fa-solid fa-bell' 
      // },
    ],
    bottom: [
      { 
        id: 'whats-new', 
        label: "What's New?", 
        icon: '✨',
        className: styles.whatsNewItem
      },
      { 
        id: 'logout', 
        label: 'Logout', 
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
              <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2" />
              <path d="M9 12h12l-3-3m0 6l3-3" />
            </g>
          </svg>
        ),
        className: styles.logoutItem,
        onClick: handleLogout
      }
    ]
  };

  return (
    <div className={styles.modalContainer} onClick={handleOverlayClick}> 
      <div className={styles.modalContent}>
        <div className={styles.sidebar}>
          <div className={styles.sectionHeader}>Workspace</div>
          
          {menuItems.workspace.map(item => (
            <div
              key={item.id}
              data-id={item.id}
              className={`${styles.menuItem} ${selectedSection === item.id ? styles.active : ''} ${item.disabled ? styles.disabled : ''}`}
              onClick={() => { !item.disabled && setSelectedSection(item.id); }}
            >
              {typeof item.icon === 'string' ? (
                <i className={item.icon}></i>
              ) : (
                item.icon
              )}
              {item.label}
            </div>
          ))}

          <div className={styles.gap}></div>

          <div className={styles.sectionHeader}>Account</div>
          
          {menuItems.account.map(item => (
            <div
              key={item.id}
              className={`${styles.menuItem} ${selectedSection === item.id ? styles.active : ''}`}
              onClick={() => setSelectedSection(item.id)}
            >
              <i className={item.icon}></i>
              {item.label}
            </div>
          ))}

          <div className={styles.big_gap2}></div>

          {menuItems.bottom.map(item => (
            <div
              key={item.id}
              className={`${styles.menuItem} ${item.className || ''}`}
              onClick={item.onClick || (() => setSelectedSection(item.id))}
            >
              {typeof item.icon === 'string' ? (
                <span className={styles.emoji}>{item.icon}</span>
              ) : (
                item.icon
              )}
              {item.label}
            </div>
          ))}
        </div>
        
        <div className={styles.content}>
          {/* Replace with a space store in the future */}
          <SettingsContent section={selectedSection} spaceDetails={spaceDetails} spaceID={spaceID}/>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;