import React from 'react';
import useAuthStore from '../store/authStore';
import { LandingPage } from './LandingPage/components/LandingPage';
import { Navigate } from 'react-router-dom';

const RootView = () => {
  const { isAuthenticated, spaceID, onboardingStatus } = useAuthStore();
  
  if (!isAuthenticated) {
    return <LandingPage />;
  }
  
  if (onboardingStatus && onboardingStatus !== 'completed') {
    return <Navigate to={`/${onboardingStatus}`} replace />;
  }

  if (onboardingStatus === 'completed' && spaceID) {
    return <Navigate to={`/client/${spaceID}`} replace />;
  }

  return <Navigate to="/create/space" replace />;
};

export { RootView }; 