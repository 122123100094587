import { getKeyForRoom } from './messageEncryption';

async function performFileEncryption(fileBuffer, key) {
  // Generate random IV
  const iv = window.crypto.getRandomValues(new Uint8Array(12));
  
  // Encrypt file data
  const encryptedData = await window.crypto.subtle.encrypt(
    { name: "AES-GCM", iv: iv },
    key,
    fileBuffer
  );

  // Combine IV and encrypted data
  const combined = new Uint8Array(iv.length + encryptedData.byteLength);
  combined.set(iv, 0);
  combined.set(new Uint8Array(encryptedData), iv.length);
  
  return combined;
}

async function performFileDecryption(encryptedData, key) {
  try {
    // Extract IV and encrypted portion
    const iv = encryptedData.slice(0, 12);
    const encrypted = encryptedData.slice(12);
    
    // Decrypt the data
    const decryptedBuffer = await window.crypto.subtle.decrypt(
      { name: "AES-GCM", iv: iv },
      key,
      encrypted.buffer
    );

    return decryptedBuffer;
  } catch (error) {
    console.error('Decryption failed:', error);
    throw error;
  }
}

async function encryptFile(file, userId, workspaceId, roomType, roomId, keyTimestamp) {
  try {
    const currentKey = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    if (!currentKey) {
      throw new Error('Encryption key not found');
    }

    // Read file as ArrayBuffer
    const fileBuffer = await file.arrayBuffer();
    
    return performFileEncryption(fileBuffer, currentKey);
  } catch (error) {
    console.error('Error encrypting file:', error);
    throw error;
  }
}

async function decryptFile(encryptedBlob, userId, workspaceId, roomType, roomId, keyTimestamp) {
  try {
    const currentKey = await getKeyForRoom(userId, workspaceId, roomType, roomId, keyTimestamp);
    if (!currentKey) {
      throw new Error('Decryption key not found');
    }

    const encryptedData = await encryptedBlob.arrayBuffer();
    const decryptedBuffer = await performFileDecryption(new Uint8Array(encryptedData), currentKey);
    
    const blob = new Blob([new Uint8Array(decryptedBuffer)], { 
      type: encryptedBlob.type || 'image/png'
    });

    return blob;
  } catch (error) {
    console.error('Error decrypting file:', error);
    throw error;
  }
}

export { encryptFile, decryptFile }; 