const saveMessage = async (spaceID, userID, folderID, messageData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}saved/message`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ spaceID, userID, folderID, messageData })
    });

    if (!response.ok) throw new Error('Failed to save message');
    return response.json();
  } catch (error) {
    console.error('Error saving message:', error);
    throw error;
  }
};

const unsaveMessage = async (spaceID, userID, folderID, messageID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}saved/message`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ spaceID, userID, folderID, messageID })
    });

    if (!response.ok) throw new Error('Failed to unsave message');
    return response.json();
  } catch (error) {
    console.error('Error unsaving message:', error);
    throw error;
  }
};

const getSavedFolders = async (spaceID, userID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}saved/folders/${spaceID}/${userID}`);
    
    if (!response.ok) throw new Error('Failed to fetch saved folders');

    return response.json();
  } catch (error) {
    console.error('Error fetching saved folders:', error);
    throw error;
  }
};

const createSavedFolder = async (spaceID, userID, folderName, folderID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}saved/folder`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ spaceID, userID, folderName, folderID })
    });

    if (!response.ok) throw new Error('Failed to create saved folder');
    return response.json();
  } catch (error) {
    console.error('Error creating saved folder:', error);
    throw error;
  }
};

const getSavedMessages = async (spaceID, userID, folderID) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}saved/messages/${spaceID}/${userID}/${folderID}`);
    if (!response.ok) throw new Error('Failed to fetch saved messages');
    return response.json();
  } catch (error) {
    console.error('Error fetching saved messages:', error);
    throw error;
  }
};

export {
  saveMessage,
  unsaveMessage,
  getSavedFolders,
  createSavedFolder,
  getSavedMessages
};