import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import useAuthStore from '../../store/authStore';
import useSidebarStore from '../../store/sidebarStore';
import { getFilesWithFilters} from './services/fileAttachmentService';
import styles from './FilesTab.module.css';
import FileLoadingState from './components/FileLoadingState';
import { fetchSpaceMembers } from '../chat/services/DMCreationFlowService';
import ImagePreviewModal from './components/ImagePreviewModal';
import useFileDetails from './hooks/useFileDetails';

import PDFPreviewModal from '../Message/messageAttachments/PDFPreviewModal';
import PDFPreview from '../Message/messageAttachments/PDFPreview';
import useFileStore from '../../store/fileStore';

const FilesTab = () => {
  const { spaceID } = useAuthStore();
  const { channelDetails, dmConversations, groupChats } = useSidebarStore();
  const { files, loading, hasMore, page, filters, setFiles, setHasMore, setPage, setLoading, setFilters, decryptedUrls, signedUrls } = useFileStore();
  const BATCH_SIZE = 20;

  const [spaceMembers, setSpaceMembers] = useState([]);
  
  const [viewMode, setViewMode] = useState('grid');

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);

  const RoomOption = ({ room }) => {
    const [userDetails, setUserDetails] = useState({ displayName: 'Loading...', avatarUrl: '/default-avatar.png' });

    useEffect(() => {
      const loadUserDetails = async () => {
        if (room.type === 'dm' && room.receiver_id) {
          const details = await useFileStore.getState().getUserDetails(room.receiver_id);
          setUserDetails(details);
        }
      };
      loadUserDetails();
    }, [room.receiver_id]);

    switch (room.type) {
      case 'dm':
        return (
          <option key={room.dm_id} value={room.dm_id}>
            {userDetails.displayName}
          </option>
        );
      case 'channel':
        return (
          <option key={room.channel_id} value={room.channel_id}>
            # {room.channel_name || 'Unknown Channel'}
          </option>
        );
      case 'group':
        return (
          <option key={room.group_id} value={room.group_id}>
            Group: {room.group_name || 'Unnamed Group'}
          </option>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const loadSpaceMembers = async () => {
      try {
        const members = await fetchSpaceMembers(spaceID);
        setSpaceMembers(members);
      } catch (error) {
        console.error('Error fetching space members:', error);
      }
    };
    loadSpaceMembers();
  }, [spaceID]);

  const fetchFiles = async (resetFiles = false) => {
    try {
      setLoading(true);
      const currentPage = resetFiles ? 1 : page;
      
      const result = await getFilesWithFilters(spaceID, {...filters, page: currentPage, limit: BATCH_SIZE});

      // Only filter out null files if there's a search query
      const validFiles = filters.searchQuery ? result.filter(file => file && file.file_name) : result.filter(file => file);

      const filteredResults = filters.searchQuery 
        ? validFiles.filter(file => {
            const matches = file.file_name.toLowerCase().includes(filters.searchQuery.toLowerCase());
            return matches;
          })
        : validFiles;
      
      if (resetFiles) {
        setFiles(filteredResults);
        setPage(1);
      } else {
        setFiles(prev => [...prev, ...filteredResults]);
      }
      
      setHasMore(result.length === BATCH_SIZE);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fileStore = useFileStore.getState();
    
    // Only fetch if we don't have initialized files or if filters changed from default
    if (!fileStore.isInitialized || 
        JSON.stringify(filters) !== JSON.stringify(fileStore.filters)) {
      fetchFiles(true);
    }
  }, [filters]);

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
    fetchFiles();
  };

  const handleFilterChange = (key, value) => {
    useFileStore.setState(state => ({
      filters: {
        ...state.filters,
        [key]: value
      }
    }));
  };

  const handleDateRangeChange = (range) => {
    const now = new Date();
    let startDate = new Date();

    switch (range) {
      case 'last7':
        startDate.setDate(now.getDate() - 7);
        break;
      case 'last30':
        startDate.setDate(now.getDate() - 30);
        break;
      case 'last90':
        startDate.setDate(now.getDate() - 90);
        break;
      case 'custom':
        return setFilters(prev => ({
          ...prev,
          dateRange: range
        }));
      default:
        startDate.setDate(now.getDate() - 30);
    }

    setFilters(prev => ({
      ...prev,
      dateRange: range,
      startDate: startDate.toISOString(),
      endDate: now.toISOString()
    }));
  };

  const handleCustomDateChange = (field, value) => {
    // Create date in local timezone
    const [year, month, day] = value.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    
    if (field === 'startDate') {
      date.setHours(0, 0, 0, 0);
    } else {
      date.setHours(23, 59, 59, 999);
    }

    // Convert to ISO string while preserving local date
    const isoString = new Date(
      date.getTime() - (date.getTimezoneOffset() * 60000)
    ).toISOString();

    setFilters(prev => ({
      ...prev,
      [field]: isoString
    }));
  };

  const renderFilters = () => (
    <div className={styles.filters}>
      <div className={styles.dateFilter}>
        <select 
          value={filters.dateRange}
          onChange={(e) => handleDateRangeChange(e.target.value)}
          className={styles.dateSelect}
        >
          <option value="last7">Last 7 days</option>
          <option value="last30">Last 30 days</option>
          <option value="last90">Last 90 days</option>
          <option value="custom">Custom range</option>
        </select>
        
        {filters.dateRange === 'custom' && (
          <div className={styles.customDateRange}>
            <input
              type="date"
              value={filters.startDate?.split('T')[0]}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => handleCustomDateChange('startDate', e.target.value)}
              className={styles.dateInput}
            />
            <span className={styles.dateSeparator}>to</span>
            <input
              type="date"
              value={filters.endDate?.split('T')[0]}
              min={filters.startDate?.split('T')[0]}
              max={new Date().toISOString().split('T')[0]}
              onChange={(e) => handleCustomDateChange('endDate', e.target.value)}
              className={styles.dateInput}
            />
          </div>
        )}
      </div>
      
      <select
        value={filters.fileType}
        onChange={(e) => handleFilterChange('fileType', e.target.value)}
      >
        <option value="">All Types</option>
        <option value="image/png">Images</option>
        <option value="application/pdf">Documents</option>
        <option value="video/quicktime">Videos</option>
        <option value="audio">Audio</option>
      </select>

      <select
        value={filters.roomID}
        onChange={(e) => handleFilterChange('roomID', e.target.value)}
      >
        <option value="">All Rooms</option>
        {dmConversations.map(dm => (
          <RoomOption key={dm.dm_id} room={{ type: 'dm', ...dm }} />
        ))}
        {channelDetails && Object.values(channelDetails).map(channel => (
          <RoomOption key={channel.channel_id} room={{ type: 'channel', ...channel }} />
        ))}
        {groupChats.map(group => (
          <RoomOption key={group.group_id} room={{ type: 'group', ...group }} />
        ))}
      </select>

      <select
        value={filters.senderID}
        onChange={(e) => handleFilterChange('senderID', e.target.value)}
      >
        <option value="">All Senders</option>
        {spaceMembers.map(member => (
          <option key={member.user_id} value={member.user_id}>
            {member.user_display_name || member.user_id}
          </option>
        ))}
      </select>
    </div>
  );

  const handlePDFClick = (file) => {
    const decryptedUrl = decryptedUrls[file.key || file.file_url]?.url;
    if (!decryptedUrl) return;
    
    setSelectedPDF({
      url: decryptedUrl,
      file_name: file.file_name,
      created_at: file.created_at,
      sender_id: file.sender_id,
      room_id: file.room_id,
      room_type: file.room_type || 'channel',
      receiver_id: file.receiver_id
    });
  };

  const renderFileGrid = () => (
    <div className={styles.fileGrid}>
      {files.map(file => (
        <div key={file.file_id} className={styles.fileCard}>
          <div className={styles.filePreview}>
            {file.file_type.startsWith('image/') ? (
              <img 
                src={decryptedUrls[file.file_url]?.url || '/placeholder-image.png'} 
                alt={file.file_name || 'File preview'} 
                className={styles.previewImage}
                onClick={() => setSelectedImage({
                  ...file,
                  url: decryptedUrls[file.file_url]?.url
                })}
              />
            ) : file.file_type === 'application/pdf' ? (
              <div 
                className={styles.pdfAttachment}
                onClick={() => handlePDFClick(file)}
                style={{ cursor: 'pointer' }}
              >
                <PDFPreview url={decryptedUrls[file.file_url]?.url} />
              </div>
            ) : (
              <div className={styles.fileIcon}>
                <i className="fa-solid fa-file" />
              </div>
            )}
          </div>
          
          <div className={styles.fileInfo}>
            <div className={styles.fileNameContainer}>
              <h3>{file.file_name}</h3>
              <a 
                href={decryptedUrls[file.file_url]?.url} 
                download={file.file_name}
                className={styles.downloadIcon}
                target="_blank"
                rel="noopener noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <rect width="24" height="24" fill="none" />
                  <path fill="currentColor" d="M6.5 20q-2.275 0-3.887-1.575T1 14.575q0-1.95 1.175-3.475T5.25 9.15q.425-1.8 2.125-3.425T11 4.1q.825 0 1.413.588T13 6.1v6.05l1.6-1.55L16 12l-4 4l-4-4l1.4-1.4l1.6 1.55V6.1q-1.9.35-2.95 1.838T7 11h-.5q-1.45 0-2.475 1.025T3 14.5t1.025 2.475T6.5 18h12q1.05 0 1.775-.725T21 15.5t-.725-1.775T18.5 13H17v-2q0-1.2-.55-2.238T15 7V4.675q1.85.875 2.925 2.588T19 11q1.725.2 2.863 1.488T23 15.5q0 1.875-1.312 3.188T18.5 20zm5.5-8.95" />
                </svg>
              </a>
            </div>
            {renderFileInfo(file)}
          </div>
        </div>
      ))}
    </div>
  );

  const renderFileList = () => (
    <div className={styles.fileList}>
      {files.map(file => (
        <div key={file.file_id} className={styles.fileListItem}>
          <div className={styles.fileIconListView}>
            {file.file_type.startsWith('image/') ? (
              <img 
                src={decryptedUrls[file.file_url]?.url || '/placeholder-image.png'} 
                alt={file.file_name} 
                className={styles.fileThumb} 
                onClick={() => setSelectedImage({
                  ...file,
                  url: decryptedUrls[file.file_url]?.url
                })}
                style={{ cursor: 'pointer' }}
              />
            ) : file.file_type === 'application/pdf' ? (
              <div 
                className={styles.pdfAttachmentListView}
                onClick={() => handlePDFClick(file)}
                style={{ cursor: 'pointer' }}
              >
                <PDFPreview url={decryptedUrls[file.file_url]?.url} />
              </div>
            ) : (
              <i className="fa-solid fa-file" />
            )}
          </div>
          
          <div className={styles.fileDetails}>
            <h4>{file.file_name}</h4>
            {renderFileInfoListView(file)}
          </div>

          <div className={styles.fileActions}>
            <a 
              href={decryptedUrls[file.file_url]?.url} 
              download={file.file_name}
              className={styles.downloadButton}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download
            </a>
          </div>
        </div>
      ))}
    </div>
  );

  const renderEmptyState = () => (
    <div className={styles.emptyState}>
      <i className="fa-solid fa-file-circle-question"></i>
      <h3>No files found</h3>
      <p>Try adjusting your filters or upload some files to get started</p>
    </div>
  );

  const FileInfo = ({ file }) => {
    const fileDetails = useFileDetails(file);

    return (
      <div className={styles.fileMetadata}>
        <div className={styles.senderInfo}>
          <div className={styles.senderDetails}>
            <img 
              src={fileDetails.senderDetails?.user_image_url || 'default-avatar.png'} 
              alt={`${fileDetails.senderDetails?.user_display_name}'s avatar`} 
              className={styles.senderAvatar} 
            />
            <div className={styles.topLine}>
              <span>{fileDetails.senderDetails?.user_display_name || 'Unknown User'}</span>
              <span>in {fileDetails.prefix}{fileDetails.roomDisplayName}</span>
            </div>
          </div>

          <div className={styles.dateWrapper}>
            <span>{format(new Date(file.created_at), 'MMM dd, yyyy')}</span>
          </div>
        </div>
      </div>
    );
  };

  const FileInfoListView = ({ file }) => {
    const fileDetails = useFileDetails(file);
    return (
      <div className={styles.fileMetadataListView}>
        
        <div className={styles.senderInfoListView}>
          <div className={styles.senderDetailsListView}>
            
            <div className={styles.topLineListView}>
              <span>Shared by {fileDetails.senderDetails?.user_display_name || 'Unknown User'} on {format(new Date(file.created_at), 'MMM dd, yyyy')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderFileInfo = (file) => {
    return (
      <FileInfo file={file} />
    );
  };
  
  const renderFileInfoListView = (file) => {
    return (
      <FileInfoListView file={file} />
    );
  };

  return (
    <div className={styles.filesContainer}>
      <div className={styles.header}>
        <h2>Shared Files</h2>
        <div className={styles.viewToggle}>
          <button
            className={`${styles.viewButton} ${viewMode === 'grid' ? styles.active : ''}`}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
          <button
            className={`${styles.viewButton} ${viewMode === 'list' ? styles.active : ''}`}
            onClick={() => setViewMode('list')}
          >
            List
          </button>
        </div>
      </div>
      <input
        type="text"
        placeholder="Search files..."
        value={filters.searchQuery || ''}
        onChange={(e) => handleFilterChange('searchQuery', e.target.value)}
        className={styles.searchInput}
      />
      <div className={styles.controls}>
        {renderFilters()}
      </div>
      <div className={styles.content}>
        {loading && files.length === 0 ? (
          <FileLoadingState />
        ) : files.length === 0 ? (
          renderEmptyState()
        ) : (
          <>
            {viewMode === 'grid' ? renderFileGrid() : renderFileList()}
            {hasMore && !loading && (
              <button 
                className={styles.loadMoreButton}
                onClick={handleLoadMore}
              >
                Load More
              </button>
            )}
            {loading && <FileLoadingState />}
          </>
        )}
      </div>
      {selectedImage && (
        <ImagePreviewModal 
          image={selectedImage} 
          onClose={() => setSelectedImage(null)}
        />
      )}
      {selectedPDF && (
        <PDFPreviewModal
          file={selectedPDF}
          onClose={() => setSelectedPDF(null)}
        />
      )}
    </div>
  );
};

export default FilesTab;
