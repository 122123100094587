import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSettingsStore from '../../../../../store/settingsStore';
import styles from './SpaceDropdown.module.css';
import useLogout from '../../../../../hooks/useLogout';
import useAuthStore from '../../../../../store/authStore';

const SpaceDropdownView = ({ activeAccounts, handleSpaceSwitch, onClose }) => {
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const navigate = useNavigate();
  const setIsSettingsModalOpen = useSettingsStore(state => state.setIsSettingsModalOpen);
  const setSelectedSection = useSettingsStore(state => state.setSelectedSection);
  const handleLogout = useLogout();

  const handleSettingsClick = (location) => {
    setSelectedSection(location);
    setIsSettingsModalOpen(true);
    onClose();
  };

  const handleAddAccount = () => {
    // Store current path for return after login
    localStorage.setItem('returnPath', window.location.pathname);
    localStorage.setItem('addingAccount', 'true');
    
    // Clear current session but maintain store state for existing account
    useAuthStore.getState().clearCurrentSession();
    
    // Navigate to login
    navigate('/login');
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.primaryMenu}>
        <div className={styles.menuItem} onClick={() => handleSettingsClick('account')}>
          Settings
          
          <span className={styles.shortcut}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <rect width="16" height="16" fill="none" />
              <path fill="currentColor" fillRule="evenodd" d="M4.5 2a2.5 2.5 0 0 0 0 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a2.5 2.5 0 0 0 0 5A2.5 2.5 0 0 0 7 11.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a2.5 2.5 0 0 0 5 0A2.5 2.5 0 0 0 11.5 9h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a2.5 2.5 0 0 0 0-5A2.5 2.5 0 0 0 9 4.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1A2.5 2.5 0 0 0 4.5 2M9 7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zm-3-3v1a.5.5 0 0 1-.5.5h-1A1.5 1.5 0 1 1 6 4.5M11.5 6h-1a.5.5 0 0 1-.5-.5v-1A1.5 1.5 0 1 1 11.5 6M6 11.5v-1a.5.5 0 0 0-.5-.5h-1A1.5 1.5 0 1 0 6 11.5m5.5-1.5h-1a.5.5 0 0 0-.5.5v1a1.5 1.5 0 1 0 1.5-1.5" clipRule="evenodd" />
            </svg>
            <div className={styles.G_shortcut}>G</div>
          </span>
        </div>
        
        <div className={styles.menuItem} onClick={() => handleSettingsClick('member-portal')}>
          Invite and manage members
        </div>

        <div className={styles.divider} />

        <div className={styles.menuItem} onClick={() => handleSettingsClick('billing')}>
          Upgrade workspace
        </div>

        <div className={styles.divider} />

        <div 
          className={styles.menuItem} 
          onClick={() => setShowWorkspaces(true)}
          onMouseEnter={() => setShowWorkspaces(true)}
        >
          Switch workspace
          <i className={`fa-solid fa-chevron-right ${styles.submenuIcon}`} />
        </div>

        <div className={styles.menuItem} onClick={handleLogout}>
          Log out
          <span className={styles.shortcut}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <rect width="16" height="16" fill="none" />
              <path fill="currentColor" fillRule="evenodd" d="M4.5 2a2.5 2.5 0 0 0 0 5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a2.5 2.5 0 0 0 0 5A2.5 2.5 0 0 0 7 11.5v-1a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a2.5 2.5 0 0 0 5 0A2.5 2.5 0 0 0 11.5 9h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h1a2.5 2.5 0 0 0 0-5A2.5 2.5 0 0 0 9 4.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1A2.5 2.5 0 0 0 4.5 2M9 7.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5zm-3-3v1a.5.5 0 0 1-.5.5h-1A1.5 1.5 0 1 1 6 4.5M11.5 6h-1a.5.5 0 0 1-.5-.5v-1A1.5 1.5 0 1 1 11.5 6M6 11.5v-1a.5.5 0 0 0-.5-.5h-1A1.5 1.5 0 1 0 6 11.5m5.5-1.5h-1a.5.5 0 0 0-.5.5v1a1.5 1.5 0 1 0 1.5-1.5" clipRule="evenodd" />
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <rect width="24" height="24" fill="none" />
              <path fill="currentColor" d="M8 20v-7H5.1q-.65 0-.912-.562t.137-1.063l6.9-8.425q.3-.375.775-.375t.775.375l6.9 8.425q.4.5.138 1.063T18.9 13H16v7q0 .425-.288.713T15 21H9q-.425 0-.712-.288T8 20m2-1h4v-8h2.775L12 5.15L7.225 11H10zm2-8" />
            </svg>

            <div className={styles.L_shortcut}>L</div>
          </span>
        </div>
      </div>
 
      {showWorkspaces && (
        <div 
          className={styles.secondaryMenu}
          onMouseLeave={() => setShowWorkspaces(false)}
        >
          {activeAccounts.map((account, index) => (
            <div key={account.email}>
              <div className={styles.emailSection}>
                <div className={styles.emailHeader}>{account.email}</div>
                <div className={styles.workspaceList}>
                  {account.workspaces.map(workspace => (
                    <div
                      key={workspace.spaceId}
                      className={styles.menuItem}
                      onClick={() => handleSpaceSwitch(workspace.spaceId, account.email)}
                    >
                      <img
                        src={workspace.imageUrl || '/default-workspace.png'}
                        alt=""
                        className={styles.workspaceIcon}
                      />
                      {workspace.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}

          <div className={styles.divider} />

          <div className={styles.menuItem} onClick={() => {
            navigate('/create/workspace');
            onClose();
          }}>
            Create a new workspace
          </div>

          <div className={styles.menuItem} onClick={handleAddAccount}>
            Add an account
          </div>
        </div>
      )}
    </div>
  );
};

export default SpaceDropdownView;