const getFilesByType = async (spaceID, fileType, startDate, endDate, limit = 15) => {
  try {
    const defaultEndDate = new Date();
    defaultEndDate.setUTCHours(23, 59, 59, 999);
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 2);

    const queryParams = new URLSearchParams({
      fileType,
      startDate: startDate || defaultStartDate.toISOString(),
      endDate: endDate || defaultEndDate.toISOString(),
      limit: limit.toString(),
    });
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}files/${spaceID}/by-type?${queryParams}`);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to fetch files by type');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching files by type:', error);
    throw error;
  }
};

const getFilesByRoom = async (spaceID, roomID, startDate, endDate) => {
  try {
    const defaultEndDate = new Date();
    defaultEndDate.setUTCHours(23, 59, 59, 999);
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);

    const adjustedEndDate = new Date(endDate || defaultEndDate);
    adjustedEndDate.setUTCHours(23, 59, 59, 999);

    const queryParams = new URLSearchParams({
      roomID,
      startDate: startDate || defaultStartDate.toISOString(),
      endDate: adjustedEndDate.toISOString()
    });
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}files/${spaceID}/by-room?${queryParams}`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch files by room');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching files by room:', error);
    throw error;
  }
};

const getFilesBySender = async (spaceID, senderID, startDate, endDate) => {
  try {
    const defaultEndDate = new Date();
    defaultEndDate.setUTCHours(23, 59, 59, 999);
    const defaultStartDate = new Date();
    defaultStartDate.setMonth(defaultStartDate.getMonth() - 3);

    const adjustedEndDate = new Date(endDate || defaultEndDate);
    adjustedEndDate.setUTCHours(23, 59, 59, 999);

    const queryParams = new URLSearchParams({
      senderID,
      startDate: startDate || defaultStartDate.toISOString(),
      endDate: adjustedEndDate.toISOString()
    });
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}files/${spaceID}/by-sender?${queryParams}`);
    
    if (!response.ok) {
      throw new Error('Failed to fetch files by sender');
    }

    return response.json();
  } catch (error) {
    console.error('Error fetching files by sender:', error);
    throw error;
  }
};

const getFilesWithFilters = async (spaceID, filters) => {
  const { fileType, roomID, senderID, startDate, endDate, accessibleRooms } = filters;

  if (fileType) {
    return getFilesByType(spaceID, fileType, startDate, endDate);
  }
  if (roomID) {
    return getFilesByRoom(spaceID, roomID, startDate, endDate);
  }
  if (senderID) {
    return getFilesBySender(spaceID, senderID, startDate, endDate);
  }
  
  // If no specific filter is provided but we have accessible rooms,
  // fetch files from all accessible rooms and combine them
  if (accessibleRooms && accessibleRooms.length > 0) {
    const filePromises = accessibleRooms.map(room => 
      getFilesByRoom(spaceID, room.id, startDate, endDate)
    );
    
    const roomFiles = await Promise.all(filePromises);

    // Combine and sort all files by created_at date
    return roomFiles
      .flat()
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }
  
  // Fallback to empty array if no rooms are accessible
  return [];
};

module.exports = {
  getFilesByType,
  getFilesByRoom,
  getFilesBySender,
  getFilesWithFilters
};