import React, { useState } from 'react';
import styles from './InviteEmailBox.module.css';

const InviteEmailBox = ({ emails, setEmails, onSendInvites, isLoading }) => {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState('');
  const [hasValidInput, setHasValidInput] = useState(false);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setError('');
    
    // Check if current input is a valid email
    setHasValidInput(isValidEmail(value.trim()) && !emails.includes(value.trim()));
  };

  const handleAddEmail = (e) => {
    if ((e.key === 'Enter' || e.key === ' ' || e.key === ',') && inputValue.trim()) {
      e.preventDefault();
      addEmailToList(inputValue.trim());
    }
  };

  const addEmailToList = (email) => {
    if (!isValidEmail(email)) {
      setError(`"${email}" is not a valid email address`);
      return false;
    }

    if (emails.includes(email)) {
      setError('This email has already been added');
      return false;
    }

    setEmails([...emails, email]);
    setInputValue('');
    setError('');
    setHasValidInput(false);
    return true;
  };

  const handleSendClick = async () => {
    if (hasValidInput && inputValue.trim()) {
      const email = inputValue.trim();
      if (isValidEmail(email) && !emails.includes(email)) {
        onSendInvites([email]);
        setInputValue('');
        setHasValidInput(false);
        return;
      }
    }
    
    if (emails.length > 0 && onSendInvites) {
      onSendInvites(emails);
    }
  };

  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
    setError('');
  };

  return (
    <div className={styles.inviteEmailBoxWrapper}>
      <div className={styles.inviteEmailBox}>
        {emails.map((email, index) => (
          <span key={index} className={styles.emailTag}>
            {email}
            <button 
              type="button" 
              onClick={() => handleDeleteEmail(email)} 
              className={styles.deleteButton}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" viewBox="-6 -6 24 24">
                <rect x="-6" y="-6" width="24" height="24" fill="none" />
                <path fill="currentColor" d="m7.314 5.9l3.535-3.536A1 1 0 1 0 9.435.95L5.899 4.485L2.364.95A1 1 0 1 0 .95 2.364l3.535 3.535L.95 9.435a1 1 0 1 0 1.414 1.414l3.535-3.535l3.536 3.535a1 1 0 1 0 1.414-1.414L7.314 5.899z" />
              </svg>
            </button>
          </span>
        ))}
      </div>

      <div className={styles.emailInputWrapper}>
        <textarea
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleAddEmail}
          placeholder={emails.length === 0 ? "example@gmail.com" : ""}
          className={styles.emailInput}
        />
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
      
      {onSendInvites && (
        <button 
          className={styles.continueButton} 
          onClick={handleSendClick}
          disabled={isLoading || (emails.length === 0 && !hasValidInput)}
        >
          {isLoading ? 'Sending...' : 'Send Invites'}
        </button>
      )}
    </div>
  );
};

export default InviteEmailBox;