import { useEffect, useRef, useState } from 'react';
import useSidebarStore from '../../store/sidebarStore';
import useAuthStore from '../../store/authStore';

// acount for multiple users typing
const useTypingIndicatorViewModel = () => {
  const socket = useSidebarStore(state => state.socket);
  const currentRoom = useSidebarStore(state => state.currentRoom);
  const userID = useAuthStore(state => state.userID);
  const [typingIndicator, setTypingIndicator] = useState({ isTyping: false, user: '' });

  const typingTimeoutRef = useRef(null);

  const handleTypingChange = () => {
    if (socket && currentRoom.room_id) {
      socket.emit('typing', { room: currentRoom.room_id, isTyping: true, sender_id: userID });
    }

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      if (socket) {
        socket.emit('typing', { room: currentRoom.room_id, isTyping: false, sender_id: userID });
      }
    }, 1000);
  };

  useEffect(() => {
    const handleTyping = (data) => {
      if (data.room === currentRoom.room_id) {
        setTypingIndicator({ isTyping: data.isTyping, user: data.sender_id });
      }
    };

    if (socket) {
      socket.on('typing', handleTyping);

      return () => {
        socket.off('typing', handleTyping);
      };
    }
  }, [socket, currentRoom.room_id]);

  return { typingIndicator, handleTypingChange };
};

export default useTypingIndicatorViewModel;
