import React, { useState } from 'react';
import styles from './MessageAttachments.module.css';
import ImagePreviewModal from '../../fileAttachments/components/ImagePreviewModal';
import PDFPreview from './PDFPreview';
import PDFPreviewModal from './PDFPreviewModal';
import useFileStore from '../../../store/fileStore';
import LoadingAttachment from './LoadingAttachmentsSpinner/LoadingAttachment';
import useAuthStore from '../../../store/authStore';

const MessageAttachments = ({ attachments, message }) => {
  const decryptedUrls = useFileStore(state => state.decryptedUrls);
  const { userID } = useAuthStore();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedPDF, setSelectedPDF] = useState(null);

  const handlePreviewClick = (attachment, type) => {
    const decryptedUrl = decryptedUrls[attachment.key]?.url;
    if (!decryptedUrl) return;

    const previewData = {
      url: decryptedUrl,
      file_name: attachment.originalName || attachment.name,
      created_at: message.created_at,
      sender_id: message.sender_id,
      room_id: message.channel_id || message.group_id || message.dm_id,
      room_type: message.room_type,
      receiver_id: message.receiver_id
    };

    type === 'image' ? setSelectedImage(previewData) : setSelectedPDF(previewData);
  };

  if (!attachments?.length) return null;

  const areAttachmentsProcessed = attachments.every(
    attachment => decryptedUrls[attachment.key]?.url
  );

  // Only show loading state if user is the sender
  if (!areAttachmentsProcessed && message.sender_id === userID) {
    return <LoadingAttachment count={attachments.length} />;
  }

  // Don't render anything for receivers until attachments are processed
  if (!areAttachmentsProcessed) {
    return null;
  }

  return (
    <>
      {selectedImage && (
        <ImagePreviewModal 
          image={selectedImage} 
          onClose={() => setSelectedImage(null)} 
        />
      )}
      {selectedPDF && (
        <PDFPreviewModal
          file={selectedPDF}
          onClose={() => setSelectedPDF(null)}
        />
      )}
      <div className={styles.attachmentsContainer}>
        {attachments.map((attachment, index) => {
          const fileType = decryptedUrls[attachment.key]?.fileType;
          
          if (fileType?.startsWith('image/')) {
            return (
              <div key={index} className={styles.imageAttachment}>
                <div className={styles.imageContainer}>
                  <img 
                    src={decryptedUrls[attachment.key]?.url}
                    alt={attachment.originalName || attachment.name}
                    onClick={() => handlePreviewClick(attachment, 'image')}
                  />
                </div>
              </div>
            );
          } 
          
          if (fileType?.startsWith('application/pdf')) {
            return (
              <div key={index} 
                className={styles.pdfAttachment}
                onClick={() => handlePreviewClick(attachment, 'pdf')}
              >
                <PDFPreview url={decryptedUrls[attachment.key]?.url} />
                <div className={styles.pdfTitle}>
                  {attachment.originalName || attachment.name}
                </div>
              </div>
            );
          }
          
          return (
            <div key={index} className={styles.fileAttachment}>
              <a 
                href={decryptedUrls[attachment.key]?.url} 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {attachment.originalName || attachment.name}
              </a>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MessageAttachments;