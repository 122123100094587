import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Avatar from 'react-avatar';
import useAuthStore from '../../../store/authStore';
import styles from './JoinSpaceView.module.css';
import Spinner from '../../Spinner/Spinner';

import { validateKeyFile, storeIdentityKeyFile } from '../utils/keyFileHandler';
import { decryptAndStoreAllKeys } from '../../../services/keyFetching';

import { updateUser, updateUserInfo, removeSpaceInvite, updateSpaceMember, processJoinCode } from '../../onboarding/services/onboardingService';
import { getSpaceDetails, removePendingMember } from '../../../features/sidebar/services/spaceService';
import { createDefaultAvatar, getAvatarColor } from '../utils/imageUtils';

const JoinSpaceView = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [keyFile, setKeyFile] = useState(null);
  const [keys, setKeys] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const { joinCode } = useParams();
  const { userID, spaceID, email, isAuthenticated } = useAuthStore();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: `/invite/${joinCode}` }, replace: true });
      return;
    }

    const processJoinFlow = async () => {
      try {
        // Check if we already processed this invite server side
        if (location.state?.inviteProcessed) {
          const details = await getSpaceDetails(spaceID);
          setSpaceDetails(details);
          setLoading(false);
          return;
        }

        const inviteData = await processJoinCode(joinCode, email);

        const userRole = 'member';
        const onboardingStatus = 'completed';

        if (!inviteData) {
          setError('Invalid or expired invite link');
          setLoading(false);
          return;
        }

        await updateUser(email, inviteData.spaceID);
        await updateSpaceMember(inviteData.pendingUserID, inviteData.spaceID, '', '', '', email, onboardingStatus, userRole);

        useAuthStore.setState({
          userID: inviteData.pendingUserID,
          spaceID: inviteData.spaceID,
          userRole: userRole,
          onboardingStatus: onboardingStatus
        });

        const details = await getSpaceDetails(inviteData.spaceID);
        setSpaceDetails(details);
        setLoading(false);

      } catch (error) {
        console.error('Error joining space:', error);
        setError('Failed to join space. Please try again.');
        setLoading(false);
      }
    };

    processJoinFlow();
  }, [joinCode, isAuthenticated, navigate, email, spaceID, location.state]);

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const validatedKeys = await validateKeyFile(file);
      setKeyFile(file);
      setKeys(validatedKeys);
      setError(null);
    } catch (error) {
      setError('Invalid key file. Please ensure you uploaded the correct file.');
      setKeyFile(null);
    }
  };

  const handleContinue = async () => {
    if (!keyFile || !spaceDetails || !keys || !spaceID || !displayName.trim()) return;
    setLoading(true);

    try {
      const avatarColor = getAvatarColor(displayName);
      const defaultAvatar = createDefaultAvatar(displayName, avatarColor);
      
      await storeIdentityKeyFile(userID, spaceID, keys);
      await decryptAndStoreAllKeys(userID, spaceID);
      
      await updateUser(email, spaceID);
      await updateUserInfo(userID, spaceID, displayName, defaultAvatar);
 
      await removeSpaceInvite(joinCode, email);
      await removePendingMember(userID, spaceID);

      navigate('/', { replace: true });
    } catch (error) {
      console.error('Error joining space:', error);
      setError('Failed to join space. Please try again.');
      setLoading(false);
    }
  };

  const handleBackToMention = () => {
    navigate('/', { replace: true });
  };

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className={styles.container}>
        <h2 className={styles.error}>{error}</h2>
        <button onClick={handleBackToMention} className={styles.backButton}>
          Back to Mention
        </button>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.spaceCard}>
        <h2 className={styles.title}>Join {spaceDetails.space_name}</h2>
        <p className={styles.subtitle}>Please continue to set up your account</p>
        
        <div className={styles.formGroup}>
          <label htmlFor="displayName">Full Name</label>
          <input
            type="text"
            id="displayName"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            placeholder="Enter your full name"
            className={styles.input}
            required
          />
        </div>

        <div className={styles.keyFileUpload}>
          <p>Upload the identity key file you received via email:</p>
          <input
            type="file"
            onChange={handleFileUpload}
            accept=".pem"
            className={`${styles.fileInput} ${keyFile ? styles.hasFile : ''}`}
          />
        </div>
        
        <div className={styles.buttonContainer}>
          <button 
            onClick={handleContinue} 
            className={styles.continueButton}
            disabled={!keyFile || !displayName.trim()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default JoinSpaceView;