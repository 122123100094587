import { useState, useRef } from 'react';
import useSidebarStore from '../../store/sidebarStore';
import useAuthStore from '../../store/authStore';
import imageCompression from 'browser-image-compression';
import { encryptMessage } from '../../services/messageEncryption';
import { encryptFile } from '../../services/fileEncryption';
import { v4 as uuidv4 } from 'uuid';

const CHUNK_SIZE = 3; // Number of files to process at once

const useTextBoxViewModel = (roomId, roomType, parentRoomType, parentRoomId, parentCreatedAt) => {
  const { socket, currentRoom, isDMCreationFlowActive } = useSidebarStore();
  const { userID, spaceID } = useAuthStore();

  const [message, setMessage] = useState("");
  const fileInputRef = useRef(null); 
  const [attachments, setAttachments] = useState([]);
  const receiver_id = currentRoom.room_id;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    const newAttachments = files.map(file => {
      const attachment = {
        file,
        name: file.name,
        type: file.type,
        preview: null
      };
  
      if (file.type.startsWith('image/')) {
        attachment.preview = URL.createObjectURL(file);
      }
      
      return attachment;
    });
  
    setAttachments(prev => {
      const updated = [...prev, ...newAttachments];
      return updated;
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const removeAttachment = (index) => {
    const newAttachments = [...attachments];
    URL.revokeObjectURL(newAttachments[index].preview);
    newAttachments.splice(index, 1);
    setAttachments(newAttachments);
  }; 

  const processAttachments = async (attachments) => {
    const results = [];
    const timestamp = new Date().toISOString();

    for (let i = 0; i < attachments.length; i += CHUNK_SIZE) {
      const chunk = attachments.slice(i, i + CHUNK_SIZE);
      const formData = new FormData();
      
      for (const attachment of chunk) {
        let processedFile = attachment.file;
        
        // Compress images first if needed
        if (attachment.file.type.startsWith('image/')) {
          try {
            const options = {
              maxSizeMB: 1,
              maxWidthOrHeight: 1920,
              useWebWorker: true
            };
            const compressedBlob = await imageCompression(attachment.file, options);
            processedFile = new File(
              [compressedBlob], 
              attachment.file.name, 
              { type: compressedBlob.type }
            );
          } catch (error) {
            console.error('Error compressing image:', error);
            processedFile = attachment.file;
          }
        }

        // Encrypt the file
        const encryptedBlob = await encryptFile(processedFile, userID, spaceID, currentRoom.room_type, currentRoom.room_id, timestamp);

        formData.append('files', new File([encryptedBlob], processedFile.name, { 
          type: processedFile.type 
        }));
      }
      
      formData.append('spaceId', spaceID);
      formData.append('roomId', roomId);
      formData.append('roomType', roomType);
      formData.append('keyTimestamp', timestamp);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}messages/attachments/upload`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) throw new Error('Upload failed');
        const data = await response.json();
        results.push(...data.attachments);
      } catch (error) {
        console.error('Error uploading chunk:', error);
        throw error;
      }
    }

    return results;
  };

  const sendMessage = async () => {
    if (socket && roomId) {
      const messageId = uuidv4();
      const keyTimestamp = new Date().toISOString();
      const createdAt = new Date().toISOString();

      // Create decrypted message object first for optimistic UI update
      const decryptedMessage = {
        message_id: messageId,
        space: spaceID,
        room: roomId,
        type: roomType,
        sender_id: userID,
        receiver_id: receiver_id,
        message: message,
        key_timestamp: keyTimestamp,
        created_at: createdAt,
        channel_id: roomType === 'channel' ? roomId : null,
        group_id: roomType === 'group_chat' ? roomId : null,
        dm_id: roomType === 'dm' ? roomId : null,
        attachments: attachments.map(att => ({
          key: att.preview || URL.createObjectURL(att.file),
          name: att.file.name,
          type: att.file.type,
          isUnprocessed: true,
          originalFile: att.file
        })),
        parent_room_type: parentRoomType,
        parent_room_id: parentRoomId,
        parent_created_at: parentCreatedAt,
      };

      // Immediately update UI
      useSidebarStore.getState().addMessage(roomId, decryptedMessage);
      
      setMessage("");
      setAttachments([]);
      exitDMCreationFlow();

      try {
        // Process everything in the background
        const [{ encryptedData }, processedAttachments] = await Promise.all([
          encryptMessage(userID, spaceID, message, roomType, roomId, keyTimestamp),
          attachments.length > 0 ? processAttachments(attachments) : Promise.resolve([])
        ]);

        // Update the optimistic message with processed attachments
        const updatedDecryptedMessage = {
          ...decryptedMessage,
          attachments: processedAttachments.map(result => ({
            ...result,
            sender_id: userID,
            space: spaceID,
            type: roomType,
            room: roomId,
            key_timestamp: keyTimestamp
          }))
        };

        // Update UI with attachment info
        useSidebarStore.getState().updateMessage(roomId, updatedDecryptedMessage);

        // Send encrypted message via socket
        const newMessage = {
          ...decryptedMessage,
          message: encryptedData,
          attachments: processedAttachments.map(result => ({
            key: result.key,
            type: result.type,
            name: result.originalName
          }))
        };

        socket.emit("process_message", newMessage);

        // Handle thread logic if needed
        if (roomType === 'thread') {
          const parentMessage = useSidebarStore.getState().messages[parentRoomId]?.find(
            msg => msg.message_id === roomId
          );
          if (parentMessage) {
            const updatedParentMessage = {
              ...parentMessage,
              reply_count: (parentMessage.reply_count || 0) + 1,
              last_reply_at: createdAt
            };
            useSidebarStore.getState().updateMessage(parentRoomId, updatedParentMessage);
            socket.emit("reply_added", updatedParentMessage);
          }
        }
      } catch (error) {
        console.error('Error processing message:', error);
        // Could add error handling here to remove the optimistic message if needed
        useSidebarStore.getState().removeMessage(roomId, decryptedMessage.message_id);
      }
    }
  };

  const exitDMCreationFlow = () => {
    if (isDMCreationFlowActive) {
      useSidebarStore.getState().toggleDMCreationFlow();
    }
  }; 

  const resetTextareaHeight = () => {
    const textarea = document.getElementById('messageInput');
    if (textarea) {
      textarea.style.height = 'auto';
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (message.trim() || attachments.length > 0) {
        sendMessage();
        resetTextareaHeight();
      }
    }
  };

  const handleEmojiSelect = (emoji) => {
    setMessage(prevMessage => prevMessage + emoji);
  };

  return {
    message,
    setMessage,
    sendMessage,
    fileInputRef,
    handleFileSelect,
    removeAttachment,
    attachments,
    setAttachments,
    handleKeyPress,
    resetTextareaHeight,
    showEmojiPicker,
    setShowEmojiPicker,
    handleEmojiSelect,
  };
};

export default useTextBoxViewModel;