import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import styles from './Checkout.module.css';

const stripePromise = loadStripe('pk_test_51QPr67GKpk2qFBnGDulUrBbzP9MyfGpKKUfD5gP8xOuGwzo12YF1sHPrk1ydlYkfiEcpnn1bFNulIPYrLfgSlZKD005U5LdMmN');

const CheckoutForm = ({ selectedPlan, interval, onClose }) => {
  const [selectedInterval, setSelectedInterval] = useState(interval || 'month');
  
  const prices = {
    month: 35,
    year: 28  
  };

  const userCount = 5; // Hardcoded for now
  const totalPrice = selectedInterval === 'year' ? 
    prices[selectedInterval] * userCount * 12 : 
    prices[selectedInterval] * userCount;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.checkoutContainer}>
          <div className={styles.checkoutDetails}>
            <h2>Upgrade MentionHQ to Pro</h2>

            <div className={styles.formSection}>
              <h3>1. Billing Information</h3>
              <AddressElement options={{mode: 'billing'}} />
           </div>
            

            <div className={styles.formSection}>
              <h3>2. Payment Method</h3>
              <PaymentElement />
            </div>

       
          </div>

          <div className={styles.orderSummary}>
            <h3>Order Summary</h3>

            <div className={styles.billingOptions}>
              <button 
                className={`${styles.intervalButton} ${selectedInterval === 'month' ? styles.active : ''}`}
                onClick={() => setSelectedInterval('month')}
                >
                <div className={styles.radioCircle} />
                <div className={styles.intervalInfo}>
                  <span className={styles.intervalLabel}>Pay monthly</span>
                  <span className={styles.intervalPrice}>${prices.month} / month / member</span>
                </div>
              </button>
              <button 
                className={`${styles.intervalButton} ${selectedInterval === 'year' ? styles.active : ''}`}
                onClick={() => setSelectedInterval('year')}
                >
                <div className={styles.radioCircle} />
                <div className={styles.intervalInfo}>
                  <span className={styles.intervalLabel}>Pay annually</span>
                  <span className={styles.intervalPrice}>${prices.year} / month / member</span>
                </div>
                <span className={styles.savings}>Save 20%</span>
              </button>
            </div>

            <ul className={styles.featuresList}>
              <p>Pro features:</p>
              <li className={styles.featureItem}>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                  <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                Up to 100 guests
              </li>
              <li className={styles.featureItem}>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                  <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                24hr customer support
              </li>
              <li className={styles.featureItem}>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                  <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                Data export options
              </li>
              <li className={styles.featureItem}>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                  <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                Manual & automatic key rotations
              </li>
              <li className={styles.featureItem}>
                <svg xmlns="http://www.w3.org/2000/svg" width="0.9rem" height="0.9rem" viewBox="0 0 32 32">
                  <rect width="32" height="32" fill="none" />
                  <path fill="currentColor" d="m14 21.414l-5-5.001L10.413 15L14 18.586L21.585 11L23 12.415z" />
                  <path fill="currentColor" d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m0 26a12 12 0 1 1 12-12a12 12 0 0 1-12 12" />
                </svg>
                50GB File storage limits
              </li>
            </ul>
            
            <div className={styles.viewAllFeaturesContainer}>
              <a href="https://mention.so/pricing" target="_blank" rel="noopener noreferrer" className={styles.viewAllFeatures}>View all features</a>
            </div>

            <div className={styles.summaryItems}>
              <div className={styles.summaryItem}>
                <span>Pro Plan</span>
                <span>${prices[selectedInterval]} / user / {selectedInterval}</span>
              </div>
              <div className={styles.summaryItem}>
                <span>Sales Tax</span>
                <span>7.41%</span>
              </div>
              <div className={styles.summaryItem}>
                <span>Billable Users</span>
                <span>5 members</span>
              </div>
            </div>
            <div className={styles.total}>
              <span>Total</span>
              <div className={styles.totalPriceWrapper}>
                <span>${totalPrice} / {selectedInterval}</span>
                <span className={styles.detailedPrice}>${prices[selectedInterval]} x 5 members x 12 months</span>
              </div>
            </div>

            <button type="submit" className={styles.submitButton}>
              Upgrade To Pro
            </button>

            <p className={styles.terms}>By continuing, you agree to Mention's <a href="/privacy" target='_blank' className={styles.termsLink}>Terms and Conditions</a>.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkout = ({ isOpen, onClose, selectedPlan, interval }) => {
  if (!isOpen) return null;

  const options = {
    mode: 'subscription',
    amount: 1099,
    currency: 'usd',
    payment_method_types: ['card', 'us_bank_account'],
    appearance: {
      theme: 'night',
      variables: {
        colorPrimary: '#404249',
        colorBackground: '#1c1e1f',
        colorText: '#ffffff',
        colorDanger: '#ff4747',
        fontFamily: 'SF Pro Text, system-ui, sans-serif',
        spacingUnit: '6px',
        borderRadius: '8px',
      },
      rules: {
        '.Input': {
          backgroundColor: '#1c1e1f',
          color: '#ffffff',
          border: '1px solid #404249',
          padding: '8px 12px',
          minHeight: '36px',
        },
        '.Input:hover': {
          backgroundColor: '#303136',
        },
        '.Input:focus': {
          backgroundColor: '#303136',
          border: '1px solid var(--primary-brand-color)',
        },
        '.Label': {
          color: '#8e9297',
          marginBottom: '4px',
        },
        '.Error': {
          color: '#ff4747',
        }
      }
    }
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm 
        selectedPlan={selectedPlan}
        interval={interval}
        onClose={onClose}
      />
    </Elements>
  );
};

export default Checkout;
