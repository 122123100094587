import React from 'react';
import KeyManagementView from './keyManagement/KeyManagementView';
import WorkspaceSettingsView from './spaceSettings/WorkspaceSettingsView';
import BillingView from '../../billing/components/BillingView';
import MemberPortalView from './memberPortal/MemberPortalView';
import NotificationsView from './notifications/NotificationsView';
import { AccountView } from './accountSettings/AccountView';
import useUserDetailsStore from '../../../store/userDetailsStore';
import useAuthStore from '../../../store/authStore';

const SettingsContent = ({ section, spaceDetails, spaceID }) => {
  const userDetails = useUserDetailsStore(state => state.userDetails);
  const currentUser = userDetails.get(useAuthStore.getState().userID);
  const isAdmin = currentUser?.userSpaceDetails?.user_role === 'admin';

  const renderContent = () => {
    // Prevent non-admins from accessing admin sections
    const adminSections = ['key-management', 'workspace-settings', 'billing', 'member-portal'];
    if (adminSections.includes(section) && !isAdmin) {
      return <p>You don't have permission to access this section.</p>;
    }

    switch (section) {
      case 'key-management':
        return <KeyManagementView workspaceId={spaceID} />;
      case 'workspace-settings':
        return <WorkspaceSettingsView />;
      case 'billing':
        return <BillingView />;
      case 'account':
        return <AccountView />;
      case 'member-portal':
        return <MemberPortalView spaceDetails={spaceDetails} />;
      case 'notifications':
        return <NotificationsView />;
      default:
        return (
          <p>coming soon...</p>
        );
    }
  };

  return renderContent();
};

export default SettingsContent;