import { create } from 'zustand';
import { getSocket } from '../utils/socket';
import { decryptAndStoreAllKeys } from '../services/keyFetching';
import { getUserKeySync, updateUserKeySync } from '../services/keyBackendService';

const useKeySyncStore = create((set, get) => ({
  showSyncModal: false,
  isSyncing: false,
  currentWorkspaceId: null,

  initialize: (workspaceId, userId) => {
    const socket = getSocket();
    socket.on('keys_need_sync', () => {
      set({ showSyncModal: true });
    });
    get().checkSyncStatus(workspaceId, userId);
  },

  checkSyncStatus: async (workspaceId, userId) => {
    try {
      const status = await getUserKeySync(workspaceId, userId);
      if (status?.needs_sync) {
        set({ showSyncModal: true });
      }
    } catch (error) {
      console.error('Error checking sync status:', error);
    }
  },

  syncKeys: async (workspaceId, userId) => {
    set({ isSyncing: true });
    try {
      await decryptAndStoreAllKeys(userId, workspaceId);
      await updateUserKeySync(workspaceId, userId, new Date().toISOString(), false);
      set({ showSyncModal: false });
    } catch (error) {
      console.error('Error syncing keys:', error);
      throw error;
    } finally {
      set({ isSyncing: false });
    }
  },

  setShowModal: (show) => set({ showSyncModal: show }),

  cleanup: () => {
    const socket = getSocket();
    socket.off('keys_need_sync');
    set({
      showSyncModal: false,
      isSyncing: false,
      currentWorkspaceId: null
    });
  }
}));

export default useKeySyncStore; 