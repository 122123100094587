import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RootView, LoginView, LogoutView, HomeView, NotFoundView, PrivacyPolicyView } from './pages';
import { UserDetailsStep, SpaceDetailsStep, InviteUsersStep, KeyBackupInfoStep } from './features/onboarding/components';

import { ProtectedRoute } from './features/authentication'
import AuthInitializer from './features/authentication/components/AuthInitializer';
import JoinSpaceView from './features/onboarding/components/JoinSpaceView';
import MobileBlocker from './components/MobileBlocker';
import useThemeStore from './store/themeStore';
import { useEffect } from 'react';
import KeyRecoveryPortal from './features/recovery/components/KeyRecoveryPortal';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OAuthCallback from './features/authentication/components/OAuthCallback';
import CreateNewWorkspaceView from './features/onboarding/components/CreateNewWorkspaceView';

export default function App() {
  const { theme } = useThemeStore();
  
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  return (
    <MobileBlocker>
      <AuthInitializer>
        <Router>
          <Routes>
            <Route path="/" element={<RootView />} />

            <Route path="/login" element={<LoginView />} />
            <Route path="/logout" element={<LogoutView />} />
            <Route path="/privacy" element={<PrivacyPolicyView />} />

            <Route path="/create/space" element={<ProtectedRoute requiredStatus="create/space"><SpaceDetailsStep/></ProtectedRoute>} />
            <Route path="/create/backup" element={<ProtectedRoute requiredStatus="create/backup"><KeyBackupInfoStep/></ProtectedRoute>} />
            <Route path="/create/user" element={<ProtectedRoute requiredStatus="create/user"><UserDetailsStep/></ProtectedRoute>} />
            <Route path="/create/invites" element={<ProtectedRoute requiredStatus="create/invites"><InviteUsersStep /></ProtectedRoute>} />
            <Route path="/invite/:joinCode" element={<JoinSpaceView />} />

            <Route path="/client/:spaceID?" element={
              <ProtectedRoute requiredStatus="completed"><HomeView /></ProtectedRoute>
            } />
            
            <Route path="/saved" element={
              <ProtectedRoute requiredStatus="completed">
                <HomeView view="saved" />
              </ProtectedRoute>
            } />

            <Route path="/saved/:folderId" element={
              <ProtectedRoute requiredStatus="completed">
                <HomeView view="saved" />
              </ProtectedRoute>
            } />

            <Route path="/recovery" element={<KeyRecoveryPortal />} />

            <Route path="/oauth/callback" element={<OAuthCallback />} />

            <Route path="/create/workspace" element={<CreateNewWorkspaceView />} />

            <Route path="*" element={<ProtectedRoute requiredStatus="completed"><NotFoundView /></ProtectedRoute>} />
          </Routes>
        </Router>
      </AuthInitializer>
      <ToastContainer position="top-right" autoClose={3000} />
    </MobileBlocker>
  );
}