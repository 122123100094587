import { initializeKeyStore } from '../utils/indexedDB';
import { getSocket } from '../utils/socket';

// Check if keys need rotation (older than 30 days)
// WRONG DO IT SIMILAR TO CRON JOB 
export async function checkKeyRotationNeeded(userId, workspaceId) {
  const keyStore = await initializeKeyStore();
  const rooms = await keyStore.getAllRoomKeys(userId, workspaceId);
  
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
  return rooms.some(room => new Date(room.created_at) < thirtyDaysAgo);
}

export async function manualKeyRotation(userId, workspaceId) {
  try {
    const socket = getSocket();
    socket.emit('manual_key_rotation', {
      workspaceId,
      initiatorId: userId,
      initiatorType: 'manual',
      timestamp: new Date().toISOString()
    });
    return true;
  } catch (error) {
    console.error('Failed to trigger key rotation:', error);
    throw error;
  }
}